<template lang="pug">
v-combobox-search(
  :readonly='readonly',
  :disabled='disabled',
  :placeholder='placeholder',
  :error-messages='errorMessages',
  v-model='local_value',
  :items='items'
)
</template>
<script>
/**
 * Vue
 */
export default {
  /**
   * props
   */
  props: {
    items: Array,
    placeholder: String,
    disabled: Boolean,
    errorMessages: Array,
    id: Number,
    value: String,

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * computed
   */
  computed: {
    local_value: {
      // getter 関数
      get: function () {
        return { id: this.id, value: this.value };
      },
      // setter 関数
      set: function (newValue) {
        this.$emit('update:id', newValue.id);
        if (newValue.id) {
          // IDが存在する場合はvalueの方を消す
          this.$emit('update:value', null);
        } else {
          // そうでない場合はvalueをそのまま送る(空白の可能性もあり)
          this.$emit('update:value', newValue.value);
        }
      },
    },
  },
};
</script>
