import axios from 'axios';

const http = axios;
const isProduction = process.env.NODE_ENV === 'production';

export default (Vue, { store }) => {
  /**
   * requestのinterceptors
   * 全てのリクエストの処理に挟む
   */
  http.interceptors.request.use(
    (config) => {
      // デバッグ情報
      Vue.$log.debug('HTTPリクエスト開始', config);

      // リクエスト時にToastを消す
      // 主にエラーが2重に出さないため
      // ただし連続リクエストの時はおかしなことになるため、スタックが無い時にだけおこなう
      if (store.state.global.http_loading == 0) {
        // Vue.toasted.clear();
      }

      // タイムアウトが設定されているものはquietとする
      if (config.timeout) {
        // 画面ノーロックローディング
        store.commit('global/set_http_loading_quiet', true, { root: true });
      } else {
        // 画面ロックローディング
        store.commit('global/set_http_loading', true, { root: true });
      }

      // Vue.toasted.global.debug_info({
      // 	message: config.method + '：' + config.url,
      // });

      // デバッグ用
      if (!isProduction) {
        // store.commit('global/set_snackbar', { color: 'info', text: config.method + '：' + config.url }, { root: true });
      }

      return config;
    },
    (error) => {
      // エラー処理
      store.commit('global/set_http_error', true);
      return error;
    },
  );

  /**
   * requestのresponse
   * 全てのレスポンスの処理に挟む
   */
  http.interceptors.response.use(
    (response) => {
      /**
       * 200
       */
      // デバッグ情報
      Vue.$log.debug('HTTPレスポンス', response);

      // エラーを消す
      store.commit('global/delete_error_messages');

      if (response.config.timeout) {
        // 画面ノーロックローディング
        store.commit('global/set_http_loading_quiet', false, { root: true });
      } else {
        // 画面ロックローディング
        store.commit('global/set_http_loading', false, { root: true });
      }

      // TODO:暫定対応なので最終的には return Promise.resolve(response) だけになるようにするべき
      // HACK:vue_http_use_responseは暫定対応のkey名
      if (response.data.vue_http_use_response) {
        delete response.data.vue_http_use_response;
        return Promise.resolve(response);
      } else {
        return Promise.resolve(response.data);
      }
    },
    async (error) => {
      // エラーを消す
      store.commit('global/delete_error_messages');

      if (http.isCancel(error)) {
        Vue.$log.debug('HTTPキャンセル');

        return Promise.reject(error);
      } else if (error.code && error.code === 'ECONNABORTED') {
        // responseなしはタイムアウト
        Vue.$log.debug('HTTPタイムアウト', error);
        // 画面ノーロックローディング
        store.commit('global/set_http_loading_quiet', false, { root: true });
      } else {
        // responseなしはタイムアウト
        Vue.$log.debug('HTTPレスポンス', error.response);

        /**
         * ネットワークエラーが発生した場合
         */
        if (error.response === undefined) {
          Vue.toasted.global.error({
            message:
              'しばらく経ってからもう一度お試しください。解消されない場合はシステム管理者まで問い合わせください。',
          });
          // ローディング終了
          if (store.state.global.http_loading > 0)
            store.commit('global/set_http_loading', false, { root: true });
          if (store.state.global.http_loading_quiet > 0)
            store.commit('global/set_http_loading_quiet', false, {
              root: true,
            });
          return Promise.reject(error);
        }

        // このフラグでLoadingの種類を変える
        if (error.response.config.timeout) {
          // 画面ノーロックローディング
          store.commit('global/set_http_loading_quiet', false, { root: true });
        } else {
          // 画面ロックローディング
          store.commit('global/set_http_loading', false, { root: true });
        }

        /**
         * responseTypeがblobの場合JSONへ変換
         */
        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type === 'application/json'
        ) {
          await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
              error.response.data = JSON.parse(fileReader.result);
              resolve();
            };
            fileReader.readAsText(error.response.data);
          });
        }

        /**
         * 500エラー
         */
        if (error.response.status === 500) {
          // サーバーが落ちてるなどいろいろな要因が考えられるが、どのように処理を行うかは検討が必要
          Vue.toasted.global.error({
            message:
              'Internal Server Error：' + error.response.request.responseURL,
          });
          return Promise.reject(error);
        }
        /**
         * 521エラー
         */
        if (error.response.status === 521) {
          Vue.toasted.global.error({
            message:
              'Web server is down：' + error.response.request.responseURL,
          });
          return Promise.reject(error);
        }

        /**
         */
        if (error.response.status === 404) {
          // 404の発生は単純にバグなのでエラーページの用意というよりも修正を行う方向で考える
          Vue.toasted.global.error({
            message: '404 Not Found：' + error.response.request.responseURL,
          });
          return Promise.reject(error);
        }

        /**
         * 想定外のエラー
         */
        if (error.response.status === 415) {
          // エラーをセットする
          Vue.toasted.global.error({
            message:
              '415 Unsupported Media Type：' +
              error.response.request.responseURL,
          });
          return Promise.reject(error);
        }

        /**
         * 409のエラー
         */
        if (error.response.status === 409) {
          // エラーをセットする
          Vue.toasted.global.error({
            message:
              '表示中の情報が古いため保存できません。読み込み直してから再度保存してください。',
          });
          return Promise.reject(error);
        }

        /**
         * 401のエラー
         */
        if (error.response.status === 401) {
          // エラーをセットする
          Vue.toasted.global.error({
            message: '有効期限が切れています。ページを再読み込みしてください。',
          });
          return Promise.reject(error);
        }
        /**
         * 403のエラー
         */
        if (error.response.status === 403) {
          if (error.response?.data?.messages?.length > 0) {
            // エラーをセットする
            Vue.toasted.global.error({
              message: error.response.data.messages[0],
            });
          } else {
            // エラーをセットする
            Vue.toasted.global.error({
              message: '権限がありません',
            });
          }
          return Promise.reject(error);
        }

        /**
         * 想定内のエラー
         * 正常なエラーなので200と同じように考える
         */
        if (error.response.status === 400) {
          // エラーをセットする
          store.commit('global/set_error_messages', error.response.data);
          return Promise.reject(error);
        }

        /**
         * 査定に関する特別なエラー
         */
        if (error.response.status === 423) {
          store.commit('global/set_error_messages', error.response.data);
          return Promise.reject(error);
        }

        /**
         * 想定内のエラー
         * （StatusCode == 422 かつ Service == assessment）の場合、
         * field_nameが「base」のエラー内容をトーストで表示する。
         */
        if (error.response.status === 422) {
          // エラーをセットする
          store.commit('global/set_error_messages', error.response.data);

          const { baseURL } = error.response.config;
          let { responseURL } = error.response.request;

          // baseURLを削除
          responseURL = responseURL.replace(baseURL, '');
          // URLがassessmentかを判定
          const isAssessment = responseURL.startsWith('assessment');

          if (isAssessment) {
            const detail =
              error.response.data.details.find(
                (x) => x.field_name === 'base',
              ) || {};
            const field_messages = detail.field_messages || [];

            for (let field_message of field_messages) {
              Vue.toasted.global.error({
                message: field_message,
              });
            }
          }

          return Promise.reject(error);
        }
      }
    },
  );

  /**
   * リクエストのベースURLは一旦固定で入れておく
   * 切り替えが必要になった時はここに記載
   * @type {string}
   */
  http.defaults.baseURL = process.env.VUE_APP_REST_API_URL_BASE;
  http.defaults.swithCredentials = false;

  Vue.http = http;
  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return http;
      },
    },
  });
};
