<template lang="pug">
v-dialog(
  v-model='value',
  :persistent='true',
  :no-click-animation='true',
  content-class='d-pc d-middle'
)
  transition#QuickSearchFilterBalloon(name='fade')
    .m-bubble.t-top.db-middle.m-search-filter
      .db-header
        h2 {{ $props.title }}
        span.a-btn.t-small.t-primary-g(
          v-if='$props.is_update_record',
          @click.capture='$emit("input", false)'
        ) 閉じる
      .m-column-main(style='text-align: left')
        span.m-text-error(v-if='new_record') ユーザー情報を入力して下さい
        form
          fieldset
            legend 社員番号
            a {{ $props.employee.employee_number }}
          fieldset
            .m-form-title-row
            legend 氏名
            div
              v-text-field.a-w-180.a-float-l(
                :error-messages='v_error("last_name")',
                v-model='$props.employee.last_name'
              )
              v-text-field.a-w-180.a-float-l(
                :error-messages='v_error("first_name")',
                v-model='$props.employee.first_name'
              )
          fieldset
            .m-form-title-row
            legend 氏名（カナ）
            div
              v-text-field.a-w-180.a-float-l(
                :error-messages='v_error("kana_last_name")',
                v-model='$props.employee.kana_last_name'
              )
              v-text-field.a-w-180.a-float-l(
                :error-messages='v_error("kana_first_name")',
                v-model='$props.employee.kana_first_name'
              )
          fieldset
            legend 所属組織
            a {{ $props.employee.organization_name }}
          fieldset(v-if='isOtherOrganization')
            legend 以前の組織
            a {{ $props.before_organization_name }}
          fieldset
            legend 電話番号
            v-text-field.m-form-box(
              :error-messages='v_error("phone")',
              v-model='$props.employee.phone'
            )
          fieldset
            legend メールアドレス
            v-text-field.m-form-box(
              :error-messages='v_error("email")',
              v-model='$props.employee.email'
            )
          fieldset
            legend 性別
            v-radio-group(row, v-model='$props.employee.gender')
              v-radio(label='女性', :value='2')
              v-radio(label='男性', :value='1')
              v-radio(label='無回答', :value='3')
              v-radio(label='不明', :value='4')
          fieldset
            legend chatwork_id
            v-text-field.m-form-box(
              :error-messages='v_error("chatwork_id")',
              v-model='$props.employee.chatwork_id'
            )
      .d-footer.a-m-t-middle
        span.a-btn.t-middle.t-primary.t-center(@click='save()') 保存する
</template>

<script>
/**
 * import
 */
import { mapState, mapActions, mapGetters } from 'vuex';
// import Vue from 'vue';
/**
 * Vue
 */
export default {
  // 従業員番号と組織コードはjwtから取得
  props: {
    value: Boolean,
    is_update_record: Boolean,
    title: String,
    employee: {
      type: Object,
    },
    isOtherOrganization: Boolean,
    before_organization_name: String,
  },
  /**
   * computed
   */
  computed: {
    /**
     * mapState
     */
    ...mapState('global', ['current_user']),
    // なぜかv_errorが呼ばれなくなってしまうので、あえて明示的に定義している
    ...mapGetters('global', ['v_error']),

    new_record() {
      return !this.$props.is_update_record;
    },
  },
  /**
   * methods
   */
  methods: {
    ...mapActions('common', ['post_employees_data']),
    save() {
      // trueにして更新
      this.$props.employee.is_usable = true;
      // 従業員情報登録更新APIを叩く
      this.post_employees_data(this.$props.employee).then(() => {
        this.$emit('input', false);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/**
 * scopedなので他のページには影響しない
 */
.m-bubble:before {
  content: none;
}
.m-bubble:after {
  content: none;
}
.d-pc .m-bubble {
  top: 10%;
  left: 42%;
}
</style>
