<template lang="pug">
v-combobox(
  :readonly='readonly',
  :disabled='disabled',
  :placeholder='placeholder',
  :error-messages='errorMessages',
  :error='is_error',
  :clearable='clearable && !readonly',
  v-model='local_value',
  item-text='name',
  item-value='id',
  :items='filter_items',
  no-filter,
  :search-input.sync='search',
  :required='isRequired',
  @keyup='local_value = $event.target.value'
)
</template>
<script>
/**
 * Vue
 */
export default {
  /**
   * props
   */
  props: {
    items: Array,
    placeholder: String,
    disabled: Boolean,
    errorMessages: Array,
    value: Object,

    // HTML5のバリデート用
    isRequired: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * data
   */
  data: function () {
    return {
      // フィルタリングしたものを表示に使用するが、初期値としてpropsのitemsを使う
      filter_items: this.items,
      search: '',
      is_error: false,
      error_messages: '',
      clearable: false,
      local_items: null,
    };
  },

  /**
   * computed
   */
  computed: {
    local_value: {
      // getter 関数
      get: function () {
        // idだけきて空のものがきたらitemから検索してあててみる
        if (!this.value.value) {
          let selected =
            this.items.find((obj) => obj.id == this.value.id) || {};
          return selected.name;
        } else {
          return this.value.value;
        }
      },
      // setter 関数
      set: function (newValue) {
        if (Object.prototype.toString.call(newValue) == '[object Object]') {
          // Objectで返ってくる場合、選択肢にあったものを使用しているのでkey,valueで返す
          this.$emit('input', { id: newValue.id, value: newValue.name });
        } else if (newValue) {
          // 自由入力で得た結果をitemsから検索する
          let item = this.items.find(({ name }) => name === newValue);
          if (item) {
            // もしもあれば自由入力であっても選択したものと同じような挙動にする
            this.$emit('input', { id: item.id, value: null });
          } else {
            this.$emit('input', { id: null, value: newValue });
          }
        } else {
          // 未入力の場合はnull,nullで返す
          this.$emit('input', { id: null, value: '' });
        }
      },
    },
  },

  /**
   * watch
   */
  watch: {
    search: function (val) {
      if (Array.isArray(this.items)) {
        if (val) {
          // 入力されている場合は配列をフィルタリングする
          this.filter_items = this.items.filter((obj) => {
            if (obj.keyword.indexOf(this.search) >= 0) return true;
          });
        } else {
          // 入力されていない場合は元データを使う
          this.filter_items = this.local_items;
        }
      }
    },
    items: function () {
      this.init();
    },
  },
  created: function () {
    this.init();
  },
  /**
   * methods
   */
  methods: {
    // 初期化&更新
    init() {
      if (!Array.isArray(this.items)) {
        this.$log.error('VComboboxSearch', '配列ではありません');
        this.is_error = true;
        this.error_messages = '配列が設定されていません';
      } else {
        this.local_items = this.items.map((element) => {
          element.keyword = element.keyword + element.name;
          return element;
        });

        this.filter_items = this.items;
      }
    },
  },
};
</script>
