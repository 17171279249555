// 空をハイフン変換
function empty_hyphen(string) {
  if (string === null || string === '') {
    // ちょっと気持ち悪いが全角のハイフンが見た目的に適している
    return '－';
  } else {
    return string;
  }
}

function add_hyphen(str1, str2) {
  if (str1 && str2) return str1 + '-' + str2;
  else if (str1) return str1;
  else if (str2) return str2;
  else return '';
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('empty_hyphen', empty_hyphen);
    Vue.filter('add_hyphen', add_hyphen);
  },
});
