<template lang="pug">
v-autocomplete-search.a-w-100.a-float-l(v-model='dataValue', :items='year')
</template>

<script>
/**
 * Vue
 */
export default {
  props: {
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  /**
   * data
   */
  data: function () {
    return {
      dataValue: this.value,
      year: [],
    };
  },
  methods: {
    // year: function() {
    // 	// if (i == 1) {
    // 	// 	return '元年';
    // 	// }
    //
    //
    //
    // 	return { id: 1, name: '1',  keyword: '1' };
    // },
  },
  watch: {
    dataValue: function (value) {
      this.$emit('input', value);
    },
  },

  /**
   * beforeMount
   */
  beforeMount: function () {
    this.$log.info(this.$route.name);

    let arr = [];
    for (let i = 1; i <= this.max; i++) {
      arr.push({ id: i, name: i + '年', keyword: i + '年' });
    }
    this.year = arr;
  },
};
</script>

<style lang="scss" scoped></style>
