<template lang="pug">
div
  v-text-field.a-w-140(
    :error-messages='errorMessages',
    type='date',
    v-model='year',
    min='1000-01-01',
    max='9999-12-31'
  )
  span {{ year | current_age }}
  .m-box-detail.a-m-t-small
    fieldset
      .m-form-title-row.t-w-xsmall
        legend 和暦検索
        div
          .a-clear
            v-wareki-gengo-input(v-model='gengoIndex')
            v-wareki-year-input(
              v-if='hasGengo',
              :max='maxGengoYear',
              v-model='gengoYear'
            )
          p 西暦: {{ year_format }}
</template>
<script>
import VWarekiGengoInput from './VWarekiGengoInput';
import VWarekiYearInput from './VWarekiYearInput';

/**
 * Vue
 */
export default {
  /**
   * components
   */
  components: { VWarekiGengoInput, VWarekiYearInput },

  /**
   * props
   */
  props: {
    value: String,
    errorMessages: Array,
  },
  /**
   * data
   */
  data: function () {
    return {
      gengoIndex: '',
      gengoYear: 0,
      gengoData: [
        { id: 1, name: '明治', base: 1867, max: 45, keyword: '明治meiji' },
        { id: 2, name: '大正', base: 1911, max: 15, keyword: '大正taisyo' },
        { id: 3, name: '昭和', base: 1925, max: 64, keyword: '昭和syowa' },
        { id: 4, name: '平成', base: 1988, max: 31, keyword: '平成heisei' },
      ],
      initialized: false,
      year: undefined,
      year_format: undefined,
    };
  },

  /**
   * computed
   */
  computed: {
    hasGengo: function () {
      return this.gengoData.some((element) => {
        return this.gengoIndex == element.name;
      });
    },

    maxGengoYear: function () {
      let arr = this.gengoData.filter((element) => {
        if (this.gengoIndex == element.name) {
          return element.max;
        }
      });

      if (arr.length) {
        return arr[0].max;
      } else {
        return 0;
      }
    },
  },

  /**
   * watch
   */
  watch: {
    gengoIndex: function () {
      if (this.initialized && this.hasGengo) {
        this.gengoYear = 0;
      }

      this.calcYear();
    },
    gengoYear: function () {
      this.calcYear();
    },
    year: function () {
      this.$emit('input', this.year);
    },
    value: function (val) {
      this.year = val;
    },
  },

  /**
   * mounted
   */
  beforeMount: function () {
    if (this.value) {
      this.year = this.value;
      let calc_year = this.year.split('-');
      calc_year = calc_year[0];

      for (let i in this.gengoData) {
        let gengo = this.gengoData[i];

        if (calc_year > gengo.base) {
          this.gengoIndex = this.gengoData[i].name;
          this.gengoYear = this.value - gengo.base;
        }
      }
    }

    this.$nextTick(() => (this.initialized = true));
  },

  /**
   * methods
   */
  methods: {
    calcYear: function () {
      let year = null;
      let month = '01';
      let date = '01';

      if (this.hasGengo && this.gengoYear > 0) {
        let gengo = this.gengoData.filter((element) => {
          return this.gengoIndex == element.name;
        });

        year = this.gengoYear + gengo[0].base;
        if (this.year) {
          const current_selected_year = new Date(this.year);
          month = (current_selected_year.getMonth() + 1)
            .toString()
            .padStart(2, '0'); // Dateオブジェクトの仕様上、月は0から始まるため+1しないといけない
          date = current_selected_year.getDate().toString().padStart(2, '0');
        }

        this.year = year.toString() + '-' + month + '-' + date;
        this.year_format = year.toString() + '年';

        this.$emit('input', this.year);
      }
    },
  },
};
</script>
