<template lang="pug">
transition(name='loading')
  .isLoading(v-show='http_loading != 0')
    .spinner
      .dot1
      .dot2
</template>

<script>
/**
 * import
 */
import { mapState } from 'vuex';

/**
 * Vue
 */
export default {
  /**
   * computed
   */
  computed: {
    /**
     * mapState
     */
    ...mapState('global', ['http_loading']),
  },
};
</script>

<style lang="scss" scoped>
.isLoading {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background-color: rgba(39, 43, 43, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
