const disableAutocomplete = {
  install(Vue) {
    Vue.mixin({
      mounted() {
        this.disableAutoComplete();
      },

      methods: {
        disableAutoComplete() {
          // inputタグが全て対象
          let elements = document.querySelectorAll('input');

          if (!elements) {
            return;
          }

          elements.forEach((element) => {
            // これはChromeに対するHACKなので、正攻法ではない
            // 正攻法はChrome側によって封鎖されている
            element.setAttribute('autocomplete', 'new-password');
          });
        },
      },
    });
  },
};

export default disableAutocomplete;
