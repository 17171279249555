import { PublicClientApplication } from '@azure/msal-browser';
import { config, loginRequest } from '../msalConfig';

let auth;

export const msal = {
  async install(Vue) {
    // MSALを使う準備
    auth = new PublicClientApplication(config);

    // プラグイン登録
    Vue.prototype.$msal = {
      async handleRedirectPromise() {
        return await auth.handleRedirectPromise();
      },
      async acquireTokenSilent(account, isForceRefresh) {
        let idToken = '';
        const accessTokenRequest = Object.assign(loginRequest, { account });
        if (account) {
          // アカウントが存在する場合は、サイレントログインでトークンを更新
          try {
            const accessTokenResponse = await auth.acquireTokenSilent({
              ...accessTokenRequest,
              forceRefresh: isForceRefresh,
            });
            Vue.$log.debug('accessTokenResponse:', accessTokenResponse);
            idToken = accessTokenResponse.idToken;
            // トークンが更新されない場合があるので、AzureADにリダイレクト
            if (!idToken) {
              await auth.acquireTokenRedirect(accessTokenRequest);
            }
          } catch (error) {
            // エラー発生時も、AzureADにリダイレクト
            Vue.$log.error(error);
            if (error) {
              await auth.acquireTokenRedirect(accessTokenRequest);
            }
          }
        } else {
          // アカウントが存在しない場合は、AzureADにリダイレクト
          await auth.acquireTokenRedirect(accessTokenRequest);
        }
        return idToken;
      },

      /**
       * ログアウト
       */
      async logout() {
        await auth.logoutRedirect();
      },
      /**
       * 全アカウント情報取得
       */
      getAllAccounts() {
        return auth.getAllAccounts();
      },
    };
  },
};
