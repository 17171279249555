/**
 * @file Global filter のエントリーポイント
 */

/**
 * 大括弧を付与
 * @param {string} str 任意の文字列
 */
function brackets(str) {
  if (str === '-' || !str) {
    return '';
  } else {
    return `[${str}]`;
  }
}

/**
 * 小括弧を付与
 * @param {string} str 任意の文字列
 */
function parentheses(str) {
  if (str === '-' || !str) {
    return '';
  } else {
    return `(${str})`;
  }
}

/**
 * 郵便番号の形式に整形 (XXX-YYYY)
 * @param {string} str 任意の文字列
 */
function zipcode(str) {
  if (typeof str === 'string' && str.length === 7) {
    return str.substr(0, 3) + '-' + str.substr(3, 7);
  } else {
    return str;
  }
}

/**
 * - 「L」で始まる文字列の場合、全角スペースを付与して返す
 * - それ以外の場合、「・ 」を付与して返す
 * @param {string} str 任意の文字列
 * @returns {string} list-styleを付与した文字列
 */
function listStyle(str) {
  if (typeof str === 'string' && str.startsWith('L')) {
    return '　' + str;
  } else {
    return '・ ' + str;
  }
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('brackets', brackets);
    Vue.filter('parentheses', parentheses);
    Vue.filter('zipcode', zipcode);
    Vue.filter('listStyle', listStyle);

    require('./age').default.install(Vue);
    require('./date').default.install(Vue);
    require('./hyphen').default.install(Vue);
    require('./price').default.install(Vue);
    require('./space').default.install(Vue);
  },
});
