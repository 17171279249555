<template lang="pug">
header
  .l-overlay(v-if='navClass', @click='navClass = ""')
  .header-inner
    div
      span.btn-menu(@click='navClass = "active"')
      router-link(:to='{ name: "Dashboard" }', tag='h1') GYRO
      span.staging(v-if='!isProductionFlg') 検証環境

    div(v-if='current_user.profile.params')
      .m-m-btn-thumbnail(style='color: #fff; cursor: hand; cursor: pointer')
        span.pr-4(@click='logoutAzure') ログアウト
        span(@click='searchEmployeeAndOpenTheModal') ▼ {{ current_user.profile.params.last_name }} {{ current_user.profile.params.first_name }} (ID: {{ current_user.profile.params.employee_number }})
        //- 従業員登録更新用モーダル
        employee-update-or-create-ballon(
          v-model='employee_update_or_create_ballon',
          title='従業員更新',
          is_update_record,
          :employee='employee',
          :isOtherOrganization='isOtherOrganization()',
          :before_organization_name='before_organization_name'
        )
        //- ログアウト確認用モーダル
        v-dialog(v-model='logoutDialog', content-class='d-pc d-small')
          v-card
            .d-main.font-weight-bold
              p {{ logoutMessage }}
            .d-footer
              span.a-btn.t-middle.t-primary.t-center(
                @click='logoutDialog = false'
              ) OK
  nav(:class='navClass')
    .btn-close
      i.fas.fa-times(@click='navClass = ""')
    menu-list(@close='navClass = ""')
      li
        router-link(:to='{ name: "Dashboard" }')
          i.fas.fa-th-large
          | ダッシュボード
</template>

<script>
/**
 * import
 */
import { mapState, mapActions, mapGetters } from 'vuex';
import MenuList from '@/components/molecules/MenuList';
import EmployeeUpdateOrCreateBallon from '@/components/molecules/EmployeeUpdateOrCreateBallon';
import { ENUM_GENDER } from '@/enum';
import { isProduction } from '@/helpers';
/**
 * Vue
 */
export default {
  components: { MenuList, EmployeeUpdateOrCreateBallon },
  /**
   * data
   */
  data: function () {
    return {
      navClass: '',
      is_update_record: true,
      employee_update_or_create_ballon: false,
      before_organization_name: '',
      employee: {
        chatwork_id: '',
        email: '',
        employee_number: '',
        first_name: '',
        gender: 1,
        kana_first_name: '',
        kana_last_name: '',
        last_name: '',
        organization_name: '',
        phone: '',
        is_usable: false,
      },
      employee_search_filter: {
        employee_number: '',
      },
      isLogged: true,
      logoutDialog: false,
      logoutMessage: '',
      isProductionFlg: isProduction(),
    };
  },

  /**
   * computed
   */
  computed: {
    /**
     * mapState
     */
    ...mapState('global', ['current_user', 'http_loading_quiet']),
    ...mapGetters('common', ['get_organization_name']),
  },
  methods: {
    ...mapActions('common', ['get_employees_data', 'get_all_common_masters']),
    async searchEmployeeAndOpenTheModal() {
      // 従業員マスタ検索
      // paramsにjwtの従業員番号を入れる
      // 検索APIを叩いて結果をpropsで渡す
      this.employee_search_filter.employee_number =
        this.current_user.profile.params.employee_number;
      await this.get_employees_data(this.employee_search_filter);
      // 更新のケース
      this.employee = this.$store.state.common.employees_data.employees[0];

      // 比較するためcopy
      this.before_organization_name = this.employee.organization_name;

      // dashbordだとcommonマスターが叩かれていないので叩く
      await this.get_all_common_masters();
      const { name } = this.get_organization_name(
        this.current_user.profile.params.organization_code,
      );
      this.employee.organization_name = name;

      this.employee.gender = this.getGenderIdByName();
      this.employee_update_or_create_ballon = true;
    },
    getGenderIdByName() {
      const obj = Object.values(ENUM_GENDER).find(
        (x) => x.name === this.employee.gender,
      );
      if (obj !== undefined) {
        return obj.id;
      } else {
        return '';
      }
    },
    // JWTの組織と現在のDBの組織が異なったらtrue
    isOtherOrganization() {
      const { name } = this.get_organization_name(
        this.current_user.profile.params.organization_code,
      );
      return this.before_organization_name !== name;
    },
    async logoutAzure() {
      this.$store.commit('global/set_http_loading', true, { root: true });
      try {
        // Azure ADのログアウト処理
        this.$msal.logout();
        // ストレージクリア
        localStorage.clear();
        sessionStorage.clear();
        this.logoutMessage =
          'ログアウトが完了しました。ブラウザを閉じてください';
      } catch (error) {
        Vue.$log.error(error);
      } finally {
        // 確認モーダルを開く
        this.logoutDialog = true;
        this.$store.commit('global/set_http_loading', false, { root: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100%;
  height: $h-pc-header-inner;
  z-index: 50;
  .header-inner {
    background: $base-color;
    height: $h-pc-header-inner;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      &:nth-child(1) {
        width: 560px;
        display: flex;
        align-items: center;
        .btn-menu {
          background-color: $base-color-light;
          width: 56px;
          height: $h-pc-header-inner;
          display: inline-block;
          margin-right: 16px;
          &:before {
            font-family: 'Font Awesome 5 Free';
            content: '\f0c9';
            color: #fff;
            font-weight: 900;
            font-size: 23px;
            line-height: $h-pc-header-inner;
            width: 56px;
            text-align: center;
            display: inline-block;
          }
          &:hover {
            cursor: pointer;
          }
        }

        h1 {
          content: url('../../assets/svg/logo.svg');
          width: auto;
          height: 26px;
          display: inline-block;
          &:hover {
            cursor: pointer;
          }
        }

        .staging {
          color: #fff;
          background-color: #f9a825;
          margin-left: 15px;
          border-radius: 2px;
          font-size: 16px;
          font-weight: bold;
          padding: 2px 25px;
          letter-spacing: 0.6px;
        }
      }

      &:nth-child(2) {
        width: 560px;
        font-size: 1.4rem;
        text-align: right;
        padding-right: 16px;

        p {
          color: #fff;
        }
      }
    }
  }

  nav {
    width: 400px;
    height: 100%;
    background-color: $base-color-dark;
    position: fixed;
    top: 0;
    z-index: $z-index-nav;
    transition: $t-apple;
    left: -400px;

    .btn-close {
      background-color: $base-color-light;
      width: 100%;
      height: $h-pc-header-inner;
      position: relative;
      i {
        color: #fff;
        font-weight: 900;
        font-size: 2rem;
        line-height: $h-pc-header-inner;
        width: 56px;
        height: $h-pc-header-inner;
        text-align: center;
        position: absolute;
        right: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &.active {
      left: 0;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
    }
    ul {
      li {
        a {
          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
