<template lang="pug">
v-autocomplete-search.a-w-100.a-float-l(
  :items='$parent.gengoData',
  v-model='dataValue'
)
</template>

<script>
/**
 * Vue
 */
export default {
  props: {
    value: String,
  },
  /**
   * data
   */
  data: function () {
    return {
      dataValue: null,
    };
  },

  watch: {
    dataValue: function (value) {
      if (value) {
        let arr = this.$parent.gengoData.filter((element) => {
          return value == element.id;
        });

        this.$emit('input', arr[0].name);
      } else {
        this.$emit('input', null);
      }
    },
  },

  /**
   * beforeMount
   */
  beforeMount: function () {
    if (this.value == '') {
      this.dataValue = 0;
    }
  },
};
</script>

<style lang="scss" scoped></style>
