import moment from 'moment';
import 'moment/locale/ja';

// 日付'HH:mm'
function date_time(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('HH:mm');
  } else {
    return '-';
  }
}

// 日付'YYYY/MM/DD(ddd)'
function date_long(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY/MM/DD(ddd)');
  } else {
    return '-';
  }
}

// 日付'YYYY/MM/DD'
function year_month_day(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY/MM/DD');
  } else {
    return '-';
  }
}

// 日付'MM/DD(ddd)'
function date_short(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('MM/DD(ddd)');
  } else {
    return '-';
  }
}

// 日付'YYYY/MM/DD(ddd) HH:mm'
function date_full(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format(
      'YYYY/MM/DD(ddd) HH:mm',
    );
  } else {
    return '-';
  }
}

// 日付'YYYY/MM'
function date_month(datetime) {
  if (datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY/MM');
  } else {
    return '-';
  }
}

// 日付'～HH:mm'
function date_range(datetime) {
  if (datetime) {
    return ' ～ ' + moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('HH:mm');
  } else {
    return '-';
  }
}

// 日付'YYYY/MM/DD(ddd) HH:mm ~ HH:mm'
function date_from_to(datetimeFrom, datetimeTo) {
  if (datetimeFrom && datetimeTo) {
    const from = moment(datetimeFrom, 'YYYY-MM-DD HH:mm:ssZ').format(
      'YYYY/MM/DD(ddd) HH:mm',
    );
    const to = moment(datetimeTo, 'YYYY-MM-DD HH:mm:ssZ').format('HH:mm');
    return `${from} ~ ${to}`;
  } else {
    return '';
  }
}

// 日付をAPI用に整形'YYYY-MM-DD'
function date_api(datetime) {
  return moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD');
}

// 日付（和暦）'令和YY年MM月DD日'
function date_wareki(datetime) {
  if (datetime) {
    let year = parseInt(
      moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY'),
    );
    let month =
      parseInt(moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('MM')) - 1;
    let day = parseInt(moment(datetime, 'YYYY-MM-DD HH:mm:ssZ').format('DD'));
    let date = new Date(year, month, day);
    let options = { era: 'long' };
    let res = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(
      date,
    );

    return res;
  } else {
    return '-';
  }
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('date_time', date_time);
    Vue.filter('date_long', date_long);
    Vue.filter('year_month_day', year_month_day);
    Vue.filter('date_short', date_short);
    Vue.filter('date_full', date_full);
    Vue.filter('date_month', date_month);
    Vue.filter('date_range', date_range);
    Vue.filter('date_from_to', date_from_to);
    Vue.filter('date_api', date_api);
    Vue.filter('date_wareki', date_wareki);
  },
});
