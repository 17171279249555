import _ from 'lodash';

// 数字3桁区切り
function add_comma(number) {
  if (_.isNumber(number)) {
    return number.toLocaleString() + '円';
  } else {
    return '0円';
  }
}

// 数字3桁区切り
function add_comma_empty(number) {
  if (_.isNumber(number)) {
    return number.toLocaleString() + '円';
  } else {
    return '未入力';
  }
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('add_comma', add_comma);
    Vue.filter('add_comma_empty', add_comma_empty);
  },
});
