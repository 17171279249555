function append_space(str) {
  if (str) return str + ' ';
  else return '';
}

function prepend_space(str) {
  if (str) return ' ' + str;
  else return '';
}

function add_space(str1, str2) {
  if (str1 && str2) return str1 + ' ' + str2;
  else if (str1) return str1;
  else if (str2) return str2;
  else return '';
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('append_space', append_space);
    Vue.filter('prepend_space', prepend_space);
    Vue.filter('add_space', add_space);
  },
});
