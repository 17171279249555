// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 style=%27enable-background:new 0 0 24 24%27 xml:space=%27preserve%27%3E%3Cpath d=%27M18 17.1 16.7 16l-.7.7 2 1.8 3-3.1-.7-.7-2.3 2.4zm4.4-.7c0-2.2-1.8-4-4-4s-4 1.8-4 4 1.8 4 4 4 4-1.8 4-4zm1.6 7-.8.6-2.4-3.2c-.7.4-1.6.7-2.5.7-.7 0-1.3-.1-1.9-.4l-5 2.6V12L22 6.6V13c.8.9 1.4 2.1 1.4 3.4 0 1.5-.7 2.9-1.7 3.8l2.3 3.2zM21.5 5.8 11 0 6.2 2.6l10.6 5.5c0 .1 4.7-2.3 4.7-2.3zM5.1 3.2.5 5.7 11 11.2l4.7-2.4c0-.1-10.6-5.6-10.6-5.6zM0 6.6 10.5 12v11.7L0 18.1V6.6z%27 style=%27fill-rule:evenodd;clip-rule:evenodd;fill:%23fff%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul[data-v-d95b521c]{padding:16px 0 24px;height:100%;overflow-y:auto}ul li[data-v-d95b521c]{display:table;width:100%}ul li a[data-v-d95b521c]{color:#fff;display:table-cell;font-size:1.8rem;height:64px;vertical-align:middle;padding:0 16px}ul li a i[data-v-d95b521c]{margin-right:8px}ul li a[data-v-d95b521c]:hover{background-color:#0f4584;transition:all .3s ease}.icon-m[data-v-d95b521c]{width:18px;display:inline-block}.icon-m.t-box-search[data-v-d95b521c]:before{position:relative;top:3px;content:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
