import Vue from 'vue';

/**
 * Commonに関連するStore
 */
export const common = {
  namespaced: true,
  state: {
    /**
     * APIのURLとして利用
     */
    api: 'common/v1/',

    /**
     * 郵便番号検索の結果
     */
    zip_code_addresses: null,
    /**
     * 従業員情報の検索結果
     */
    employees_data: [],
    // 従業員一括取得
    all_employees_data: [],

    cmn_masters: {
      master_address_classifications: [],
      master_address_evidences: [],
      master_agent_attributes: [],
      master_agent_categorys: [],
      master_agent_types: [],
      master_appointment_ranks: [],
      master_bank_account_types: [],
      master_banks: [],
      master_contact_classifications: [],
      master_contact_hosts: [],
      master_delegate_type_1s: [],
      master_delegate_type_2s: [],
      master_identity_verification_documents: [],
      master_organizations: [],
      master_search_filter_date_indicators: [],
      master_search_filter_registration_classifications: [],
    },

    promas_products_search: {},
    promas_masters_for_graph: {},
    promas_signed_download_url: {},

    /**
     * Azure認証用のユーザデータ取得結果
     */
    gyro_user_me: null,

    /**
     * gyro_user
     */
    gyro_users_data: [],
    gyro_user_data: null,
  },

  getters: {
    /**
     * table_name と name を引数として与え、該当するマスターデータの object を返す
     */
    get_cmn_master_object_by_name: (state) => (table_name, name) => {
      const { cmn_masters = {} } = state;
      if (cmn_masters[table_name]) {
        return (
          state.cmn_masters[table_name].find((obj) => obj.name == name) || {}
        );
      } else {
        return {};
      }
    },
    /**
     * table_name と id を引数として与え、該当するマスターデータの object を返す
     */
    get_cmn_master_object: (state) => (table_name, id) => {
      const { cmn_masters = {} } = state;
      if (cmn_masters[table_name]) {
        return state.cmn_masters[table_name].find((obj) => obj.id == id) || {};
      } else {
        return {};
      }
    },

    /**
     * table_name と id を引数として与え、該当するマスターデータの name を返す
     */
    get_cmn_master_name:
      (_, { get_cmn_master_object }) =>
      (table_name, id) => {
        const { name = '' } = get_cmn_master_object(table_name, id);
        return name;
      },

    /**
     * 取得した従業員一覧情報をautoComplete用に整形
     */
    filter_all_master_data: (state) => {
      if (state.all_employees_data) {
        return state.all_employees_data.map((data) => {
          let name = data.last_name + ' ' + data.first_name;
          return {
            name: name,
            id: data.employee_number,
          };
        });
      }
    },

    /**
     * 従業員一覧 を (organization_name + last_name + first_name) に整形
     */
    get_employees_full: (state) => {
      if (state.all_employees_data) {
        return state.all_employees_data.map((employee) => {
          const { organization_name, last_name, first_name, employee_number } =
            employee;
          return {
            id: employee_number,
            name: organization_name + ' ' + last_name + ' ' + first_name,
          };
        });
      } else {
        return [];
      }
    },

    get_employees_full_by_id: (state) => {
      if (state.all_employees_data) {
        return state.all_employees_data.map((employee) => {
          const { organization_name, last_name, first_name, employee_id } =
            employee;
          return {
            id: employee_id,
            name: organization_name + ' ' + last_name + ' ' + first_name,
          };
        });
      } else {
        return [];
      }
    },

    get_employee: (state) => (employee_number) => {
      if (!state.all_employees_data) return '';
      const employee = state.all_employees_data.find(
        (employee) => employee_number === employee.employee_number,
      );
      if (employee == undefined) return '';
      const { organization_name, last_name, first_name } = employee;
      return organization_name + ' ' + last_name + ' ' + first_name;
    },

    get_employee_by_id: (state) => (employee_number) => {
      if (!state.all_employees_data) return '';
      const employee = state.all_employees_data.find(
        (employee) => employee_number === employee.employee_number,
      );
      if (employee == undefined) return '';
      const { employee_id } = employee;
      return employee_id;
    },

    /**
     * 銀行のIDと支店のIDを元に支店名のnameだけを取得する
     */
    get_branch_name:
      (_, getters) => (master_bank_id, master_bank_branch_id) => {
        const { get_cmn_master_object } = getters;
        let master = get_cmn_master_object('master_banks', master_bank_id);
        master = master.branches.find(
          (obj) => obj.id === master_bank_branch_id,
        );
        if (master) {
          return master.name;
        } else {
          return '未選択';
        }
      },
    get_organization_name: (state) => (code) => {
      const { cmn_masters = {} } = state;
      if (cmn_masters.master_organizations) {
        return (
          cmn_masters.master_organizations.find(
            (obj) => obj.organization_code == code,
          ) || '不明'
        );
      } else {
        return {};
      }
    },
  },

  mutations: {
    set_zip_code_addresses(state, response) {
      state.zip_code_addresses = response;
    },
    set_employees_data(state, response) {
      state.employees_data = response;
    },
    set_all_employees_data(state, response) {
      state.all_employees_data = response.employees;
    },
    set_gyro_users_data(state, response) {
      state.gyro_users_data = response;
    },
    set_gyro_user_data(state, response) {
      state.gyro_user_data = response;
    },
    set_all_masters(state, payload) {
      state.cmn_masters = payload;
    },
    set_promas_products_search(state, payload) {
      state.promas_products_search = payload;
    },
    set_promas_masters_for_graph(state, payload) {
      state.promas_masters_for_graph = payload;
    },
    set_promas_signed_download_url(state, payload) {
      state.promas_signed_download_url = payload;
    },
    set_gyro_user_me(state, payload) {
      state.gyro_user_me = payload;
    },
  },
  actions: {
    /**
     * BFF_CMN_00017 郵便番号住所検索API
     * API概要 郵便番号・住所を検索する - 郵便番号と住所をクエリパラメータに、commonの住所郵便番号マスタから部分一致で検索する
     * 検索結果は郵便番号と住所を返却し、フロント側で連結して表示する
     */
    async get_zip_code_addresses_search(
      { commit, state },
      zip_code_or_address,
    ) {
      Vue.$log.info('BFF_CMN_00017 郵便番号住所検索API');

      return Vue.http
        .get(state.api + 'zip_code_addresses/search', {
          params: { zip_code_or_address: zip_code_or_address },
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_zip_code_addresses', response.zip_code_addresses);
          },

          // 異常系
          (error) => {
            Vue.$log.debug(error);
          },
        );
    },
    /**
     * BFF_CMN_00002 従業員マスタ検索API
     * API概要
     * - 検索条件に従い従業員の検索を行い、検索結果を返却する
     * ### バリデーション
     * - 参照権限がない場合は403 ## SS API - cmn_00007 従業員マスタ検索API
     * ### 処理フロー
     * 1. cmn_00007 従業員マスタ検索APIを呼び出し検索結果を取得する
     * 2. SS APIのレスポンスをBFF APIのレスポンスに整形する ## 注意点 - 検索結果の件数表示はフロントで実施する想定
     */
    async get_employees_data({ commit, state }, params) {
      Vue.$log.info('BFF_CMN_00002 従業員マスタ検索API');

      return Vue.http
        .get(state.api + 'employees/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_employees_data', response);
            Vue.$log.debug(state.employees_data);
            return response;
            // リミットオーバー
            // commit('set_search_result', response.is_limit_over);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_CMN_00021 従業員情報登録更新API
     * API概要
     * - 入力された従業員情報を登録更新する
     * ### バリデーション
     * - 参照権限がない場合は403 ## SS API - cmn_00052 従業員情報登録更新API
     * ### 処理フロー
     * 1. BFF_CMN_00002 従業員マスタ検索APIを叩き、取得レコードが存在しない(新規時)
     * 1. BFF_CMN_00002 従業員マスタ検索APIを叩き、レコードを取得(更新時)
     * 2. 本APIを叩く
     */
    async post_employees_data({ state }, params) {
      Vue.$log.info('BFF_CMN_00021 従業員情報登録更新API');

      return await Vue.http.post(state.api + 'employees', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: response.message,
          });
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          throw new Error(error);
        },
      );
    },
    /**
     * BFF_CMN_00019 従業員一覧取得API
     * API概要
     * - 従業員を全件取得し返却する
     * ## バリデーション - 参照権限がない場合は403
     * ## SS API - cmn_00009 従業員一覧取得API
     * ## 処理フロー
     * 1. cmn_00009 従業員一覧取得APIを呼び出し検索結果を取得する
     * 2. SS APIのレスポンスをBFF APIのレスポンスに整形する
     * ## 注意点 - なし
     */
    async get_all_employees_data({ commit, state }, params) {
      Vue.$log.info('BFF_CMN_00019 従業員一覧取得API');

      return Vue.http
        .get(state.api + 'employees', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_all_employees_data', response);
            Vue.$log.debug(state.employees_data);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_CMN_00024 従業員退職フラグ更新API
     * API概要
     * - 指定された従業員の退職フラグを更新する
     * - 同時にRedisキャッシュ(MasterData)への書き込みも行う
     * バリデーション
     * - 参照権限がない場合は403
     * - データが存在しない場合は404
     * ## SS API - cmn_00056 従業員退職フラグ更新API
     * ## 処理フロー
     * 1. 従業員IDをパラメータにしてSSをコール、レスポンスをそのままFEに返す。
     * ## 注意点 - なし
     */
    async patch_employees_is_usable({ state }, params) {
      Vue.$log.info('BFF_CMN_00024 従業員退職フラグ更新API');

      return Vue.http.patch(
        state.api + 'employees/' + params.id + '/is_usable',
        {
          is_usable: params.is_usable,
        },
      );
    },

    /**
     * BFF_CMN_00018 マスタ一括取得
     * API概要
     * - コモンの全マスタ取得を一括で行う
     * ## SS API
     * ### cmn_00041 マスタ取得(全レコード)
     * - master_address_evidences | 住所証明物
     * - master_identity_verification_documents | 本人確認書類
     * - master_agent_types | 代理人種類
     * - master_agent_categorys | 代理人種別
     * - master_agent_attributes | 代理人属性
     * - master_delegate_type_1s | 受任形態1
     * - master_delegate_type_2s | 受任形態2
     * - master_search_filter_date_indicators | 検索条件日付区分
     * - master_search_filter_registration_classifications | 検索条件登録区分
     * - master_bank_account_types | 口座種別 ### cmn_00028 アポランクマスタ全件取得API
     * - master_appointment_ranks | アポランク ### cmn_00010 銀行マスタ取得
     * - master_banks | 銀行 - master_bank_branches | 銀行支店
     * ### cmn_00005 組織一覧取得
     * - master_organizations | 組織 ## バリデーション
     * - 参照権限がない場合は403
     */
    async get_all_common_masters({ commit, state }) {
      // ２回の取得は必要ない
      if (state.cmn_masters.master_banks.length > 0) return true;
      Vue.$log.info('BFF_CMN_00018 マスタ一括取得');

      return await Vue.http.get(state.api + 'all_masters').then(
        // 正常系
        (response) => {
          commit('set_all_masters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * TODO: 疎通確認できたらちゃんと実装する
     * 商品マスタ検索
     */
    async get_promas_products_search({ commit, state }) {
      Vue.$log.info('商品マスタ検索');

      return await Vue.http
        .get(
          state.api +
            'promas/products/search?master_product_type_id=18&id=1&name=M&tenant_id=9b9e2ae4-d53b-49b5-b6ae-2417060227e3',
        )
        .then(
          // 正常系
          (response) => {
            console.log(response);
            commit('set_promas_products_search', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * TODO: 疎通確認できたらちゃんと実装する
     * 商品マスタグラフ表示用マスタ取得
     */
    async get_promas_masters_for_graph({ commit, state }) {
      Vue.$log.info('商品マスタグラフ表示用マスタ取得');

      return await Vue.http.get(state.api + 'promas/masters/graph').then(
        // 正常系
        (response) => {
          console.log(response);
          commit('set_promas_masters_for_graph', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * TODO: 疎通確認できたらちゃんと実装する
     * 画像参照用署名付きURL取得
     */
    async get_promas_signed_download_url({ commit, state }, file_name) {
      Vue.$log.info('画像参照用署名付きURL取得');

      return await Vue.http
        .get(state.api + `promas/signed_download_url?file_name=${file_name}`)
        .then(
          // 正常系
          (response) => {
            console.log(response);
            commit('set_promas_signed_download_url', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_CMN_00026 Azure認証用のユーザデータ取得API
     * API概要
     * - 従業員IDで指定された従業員の情報を返却
     * - 認証後に各画面へ遷移するために必要なデータの取得
     * バリデーション
     * - 参照権限がない場合は403
     * - データが存在しない場合は404
     * ## SS API - cmn_0000X  Azure認証用のユーザデータ取得API
     * ## 処理フロー
     * ## 注意点 - なし
     */
    async get_gyro_user_me({ commit, state }) {
      Vue.$log.info('BFF_CMN_00026 Azure認証用のユーザデータ取得API');

      return Vue.http.get(state.api + `gyro_users/me`).then(
        (response) => {
          commit('set_gyro_user_me', response);
        },
        (error) => {
          Vue.$log.error(error);
          throw error;
        },
      );
    },

    // BFF_CMN_00029 gyro_user検索API
    async search_gyro_users_data({ commit, state }, params) {
      Vue.$log.info('BFF_CMN_00029 gyro_user検索API');

      return Vue.http
        .get(state.api + 'gyro_users/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_gyro_users_data', response);
            Vue.$log.debug(state.gyro_users_data);
            return response;
            // リミットオーバー
            // commit('set_search_result', response.is_limit_over);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    // BFF_CMN_00028 gyro_user更新API
    async post_gyro_users_data({ commit, state }, params) {
      Vue.$log.info('BFF_CMN_00028 gyro_user更新API');

      return await Vue.http.post(state.api + 'gyro_users', params).then(
        // 正常系
        (response) => {
          commit('set_gyro_user_data', response);
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          throw new Error(error);
        },
      );
    },

    // BFF_CMN_00027 gyro_user取得API
    async get_gyro_user_data({ commit, state }, id) {
      Vue.$log.info('BFF_CMN_00027 gyro_user取得API');

      return Vue.http.get(state.api + 'gyro_users/' + id).then(
        (response) => {
          commit('set_gyro_user_data', response);
        },
        (error) => {
          Vue.$log.error(error);
          throw error;
        },
      );
    },
  },
};
