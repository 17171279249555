<template lang="pug">
transition(name='fade')
  div(v-if='notLogin')
    .splash
      .wrap
        h1.burst
</template>

<script>
/**
 * import
 */
import { mapState } from 'vuex';

/**
 * Vue
 */
export default {
  /**
   * computed
   */
  computed: {
    /**
     * mapState
     */
    ...mapState('global', ['http_loading', 'current_user']),

    /**
     * 現在未ログインかどうか？
     * @returns {boolean}
     */
    notLogin: function () {
      // 今の時間
      let date = new Date();
      date = date.getTime() / 1000;

      // 今の時間よりも期限が未来の時間であればログインしている
      if (this.current_user.expires_at > date) {
        return false;
      } else {
        // Azure認証用のユーザデータ取得APIでエラーになっている場合は、ローディングを解除し、エラーメッセージを表示させる
        return !this.current_user.authenticatorError;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  background-color: $base-color-light;
  z-index: 100000;
  position: fixed;
}

.splash {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  position: absolute;
  top: 45%;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

h1 {
  content: url('../../assets/svg/logo.svg');
  width: auto;
  height: 100px;
  display: inline-block;
}

.burst {
  animation: burst_4138 2s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes burst_4138 {
  0% {
    opacity: 1;
  }
  99.999% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
