<template lang="pug">
ul
  slot
  li(v-if='show_customer_management')
    router-link(:to='{ name: "Crm/CustomerManagement" }')
      i.fas.fa-users
      | 顧客管理
  li(v-if='show_communication_management')
    router-link(:to='{ name: "Crm/CommunicationSearch" }')
      i.fas.fa-comments
      | コミュニケーション管理
  li(v-if='show_appoint_schedule_management')
    router-link(:to='{ name: "Reservation/ScheduleManagement" }')
      i.fas.fa-calendar-alt
      | 査定スケジュール
  li(v-if='show_sales_schedule_management')
    router-link(:to='{ name: "Reservation/ScheduleUpload" }')
      i.fas.fa-file-download
      | 営業スケジュール取り込み
  li(v-if='show_appointment_management')
    router-link(:to='{ name: "Reservation/AppointmentManagement" }')
      i.fas.fa-tty
      | アポイントメント管理
  li(v-if='show_sales_appraiser_management')
    router-link(:to='{ name: "Reservation/AppraiserManagement" }')
      i.fas.fa-id-card
      | 査定員管理
  li(v-if='show_budget_upload')
    router-link(:to='{ name: "Reservation/BudgetUpload" }')
      i.fas.fa-file-download
      | 営業目標取り込み
  li(v-if='show_sales_reserves_management')
    router-link(:to='{ name: "Fund/SalesReserveManagement" }')
      i.fas.fa-coins
      | 営業準備金管理
  li(v-if='show_event_management')
    router-link(:to='{ name: "Assessment/EventSearch" }')
      i.fas.fa-copy
      | 案件管理
  li(v-if='show_assessment_item')
    router-link(:to='{ name: "Assessment/AssessmentOfferSearch" }')
      i.icon-m.t-box-search
      | 商材査定
  li(v-if='show_settlement_management')
    router-link(:to='{ name: "Assessment/ContractSearch" }')
      i.fas.fa-file-contract
      | 決済管理
  li(v-if='show_master_management')
    router-link(:to='{ name: "MasterMaintenance" }')
      i.fas.fa-file-csv
      | マスタメンテナンス
  li(v-if='show_data_management')
    router-link(:to='{ name: "DataMaintenance" }')
      i.fas.fa-file-csv
      | データメンテナンス
  li(v-if='show_employee_management')
    router-link(:to='{ name: "Common/EmployeeManagement" }')
      i.fas.fa-id-card
      | 従業員管理
</template>
<script>
import { mapGetters } from 'vuex';

/**
 * Vue
 */
export default {
  /**
   * computed
   */
  computed: {
    /**
     * 全て権限管理に関するもの
     */
    ...mapGetters('global', [
      'show_customer_management',
      'show_identify_management',
      'show_communication_management',
      'show_appointment_management',
      'show_appoint_schedule_management',
      'show_sales_appraiser_management',
      'show_sales_schedule_management',
      'show_event_management',
      'show_event_related_assessment_item',
      'show_event_related_sales_management',
      'show_assessment_item',
      'show_settlement_management',
      'show_sales_reserves_management',
      'show_master_management',
      'show_campaign_master_management',
      'show_budget_upload',
      'show_data_management',
      'show_employee_management',
    ]),
  },
  /**
   * beforeMount
   */
  beforeMount: function () {
    // ここに権限まわりの処理が必要かもしれない
  },

  /**
   * mounted
   */
  mounted: function () {
    /**
     * $slotsが存在するのはトップページではなく必ずサイドメニュー
     */
    if (Object.keys(this.$slots).length) {
      this.$store.subscribe((mutation) => {
        // ルートが変わった時
        if (mutation.type === 'route/ROUTE_CHANGED') {
          // サイドメニューを展開している親にパス
          this.$emit('close');
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
ul {
  padding: 16px 0 24px;
  height: 100%;
  overflow-y: auto;
  li {
    display: table;
    width: 100%;
    a {
      color: #fff;
      display: table-cell;
      font-size: 1.8rem;
      height: 64px;
      vertical-align: middle;
      padding: 0 16px;
      i {
        margin-right: 8px;
      }
      &:hover {
        background-color: $base-color;
        transition: all 0.3s ease;
      }
    }
  }
}
.icon-m {
  width: 18px;
  display: inline-block;
  &.t-box-search {
    &:before {
      position: relative;
      top: 3px;
      content: url('../../assets/svg/icon-box-search.svg');
    }
  }
}
</style>
