<template lang="pug">
v-autocomplete-search(
  :disabled='disabled',
  :error-messages='errorMessagesWrap',
  v-model='local_value',
  :items='local_items'
)
</template>
<script>
export default {
  /**
   * props
   */
  props: {
    items: Array,
    disabled: Boolean,
    errorMessages: Array,
    value: Object,

    // itemsの指定がid以外の場合はidNameで受け取る
    idName: {
      type: String,
      default: 'id',
    },
  },

  /**
   * computed
   */
  computed: {
    errorMessagesWrap: function () {
      if (this.value && 'id' in this.value) {
        return this.errorMessages;
      } else {
        return [['Objectで初期化してください']];
        // return this.errorMessages;
      }
    },

    local_value: {
      // getter 関数
      get: function () {
        if (this.value && 'id' in this.value) {
          return this.value.id;
        } else {
          return 0;
        }
      },
      // setter 関数
      set: function (newValue) {
        if (newValue) {
          return this.$emit(
            'input',
            this.items.find((obj) => obj.id == newValue),
          );
        } else {
          return { id: null, name: null };
        }
      },
    },

    // idName対応
    local_items: function () {
      if (this.idName != 'id') {
        return this.items.map((element) => {
          element.id = element[this.idName];
          return element;
        });
      } else {
        return this.items;
      }
    },
  },
};
</script>
