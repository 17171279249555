<template lang="pug">
v-autocomplete(
  :readonly='readonly',
  :disabled='disabled',
  :error-messages='errorMessages',
  :error='is_error',
  :clearable='clearable && !readonly',
  v-model='local_value',
  item-text='name',
  item-value='id',
  :items='filter_items',
  no-filter,
  :search-input.sync.lazy='search',
  no-data-text='見つかりません',
  :required='isRequired',
  :placeholder='placeholder'
)
</template>
<script>
export default {
  /**
   * props
   */
  props: {
    items: Array,
    disabled: Boolean,
    errorMessages: Array,
    value: [String, Number, Array],

    readonly: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    // itemsの指定がid以外の場合はidNameで受け取る
    idName: {
      type: String,
      default: 'id',
    },
    // itemsの指定がid以外の場合はidNameで受け取る
    otherName: {
      type: String,
      default: 'name',
    },

    // HTML5のバリデート用
    isRequired: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    // 「その他」を含まない
    isNotOthers: Boolean,
  },

  /**
   * data
   */
  data: function () {
    return {
      // フィルタリングしたものを表示に使用するが、初期値としてpropsのitemsを使う
      filter_items: null,
      search: null,
      is_error: false,
      local_items: null,
    };
  },

  /**
   * computed
   */
  computed: {
    local_value: {
      // getter 関数
      get: function () {
        return this.value;
      },
      // setter 関数
      set: function (newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  /**
   * watch
   */
  watch: {
    search: function (val) {
      if (Array.isArray(this.items)) {
        if (val) {
          // 入力されている場合は配列をフィルタリングする
          this.filter_items = this.local_items.filter((obj) => {
            if (!this.isNotOthers && obj.keyword.indexOf('その他') >= 0) {
              return true;
            } else if (obj.keyword.indexOf(val) >= 0) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          // 入力されていない場合は元データを使う
          this.filter_items = this.local_items;
        }
      }
    },
    items: function () {
      this.init();
    },
  },
  /**
   * created
   */
  created: function () {
    this.init();
  },
  /**
   * methods
   */
  methods: {
    // 初期化&更新
    init() {
      if (!Array.isArray(this.items)) {
        this.$log.error('VAutocompleteSearch', '配列ではありません');
        this.is_error = true;
        this.error_messages = '配列が設定されていません';
      } else {
        this.local_items = this.items.map((element) => {
          element.name = element[this.otherName];
          element.id = element[this.idName];
          element.keyword = element.keyword + element.name;
          return element;
        });

        this.filter_items = this.items;
      }
    },
  },
};
</script>
