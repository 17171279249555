import Vue from 'vue';
import _ from 'lodash';

/**
 *CRMに関連するStore
 */
export const crm = {
  namespaced: true,
  state: {
    /**
     * APIのURLとして利用
     */
    api: 'crm/v1/',

    /**
     * コミュニケーション管理
     */
    communication_customers_search: { customers: [] },
    customers_inflows: null,
    customers_dialogues: null,
    customers: {
      contacts: [],
    },
    reception_related: null,
    communication_form: null,
    customer_form: null,
    analogical_customers_search: [],
    analogical_customers_or_search: [],
    assessment_request_bring_form: null,
    assessment_offer_visit_form: null,
    claim_and_cooling_off_form: null,
    customer_inflows: null,

    /**
     * CT-1連携
     * 値の受け渡しが多岐に渡るので、以下に関してはActionではなく通常のミューテーションを使う
     */
    session_id: null,
    kddi_03_number: null,
    received_call_number: null, // 入電番号
    // CT-1連携部分のうち、kddi_03_numberに紐づくキャンペーンなどソフトフォンに関わる情報 こちらはActionで更新
    softphone: null,

    // 査定予定商材
    expected_assessment_item_summary: null,
    expected_assessment_items_form: null,
    sense_of_value_and_purchaser: null,
    expected_assessment_items_calculate: null,

    /**
     * 顧客検索
     */
    customer_search_form: {
      advertisement_item_types: [],
      advertisement_numbers: [],
      appointment_conclusion_statuses: [],
      appointment_exclusion_reasons: [],
      appointment_failure_reasons: [],
      appointment_hold_reasons: [],
      appointment_conclusion_second_statuses: [],
      appointment_failure_second_reasons: [],
      appointment_ranks: [],
      call_connection_statuses: [],
      campaign_type1s: [],
      campaign_type2s: [],
      campaigns: [],
      complaint_contents: [],
      dialogue_types: [],
      expected_assessment_item_types: [],
      follow_up_reasons: [],
      follow_up_second_reasons: [],
      handling_statuses: [],
      inflow_types: [],
      information_labels: [],
      inquiry_types: [],
      next_handling_employees: [],
      organizations: [],
      reservation_types: [],
      scheduled_handling_employees: [],
    },
    customers_search: { customers: [] },

    // コミュニケーション情報（レスポンス退避用）
    temp_communication: null,

    /**
     * 店舗顧客
     */
    store_customer: {
      customer_link_id: null,
      last_name: null,
      first_name: null,
      kana_last_name: null,
      kana_first_name: null,
      registration_age: null,
      contacts: [],
      addresses: [],
      antisocial_check1: null,
      antisocial_check1_employee_id: null,
      antisocial_check2: null,
      antisocial_check2_employee_id: null,
      antisocial_suspicious: null,
      lock_version: null,
      event_id: null,
      gender: null,
      birth_date: null,
      territory_id: null,
    },
    store_form: {
      campaigns: [],
      prefectures: [],
    },

    // アライアンス情報
    alliances: [],

    // ラベル検索結果
    master_labels_search: {
      is_over_limit: null,
      master_labels: [],
    },

    // （データメンテナンス）流入登録日
    inflow_dates_response: { inflows: [] },
  },

  getters: {
    filtered_analogical_customers_search(state) {
      return state.analogical_customers_search.filter(
        (customer) =>
          customer.customer_link_id != state.customers.customer_link_id,
      );
    },
  },
  mutations: {
    set_communication_customers_search(state, payload) {
      state.communication_customers_search = payload;
    },

    set_customers_inflows(state, payload) {
      state.customers_inflows = payload;
    },
    update_customers_inflow(state, payload) {
      if (state.customers_inflows == null) {
        return;
      }
      const inflow = state.customers_inflows.find(
        (inflow) => inflow.inflow_id == payload.id,
      );
      if (inflow == undefined) {
        return;
      }
      inflow.is_handling_unrequired = payload.is_handling_unrequired;
      inflow.corresponding_status_english =
        payload.corresponding_status_english;
      inflow.handling_status = payload.handling_status;
    },

    set_customers_dialogues(state, payload) {
      state.customers_dialogues = payload;
    },
    set_customers(state, payload) {
      state.customers = payload;
    },
    set_reception_related(state, payload) {
      state.reception_related = payload;
    },
    set_communication_form(state, payload) {
      state.communication_form = payload;
    },

    set_customer_form(state, payload) {
      if (payload) {
        // 都道府県だけ特別な処理が必要
        payload.prefectures = payload.prefectures.map((element) => {
          if (element.name) {
            return element.name;
          } else {
            return element;
          }
        });
        state.customer_form = payload;
      }
    },
    set_analogical_customers_search(state, payload) {
      state.analogical_customers_search = payload;
    },
    set_assessment_offer_bring_form(state, payload) {
      state.assessment_offer_bring_form = payload;
    },
    set_assessment_offer_visit_form(state, payload) {
      if (payload) {
        // 都道府県だけ特別な処理が必要
        payload.prefectures = payload.prefectures.map((element) => {
          if (element.name) {
            return element.name;
          } else {
            return element;
          }
        });
        state.assessment_offer_visit_form = payload;
      }
    },
    set_claim_and_cooling_off_form(state, payload) {
      state.claim_and_cooling_off_form = payload;
    },
    set_customer_inflows(state, payload) {
      state.customer_inflows = payload;
    },
    set_expected_assessment_item_summary(state, payload) {
      state.expected_assessment_item_summary = payload;
    },
    set_expected_assessment_items_form(state, payload) {
      state.expected_assessment_items_form = payload;
    },
    set_sense_of_value_and_purchaser(state, payload) {
      state.sense_of_value_and_purchaser = payload;
    },
    set_session_id(state, payload) {
      state.session_id = payload;
    },
    set_kddi_03_number(state, payload) {
      state.kddi_03_number = payload;
    },
    set_received_call_number(state, payload) {
      state.received_call_number = payload;
    },
    set_softphone(state, payload) {
      state.softphone = payload;
    },
    set_expected_assessment_items_calculate(state, payload) {
      state.expected_assessment_items_calculate = payload;
    },
    set_customer_search_form(state, payload) {
      state.customer_search_form = payload;
    },
    set_customers_search(state, payload) {
      state.customers_search = payload;
    },
    reset_ct1(state) {
      // CT1連携に関する情報を全てクリアする
      state.session_id = null;
      state.kddi_03_number = null;
      state.received_call_number = null;
      state.softphone = null;
    },
    set_temp_communication(state, payload) {
      state.temp_communication = _.cloneDeep(payload);
    },
    set_store_customer(state, payload) {
      state.store_customer = payload;
    },
    set_analogical_customers_or_search(state, payload) {
      state.analogical_customers_or_search = payload;
    },
    set_store_form(state, payload) {
      state.store_form = payload;
    },
    set_data_alliances(state, payload) {
      state.alliances = payload;
    },
    set_master_labels_search(state, payload) {
      state.master_labels_search = payload;
    },
    /**
     * BFF_CRM_00083 （データメンテナンス）流入登録日修正フォーム情報取得
     * BFF_CRM_00084 （データメンテナンス）流入登録日検索
     * BFF_CRM_00085 （データメンテナンス）流入登録日更新
     */
    set_inflow_dates_response(state, payload) {
      state.inflow_dates_response = payload;
    },
  },
  actions: {
    /**
     * BFF_CRM_00001 顧客検索フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_customer_search_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.customer_search_form.advertisement_item_types.length)
        return true;
      Vue.$log.info('BFF_CRM_00001 顧客検索フォーム情報取得');

      return Vue.http.get(state.api + 'customer_search_form').then(
        // 正常系
        (response) => {
          commit('set_customer_search_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00008 類似顧客情報検索
     *
     * API概要 - 「関連する顧客検索」や「既存顧客を検索」で使用するAPI - パラメーターがnilの場合は顧客情報を全件取得 - 指定件数が100件(件数は変わるかも)を超えた場合はis_over_limitがtrueになって、それ以上表示されない。
     * ## バリデーション - 入力値が不正な場合は400 - 参照権限がない場合は403
     * ## SS API - crm_00015 類似顧客情報検索API
     * ## 処理フロー パラメーターを受け取る ( 取得したパラメータの顧客リンクID以外で顧客テーブルをAND検索 検索結果に顧客リンクをINNER JOIN JOIN結果に顧客リンクIDで検索 )←ここまで1行でやれたら理想 取得した類似顧客情報を返却する
     * ## 注意 - 名寄せされた顧客は名寄せ後の値のみを表示させる - 例）Aさんの検索結果が4件あったので、名寄せして1件にまとめた場合はその1件のみが表示される
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_analogical_customers_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00008 類似顧客情報検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .get(state.api + 'analogical_customers/search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit(
              'set_analogical_customers_search',
              response.analogical_customers,
            );
            return response.analogical_customers;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_CRM_00026 コミュニケーション検索
     * API概要 - 顧客を検索し、結果を返す
     *
     * ## バリデーション - 参照権限がない場合は403
     * ## SS API - crm_00111 コミュニケーション検索
     * ## 処理フロー - クエリパラメータに含まれる情報を元に検索を行い、合致した顧客情報をCommunicationCustomerSearchResultsにいれて返す
     * ## 注意点 - 主に受電した際に使用する顧客検索機能、名寄せされた顧客は検索結果に出さない（顧客リレーション.顧客IDに存在する顧客のみが出力）
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_communication_customers_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00026 コミュニケーション検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'communication_customers/search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_communication_customers_search', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_CRM_00002 顧客検索
     * API概要 - 顧客を検索し、結果を返す
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_customers_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00002 顧客検索');

      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'customers/search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_customers_search', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_CRM_00027流入・応対履歴取得API
     * API概要 - 選択した顧客に紐付く流入履歴と応対記録履歴を取得する
     *
     * ## バリデーション - 参照権限がない場合は403
     * ## SS API - crm_00111 コミュニケーション検索
     * ## 処理フロー - クエリパラメータに含まれる情報を元に検索を行い、合致した顧客情報をCommunicationCustomerSearchResultsにいれて返す
     * ## 注意点 - 主に受電した際に使用する顧客検索機能、名寄せされた顧客は検索結果に出さない（顧客リレーション.顧客IDに存在する顧客のみが出力）
     *
     * @param commit
     * @param state
     * @param customer_link_id
     */
    async get_customers_inflows_with_dialogues(
      { commit, state },
      customer_link_id,
    ) {
      Vue.$log.info('BFF_CRM_00027流入・応対履歴取得API');

      return Vue.http
        .get(
          state.api +
            'customers/' +
            customer_link_id +
            '/inflows_with_dialogues',
        )
        .then(
          // 正常系
          (response) => {
            commit(
              'set_customers_inflows',
              response.inflows_with_dialogues.inflows,
            );
            commit(
              'set_customers_dialogues',
              response.inflows_with_dialogues.dialogues,
            );
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_CRM_00029 コミュニケーション登録更新フォーム情報取得API
     *
     * @param commit
     * @param state
     */
    async get_communication_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.communication_form) return true;
      Vue.$log.info('BFF_CRM_00029コミュニケーション登録更新フォーム情報取得');

      return Vue.http.get(state.api + 'communication_form').then(
        // 正常系
        (response) => {
          commit('set_communication_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_CRM_00032 査定依頼（訪問査定）画面入力フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_assessment_offer_visit_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.assessment_offer_visit_form) return true;

      Vue.$log.info(
        'BFF_CRM_00032 査定依頼（訪問査定）画面入力フォーム情報取得',
      );

      return Vue.http.get(state.api + 'assessment_offer/visit_form').then(
        // 正常系
        (response) => {
          commit('set_assessment_offer_visit_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_CRM_00039 査定予定商材入力フォーム情報取得API
     *
     * @param commit
     * @param state
     */
    async get_expected_assessment_item_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.expected_assessment_item_summary) return true;

      Vue.$log.info(
        'BFF_CRM_00032 査定依頼（訪問査定）画面入力フォーム情報取得',
      );

      return Vue.http.get(state.api + 'expected_assessment_item_form').then(
        // 正常系
        (response) => {
          // BFFの実装待ちでエラーがでるのでif文をいれておく
          if (response) {
            // ここは情報量が多いのでわけておく
            commit(
              'set_expected_assessment_item_summary',
              response.expected_assessment_item_summary,
            );
            commit(
              'set_expected_assessment_items_form',
              response.expected_assessment_items_form,
            );
            commit(
              'set_sense_of_value_and_purchaser',
              response.sense_of_value_and_purchaser,
            );
          }
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00040 査定予定商材注釈取得
     *
     * @param commit
     * @param state
     */
    async get_expected_assessment_item_types_annotation({ state }, id) {
      Vue.$log.info('BFF_CRM_00040 査定予定商材注釈取得');

      // ここは各Componentに任せる
      return Vue.http.get(
        state.api + `expected_assessment_item_types/${id}/annotation`,
      );
    },

    /**
     * BFF_CRM_00041 ご意見対応・クーリングオフ入力フォーム情報取得API
     *
     * @param commit
     * @param state
     */
    async get_claim_and_cooling_off_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.claim_and_cooling_off_form) return true;
      Vue.$log.info(
        'BFF_CRM_00041 ご意見対応・クーリングオフ入力フォーム情報取得API',
      );

      return Vue.http.get(state.api + 'claim_and_cooling_off_form').then(
        // 正常系
        (response) => {
          commit('set_claim_and_cooling_off_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     *  BFF_CRM_00033 査定依頼（持込査定）画面入力フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_assessment_offer_bring_form({ commit, state }) {
      if (state.assessment_offer_bring_form) return true;
      Vue.$log.info(
        'BFF_CRM_00033 査定依頼（持込査定）画面入力フォーム情報取得',
      );

      return Vue.http.get(state.api + 'assessment_offer/bring_form').then(
        // 正常系
        (response) => {
          commit('set_assessment_offer_bring_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_CRM_00030 コミュニケーション情報取得
     *
     * @param commit
     * @param state
     */
    async get_communication({ commit, state }, id) {
      // ここは呼び元で動作が変わるためcommitは使わない
      Vue.$log.info('BFF_CRM_00030 コミュニケーション情報取得');

      return Vue.http.get(state.api + 'communication/' + id).then(
        // 正常系
        (response) => {
          // レスポンスをリクエストとして使用している為退避させておく
          commit('set_temp_communication', response);

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00007 顧客入力フォーム情報取得
     *
     * API概要 - 顧客情報登録・更新画面の初期表示用項目を取得 - コミュニケーション管理_お客様情報入力画面の初期表示用項目を取得
     * ## バリデーション - 参照権限がない場合は403
     * ## SS API - crm_00162 マスタ取得API
     *
     * @param commit
     * @param state
     */
    async get_customer_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.customer_form) return true;

      Vue.$log.info('BFF_CRM_00007 顧客入力フォーム情報取得');

      return Vue.http.get(state.api + 'customer_form').then(
        // 正常系
        (response) => {
          commit('set_customer_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00010 顧客情報取得API
     *
     * API概要 - 選択した顧客に紐付く流入履歴と応対記録履歴を取得する
     *
     * ## バリデーション - path parameter不正時は400 - 参照権限がない場合は403 - 指定したidの顧客データが存在しない場合、あるいはURIの間違い時は404
     * ## SS API - crm_00019 流入履歴一覧取得 - crm_00112 顧客応対記録一覧取得
     * ## 処理フロー 1. 顧客IDをパラメータにして流入履歴一覧取得API、顧客応対記録一覧取得APIを各々コール 2. 2つのレスポンスを組み合わせる
     * ## 注意点 - 顧客データは存在するがそれに紐付く流入、応対履歴がない場合はそれぞれ空の配列を返却
     *
     * @param commit
     * @param state
     * @param customer_link_id
     */
    async get_customers_show({ commit, state }, customer_link_id) {
      Vue.$log.info('BFF_CRM_00010 顧客情報取得API');

      return Vue.http
        .get(state.api + 'customers/' + customer_link_id + '/show')
        .then(
          // 正常系
          (response) => {
            commit('set_customers', response);
            return response;
          },
          // 異常系
          (error) => {
            if (error.response && error.response.status === 404) {
              Vue.toasted.global.error({
                message: '顧客情報が見つかりませんでした。',
              });
            } else {
              Vue.$log.error(error);
            }
          },
        );
    },

    async get_customers_show_without_commit({ state }, customer_link_id) {
      Vue.$log.info('BFF_CRM_00010 顧客情報取得API');

      return Vue.http
        .get(state.api + 'customers/' + customer_link_id + '/show')
        .then(
          // 正常系
          (response) => {
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    async get_base_customers_show({ commit, state }, customer_link_id) {
      Vue.$log.info('名寄せ解除用情報取得API');

      return Vue.http
        .get(state.api + 'customers/' + customer_link_id + '/show_base')
        .then(
          // 正常系
          (response) => {
            commit('set_customers', response);
            return response;
          },
          // 異常系
          (error) => {
            if (error.response.status === 404) {
              Vue.toasted.global.error({
                message: error.response.data.messages,
              });
            } else {
              Vue.$log.error(error);
            }
          },
        );
    },

    /**
     * BFF_CRM_00046 サジェスト日時一覧取得
     */
    async post_suggested_date_times({ state }, params) {
      Vue.$log.info('BFF_CRM_00046 サジェスト日時一覧取得');

      return Vue.http.post(state.api + 'suggested_date_times', params).then(
        // 正常系
        (response) => {
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);

          Vue.toasted.global.error({
            message: error.response.data.details
              .map((d) => d.field_name + ': ' + d.field_messages.join())
              .join(),
          });
        },
      );
    },
    /**
     * BFF_CRM_00047 アポイントメント登録
     */
    async post_appointments({ state }, params) {
      Vue.$log.info('BFF_CRM_00047 アポイントメント登録');

      return Vue.http.post(state.api + 'appointments', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00047 アポイントメント登録',
          });

          return response;
        },
        // 異常系
        (error) => {
          if (error.response.status === 404) {
            Vue.toasted.global.error({
              message:
                '指定した日時でアポイントメントを登録できませんでした。検索条件を入力し直して再度空き日時を検索してください',
            });
          } else {
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00047 アポイントメント登録',
            });
          }

          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00050 応対関連情報取得
     * API概要 - 流入ID もしくは 応対記録ID を基にその応対の関連情報を取得する。
     * 「流入ID もしくは 応対記録ID」という制約ため、クエリパラメータにはどちらかにしかIDを入力できないこととする。
     *
     * ## バリデーション - 参照権限がない場合は403 - パラメータが不正の場合は400
     * ## SS API - crm_00166 応対関連情報取得 - rsv_00084 アポイント一覧取得（査定依頼ID） - ass_00107 案件情報一覧取得 - ass_00325 契約情報一覧取得 - crm_00161 コミュニケーション応対記録一覧取得
     * ## 処理フロー - クエリパラメータのどちらかにしか値が入力されていないかどうかを判別する。 - 入力されているクエリによって処理を分岐させる。
     *
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_reception_related({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00050 応対関連情報取得');

      return Vue.http.get(state.api + 'reception_related/', { params }).then(
        // 正常系
        (response) => {
          commit('set_reception_related', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00052 更新用顧客情報取得
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_customers({ state }, customer_link_id) {
      Vue.$log.info('BFF_CRM_00052 更新用顧客情報取得');

      return Vue.http.get(state.api + 'customers/' + customer_link_id).then(
        // 正常系
        (response) => {
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_CRM_00051 流入一覧取得
     */
    async get_customers_inflows({ commit, state }, customer_link_id) {
      Vue.$log.info('BFF_CRM_00051 流入一覧取得');

      return Vue.http
        .get(state.api + '/customers/' + customer_link_id + '/inflows/')
        .then(
          // 正常系
          (response) => {
            commit('set_customer_inflows', response.inflows);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_CRM_00028 流入更新API
     * API概要 - 選択した流入を更新する
     *
     * ## バリデーション - 参照権限がない場合は403 - データが存在しない場合は404
     * ## SS API - crm_00113 流入削除 ## 処理フロー 1. 流入IDをパラメータにしてSSをコール、レスポンスをそのままFEに返す。
     *
     * @param commit
     * @param state
     */
    async patch_inflows({ state }, params) {
      Vue.$log.info('BFF_CRM_00028 流入更新API');

      return Vue.http.patch(state.api + 'inflows/' + params.id, params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: response.message,
          });
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00037 コミュニケーション情報登録更新
     */
    async post_communication({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00037 コミュニケーション情報登録更新');

      return Vue.http.post(state.api + 'communication', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00037 コミュニケーション情報登録更新',
          });
          // レスポンスをリクエストとして使用している為退避させておく
          commit('set_temp_communication', response);

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);

          const has_information_label_error =
            error.response.data.details[0].field_name ===
            'customer_information_tab.information_labels';

          if (error.response.status === 409) {
            Vue.toasted.global.error({
              message:
                '他のユーザと競合しており登録に失敗しました。再度開き直し入力した内容を反映してください',
            });
          } else if (has_information_label_error) {
            // フォームにエラーメッセージを出すことが難しいためtoastedでエラーを出す
            Vue.toasted.global.error({
              message: error.response.data.details
                .map((d) => d.field_messages.join())
                .join(),
            });
          } else {
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00037 コミュニケーション情報登録更新',
            });
          }
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00054 アポイントメントキャンセル
     */
    async patch_appointments_cancel({ state }, params) {
      Vue.$log.info('BFF_CRM_00054 アポイントメントキャンセル');

      try {
        const response = await Vue.http.patch(
          state.api + 'appointments/' + params.id + '/cancel',
          params.body,
        );
        Vue.toasted.global.success({
          message: '【成功】BFF_CRM_00054 アポイントメントキャンセル',
        });
        return response;
      } catch (error) {
        Vue.$log.error(error);
        if (error.response.status !== 400) {
          Vue.toasted.global.error({
            message: '【失敗】BFF_CRM_00054 アポイントメントキャンセル',
          });
        }

        Vue.toasted.global.error({
          message: error.response.data.details
            .map((d) => d.field_messages.join())
            .join(),
        });
        throw error;
      }
    },

    /**
     * BFF_CRM_00009 顧客情報登録更新
     */
    async post_customers({ state }, params) {
      Vue.$log.info('BFF_CRM_00009 顧客情報登録更新');

      return Vue.http.post(state.api + 'customers', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00009 顧客情報登録更新',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);

          const has_information_label_error =
            error.response.data.details[0].field_name === 'information_labels';

          if (error.response.status === 409) {
            Vue.toasted.global.error({
              message:
                '他のユーザと競合しており登録に失敗しました。再度開き直し入力した内容を反映してください',
            });
          } else if (has_information_label_error) {
            // フォームにエラーメッセージを出すことが難しいためtoastedでエラーを出す
            Vue.toasted.global.error({
              message: error.response.data.details
                .map((d) => d.field_messages.join())
                .join(),
            });
          } else {
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00009 顧客情報登録更新',
            });
          }
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00053 反社DB検索
     * API概要 - 反社DBに該当する顧客を検索する
     */
    get_antisocials({ state }, params) {
      Vue.$log.info('BFF_CRM_00053 反社DB検索');
      return Vue.http.get(state.api + 'antisocials', { params });
    },

    /**
     * BFF_CRM_00045 査定予定商材算出
     * API概要
     */
    async post_expected_assessment_items_calculate({ state, commit }, params) {
      Vue.$log.info('BFF_CRM_00045 査定予定商材算出');

      return Vue.http
        .post(state.api + 'expected_assessment_items/calculate', params)
        .then(
          // 正常系
          (response) => {
            // タブ移動後に保持するためにstateを使う
            commit('set_expected_assessment_items_calculate', response);
            // データの制御は返却先でも行う
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_CRM_00049 未割り当てアポイントメント登録
     * API概要 - コミュニケーション画面_査定依頼で、お客様希望のアポイント日時を入力し査定員未割り当てでアポイントを新規登録する
     */
    async post_unallocated_appointment({ state }, params) {
      Vue.$log.info('BFF_CRM_00049 未割り当てアポイントメント登録');

      return Vue.http.post(state.api + 'unallocated_appointments', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00049 未割り当てアポイントメント登録',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);

          Vue.toasted.global.error({
            message: '【失敗】BFF_CRM_00049 未割り当てアポイントメント登録',
          });

          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00055 ソフトフォン情報取得
     * API概要 - GYRO-ソフトフォン連携に関わる情報を返す
     */
    async get_softphone({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00055 ソフトフォン情報取得');
      return Vue.http.get(state.api + 'softphone', { params }).then(
        // 正常系
        (response) => {
          commit('set_softphone', response.softphone);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00058 応対記録更新API
     * API概要 - 指定した応対記録を更新する
     */
    async patch_dialogue({ state }, params) {
      Vue.$log.info('BFF_CRM_00058 応対記録更新API');
      const { id } = params;
      const { request } = params;
      return Vue.http.patch(state.api + `dialogues/${id}`, request).then(
        // 正常系
        (response) => {
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00022 顧客名寄せ処理API
     * API概要 - 選択した顧客を対象の顧客に名寄せする
     */
    async identify_customer_links({ state }, params) {
      Vue.$log.info('BFF_CRM_00022 顧客名寄せ処理API');
      const path_params = params.customer_link_id;
      const query_params = params.target_customer_link_id;

      return Vue.http
        .patch(
          state.api +
            'customer_links/' +
            path_params +
            '/identify?target_customer_link_id=' +
            query_params,
        )
        .then(
          // 正常系
          () => {
            Vue.toasted.global.success({
              message: '【成功】BFF_CRM_00022 顧客名寄せ処理API',
            });
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00022 顧客名寄せ処理API',
            });
            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_CRM_00056 RESIDENT LINE 通知
     * API概要 - コミュニケーションIDを指定して、LOOPASSを使って顧客の電話番号に通知を送る
     */
    async post_resident_notification({ state }, params) {
      Vue.$log.info('BFF_CRM_00056 RESIDENT LINE 通知');

      return Vue.http.post(state.api + 'resident/notification', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00056 RESIDENT LINE 通知',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          if (error.response.status === 404) {
            Vue.toasted.global.error({
              message:
                '指定したコミュニケーションIDのデータが見つかりませんでした。再度開き直し入力した内容を反映してください',
            });
          } else {
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00056 RESIDENT LINE 通知',
            });
          }

          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00067 案件関連店舗顧客取得
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_customer_by_event_id({ commit, state }, event_id) {
      Vue.$log.info('BFF_CRM_00067 案件関連店舗顧客情報取得');

      return Vue.http
        .get(state.api + 'store_customers/customer_by_event_id', {
          params: { id: event_id },
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_store_customer', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_CRM_00068 店舗顧客情報登録更新
     *
     * @param state
     * @param params
     */
    async post_store_customers({ state }, params) {
      Vue.$log.info('BFF_CRM_00068 店舗顧客情報登録更新');

      return Vue.http.post(state.api + 'store_customers', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '【成功】BFF_CRM_00068 店舗顧客情報登録更新',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          if (error.response.status === 409) {
            Vue.toasted.global.error({
              message:
                '他のユーザと競合しており登録に失敗しました。再度開き直し入力した内容を登録してください',
            });
          } else {
            Vue.toasted.global.error({
              message: '【失敗】BFF_CRM_00068 店舗顧客情報登録更新',
            });
          }
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00070 キャンペーンマスタアップロードAPI
     *
     * ## SS API - crm_00188 キャンペーンマスタ登録更新API
     *
     * @param state
     * @param file
     */
    async post_campaigns_csv({ state }, file) {
      Vue.$log.info('BFF_CRM_00070 キャンペーンマスタアップロードAPI');

      let data = new FormData();
      data.append('file', file);

      return Vue.http.post(state.api + 'masters/import_campaigns', data).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: '処理が失敗しました',
          });

          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00071 関連マスタアップロードAPI
     *
     * ## SS API - crm_00189 マスタデータ登録更新API
     *
     * @param state
     * @param params
     */
    async post_master_data_csv({ state }, params) {
      Vue.$log.info('BFF_CRM_00071 関連マスタアップロードAPI');

      let data = new FormData();
      data.append('file', params.file);
      data.append('master_data_type', params.advertisment_item_type);

      return Vue.http.post(state.api + 'masters/import_relations', data).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: '処理が失敗しました',
          });

          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00072 類似顧客情報or検索
     *
     * API概要 - パラメーターがnilの場合は顧客情報を全件取得 - 指定件数が100件(件数は変わるかも)を超えた場合はis_over_limitがtrueになって、それ以上表示されない。
     * ## バリデーション - 入力値が不正な場合は400 - 参照権限がない場合は403
     * ## SS API - crm_00192 類似顧客情報or検索API
     * ## 処理フロー パラメーターを受け取る ( 取得したパラメータの顧客リンクID以外で顧客テーブルをOR検索 検索結果に顧客リンクをINNER JOIN JOIN結果に顧客リンクIDで検索 )←ここまで1行でやれたら理想 取得した類似顧客情報を返却する
     * ## 注意 - 名寄せされた顧客は名寄せ後の値のみを表示させる - 例）Aさんの検索結果が4件あったので、名寄せして1件にまとめた場合はその1件のみが表示される
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_analogical_customers_or_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00072 類似顧客情報or検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .get(state.api + 'analogical_customers/or_search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit(
              'set_analogical_customers_or_search',
              response.analogical_customers,
            );
            return response.analogical_customers;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_CRM_00074 都道府県検索API
     *
     * パラメータ：電話番号と連絡先保持者を配列で[json ]
     * ## SS API - crm_00194 都道府県検索API
     *
     * @param state
     * @param params
     */
    async get_prefectures_search({ state }, params) {
      Vue.$log.info('BFF_CRM_00074 都道府県検索API');

      return Vue.http.get(state.api + 'prefectures/search', { params }).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00075 オファーキャンペーンマスタアップロードAPI
     *
     * ## SS API - crm_00196 offer_campaignsアップロード
     *
     * @param state
     * @param file
     */
    async post_offer_campaigns_csv({ state }, file) {
      Vue.$log.info('BFF_CRM_00075 オファーキャンペーンマスタアップロードAPI');

      let data = new FormData();
      data.append('file', file);

      return Vue.http
        .post(state.api + 'masters/import_offer_campaigns', data)
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '処理が成功しました',
            });

            return response;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: '処理が失敗しました',
            });

            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_CRM_00076 オファーキャンペーン関連マスタアップロードAPI
     *
     * ## SS API - crm_00197 master_campaign_offersアップロード
     *
     * @param state
     * @param file
     */
    async post_campaign_offers_csv({ state }, file) {
      Vue.$log.info(
        'BFF_CRM_00076 オファーキャンペーン関連マスタアップロードAPI',
      );

      let data = new FormData();
      data.append('file', file);

      return Vue.http
        .post(state.api + 'masters/import_campaign_offers', data)
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '処理が成功しました',
            });

            return response;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: '処理が失敗しました',
            });

            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_CRM_00077 店舗顧客登録更新フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_store_form({ commit, state }) {
      if (_.values(state.store_form).some((v) => !_.isEmpty(v))) return;
      Vue.$log.info('BFF_CRM_00077 店舗顧客登録更新フォーム情報取得API');

      return Vue.http.get(state.api + 'store_form').then(
        // 正常系
        (response) => {
          commit('set_store_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_CRM_00078 CASH SMS通知
     *
     * @param state
     * @param params
     */
    async post_cash_sms({ state }, params) {
      Vue.$log.info('BFF_CRM_00078 CASH SMS通知API');

      return Vue.http.post(state.api + 'cash/sms', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: error.response.data.details
              .map((el) => el.field_messages.join())
              .join(),
          });

          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_CRM_00080 アライアンス情報取得API
     * API概要 - 保存済のアライアンスデータを取得する
     */
    async get_alliances({ commit, state }, customer_link_id) {
      Vue.$log.info('BFF_CRM_00080 アライアンス情報取得API');

      return Vue.http
        .get(state.api + 'customers/' + customer_link_id + '/alliances')
        .then(
          // 正常系
          (response) => {
            commit('set_data_alliances', response.alliances);

            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);

            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_CRM_00079 アライアンス登録更新API
     * API概要 - 保存済のアライアンスデータを登録更新する
     *
     * @param commit
     * @param state
     * @param params
     */
    async post_alliances({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00079 アライアンス登録更新API');

      let customer_link_id = params.customer_link_id;
      let data = params.data;

      return Vue.http
        .post(
          state.api + 'customers/' + customer_link_id + '/alliances/upsert',
          data,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '処理が成功しました',
            });
            commit('set_data_alliances', response.alliances);

            return Promise.resolve(response);
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: error.response.data.details
                .map((el) => el.field_messages.join())
                .join(),
            });

            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_CRM_00081 ラベル検索API
     * API概要 - ラベルをnameの部分一致検索を行い、結果を返す
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_master_labels_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00081 ラベル検索API');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .get(`${state.api}masters/labels/search`, {
          params,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_master_labels_search', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_CRM_00082 ラベル登録更新API
     * API概要 - ラベルの登録更新を行う
     *
     * @param state
     * @param params
     */
    async post_master_label({ state }, params) {
      Vue.$log.info('BFF_CRM_00082 ラベル登録更新API');

      return Vue.http.post(`${state.api}masters/label`, params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });

          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: '処理が失敗しました',
          });

          return Promise.reject(error);
        },
      );
    },
    /**
     * BFF_CRM_00083 （データメンテナンス）流入登録日修正フォーム情報取得
     *
     * API概要 - 流入登録日修正画面の初期表示用項目を取得
     *
     * ## バリデーション - 参照権限がない場合は403 —
     *
     * @param commit
     * @param state
     */
    async get_inflow_dates({ commit, state }) {
      Vue.$log.info(
        'BFF_CRM_00083 （データメンテナンス）流入登録日修正フォーム情報取得',
      );

      return Vue.http.get(state.api + 'maintenance/inflows/dates').then(
        // 正常系
        (response) => {
          commit('set_inflow_dates_response', response);
          return response.inflows;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_CRM_00084 （データメンテナンス）流入登録日検索
     *
     * API概要 - 流入idをkeyに流入に紐づく、流入登録日を検索する
     *
     * ## バリデーション - 参照権限がない場合は403 —
     * ## SS API - （データ修正用）流入検索
     *
     * @param commit
     * @param state
     */
    async post_inflow_dates_search({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00084 （データメンテナンス）流入登録日検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .post(state.api + 'maintenance/inflows/dates/search', params)
        .then(
          // 正常系
          (response) => {
            commit('set_inflow_dates_response', response);
            return response.inflows;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_CRM_00085 （データメンテナンス）流入登録日更新
     *
     * API概要 - 流入idをkeyに流入に紐づく、流入登録日を更新する
     *
     * ## バリデーション - 参照権限がない場合は403 —
     * ## SS API - （データ修正用）流入更新
     *
     * @param commit
     * @param state
     */
    async patch_inflow_dates_update_all({ commit, state }, params) {
      Vue.$log.info('BFF_CRM_00085 （データメンテナンス）流入登録日更新');
      let toast = Vue.toasted.global.load({
        message: '更新中',
      });

      return Vue.http
        .patch(state.api + 'maintenance/inflows/dates/update_all', params)
        .then(
          // 正常系
          (response) => {
            commit('set_inflow_dates_response', response);
            return response.inflows;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_CRM_00086 会員情報取得API
     *
     * API概要 - 会員IDをパラメータに顧客リンクIDを取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async member_info({ commit, state }, member_id) {
      Vue.$log.info('BFF_CRM_00086 会員情報取得API');

      return Vue.http
        .get(state.api + 'member_info', {
          params: { member_id: member_id },
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_store_customer', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * 名寄せ解除API
     *
     * API概要 - 顧客リンクIDを指定して名寄せを解除する
     *
     */
    async undo_identify_customer_link({ state }, params) {
      Vue.$log.info('名寄せ解除API');

      return Vue.http
        .patch(state.api + 'customer_links/' + params + '/undo_identify')
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '顧客リンクID: ' + params + ' の名寄せを解除しました。',
            });

            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            Vue.toasted.global.error({
              message: '名寄せ解除に失敗しました。再度お試しください。',
            });
            return Promise.reject(error);
          },
        );
    },
  },
};
