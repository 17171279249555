<template lang="pug">
v-app
  v-loading
  v-splash

  template.store(v-if='is_store_path')
    router-view
  template(v-else)
    .sp(v-if='sp_hide_header()')
      router-view
    .pc(v-else)
      header-pc
      router-view

  v-snackbar(v-model='snackbar.display', :color='snackbar.color', :timeout='0') {{ snackbar.text }}

  v-dialog(v-model='common_dialog_vo.is_open', content-class='d-pc d-xsmall')
    v-card
      .d-main.font-weight-bold
        p {{ common_dialog_vo.message }}
      .d-footer.t-equallyspace-wrap
        span.a-btn.t-middle.t-primary-g(
          @click='handlerCommonDialogClose(true)'
        ) キャンセル
        span.a-btn.t-middle.t-primary(@click='handlerCommonDialogClose(false)') OK
</template>

<script>
/**
 * import
 */
import isMobile from 'ismobilejs';
import HeaderSp from './components/organisms/HeaderSp.vue';
import HeaderPc from './components/organisms/HeaderPc.vue';
import VLoading from './components/atoms/VLoading.vue';
import VSplash from './components/atoms/VSplash.vue';
import { mapState, mapMutations } from 'vuex';
import { isProduction, isTimeless } from '@/helpers';

/**
 * Vue
 */
export default {
  /**
   * components
   */
  components: {
    HeaderSp,
    HeaderPc,
    VLoading,
    VSplash,
  },

  /**
   * data
   */
  data() {
    return {
      is_store_path: false,
      is_satellite_path: false,
      ua: '',
    };
  },

  /**
   * computed
   */
  computed: {
    /**
     * mapState
     */
    ...mapState('global', [
      'is_mobile',
      'http_loading',
      'current_user',
      'snackbar',
      'common_dialog_vo',
    ]),
  },

  /**
   * created
   */
  created: function () {
    this.ua = navigator.userAgent;
    // iPadでもviewportを制御してPCと同等に見えるようにする
    if (/iPad|Macintosh/i.test(this.ua) && 'ontouchend' in document) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute('content', 'width=1920');
    }
  },

  /**
   * beforeMount
   */
  beforeMount: function () {
    /**
     * Mobile判定してstoreへcommit
     */
    this.set_is_mobile(isMobile.phone);

    // タイムレス環境の場合、ファビコン変更
    if (isTimeless()) {
      document
        .querySelector('link[rel="icon"]')
        .setAttribute('href', '/favicon-timeless.ico');
    }
  },
  watch: {
    '$route.path': {
      handler: function () {
        const suffix = isProduction() ? '' : '| 検証環境';
        if (this.$route?.meta?.pageTitle) {
          document.title = `${this.$route.meta.pageTitle} ${suffix}`;
        } else {
          document.title = `GYRO ${suffix}`;
        }
      },
    },
  },

  /**
   * beforeUpdate
   */
  beforeUpdate: function () {
    this.set_is_store_path();
    this.set_is_satellite_path();
  },

  /**
   * methods
   */
  methods: {
    /**
     * mapMutations
     */
    ...mapMutations('global', [
      'set_is_mobile',
      'set_current_user',
      'set_common_dialog_vo',
    ]),

    set_is_store_path() {
      if (this.$route.path == '/crm/store_form') {
        this.is_store_path = true;
        this.clear_viewport();
      }
    },

    set_is_satellite_path() {
      if (~this.$route.path.indexOf('satellite')) {
        // satelliteのSPページの場合はviewportを制御しない
        this.is_satellite_path = true;
        this.clear_viewport();
      }
    },

    clear_viewport() {
      // viewportの縮小設定を解除する
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          'content',
          'width=device-width,initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
        );
    },

    sp_hide_header() {
      let flg = false;
      if (this.is_mobile) flg = true;
      if (this.is_satellite_path) flg = true;
      return flg;
    },

    handlerCommonDialogClose(cancel) {
      const vo = this.common_dialog_vo;
      vo.is_open = false;

      if (!cancel) {
        if (vo.done_callback !== null) vo.done_callback();
      } else {
        if (vo.cancel_callback !== null) vo.cancel_callback();
      }
    },
  },
};
</script>

<style lang="scss">
//RESET
@import 'assets/scss/bootstrap-reboot.scss';

//vuetify
@import '../node_modules/vuetify/dist/vuetify.css';

//外部のCSS
@import url('https://use.fontawesome.com/releases/v6.4.2/css/all.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons');

//デバッグ用
pre {
  background: #333;
  padding: 2em;
  color: #fff;
}
</style>
