// Config object to be passed to Msal on creation.
// Document:
// https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
export const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZUREAD_TENANT_ID}`, // テナントID
    clientId: process.env.VUE_APP_AZUREAD_CLIENT_ID, // クライアントID
    redirectUri: process.env.VUE_APP_AZUREAD_REDIRECT_URI, // リダイレクトURI
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored. "sessionStorage" or "localStorage" or "memoryStorage"
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// scope: ユーザー情報の参照のみ
export const loginRequest = {
  scopes: ['User.Read'],
};
