import Vue from 'vue';
import _ from 'lodash';
import { getErrorMessages } from '@/helpers';

/**
 * Reservationに関連するStore
 */
export const reservation = {
  namespaced: true,
  state: {
    /**
     * APIのURLとして利用
     */
    api: 'reservation/v1/',

    /**
     * Balloon制御
     */
    balloon: '',

    /**
     * アラートの戻り値
     */
    alert_information: null,

    /**
     * コメントの戻り値
     */
    expected_working_dates_comment: false,

    /**
     * アポの詳細
     */
    detail: null,
    route_information: null,
    appraisers: null,
    working_appraisers: null,

    // 先頭のデータ(未割り当てを抜いたもの)
    working_appraisers_sub: null,

    /**
     * GoogleMapの検索結果
     */
    google_ds: null,

    /**
     * アポ関連
     */
    appointments: [],
    line_base_information: [],
    line_base_information_origin: [],
    pure_line_base_information: [],

    /**
     * 当日移動手段
     */
    transportation_form: false,

    /**
     * 査定員絞り込みフォーム情報
     */
    appraiser_filter_form: null,
    appraiser_selected: false,
    appraiser_organizations_selected: false,
    is_beginner_selected: null,

    /**
     * テリトリー絞り込み情報
     */
    territories: null,
    territory_information_selected: [],
    territory_classifications_selected: [],
    territory_areas_selected: [],

    /**
     * 当日情報絞り込み情報
     */
    todays_transportation_selected: null,

    /*
     * 都道府県絞り込み情報
     */
    appraiser_prefectures: [],
    prefectures_selected: [],
    field_address_filter: '',

    /**
     * 営業スケジュール関連
     */
    upload_classifications: [],
    upload_territories: [],
    upload_histories: {},
    upload_last: false,
    upload_result: null,

    /**
     * 査定員検索関連
     */
    sales_appraiser_search_forms: null,
    sales_appraiser_search_results: [],
    sales_appraiser_search_query: null,
    sales_appraiser_search_filters: null,
    new_sales_appraiser_search_forms: null,
    /**
     * 査定員詳細
     */
    sales_appraiser_detail: null,
    /**
     * 営業スケジュールアップロードでエラーの出し分けが困難なためここで利用
     * error_messages
     */
    error_messages: { details: [], messages: [] },

    /**
     * アポイントメント管理
     */
    appointment_filter_forms: null,
    appointments_search_results: { appointments: [] },
    appointment_search_filters: null,
    // 最後の検索queryを保持して時間を変更した時に使う
    appointment_search_query: null,
  },

  getters: {
    /**
     * APIから取得した絞り込み情報をコンボボックスで使用できる3点セットにして返す
     */
    appraiser_filter_form_filter_appraisers: (state) => {
      if (state.appraiser_filter_form) {
        return state.appraiser_filter_form.appraisers.map((element) => {
          let name =
            element.organization_name +
            ' ' +
            element.appraiser_last_name +
            element.appraiser_first_name;
          return {
            // コンボボックス用3点セット
            id: element.appraiser_id,
            name: name,
            keyword: name + element.keyword,
          };
        });
      } else {
        return null;
      }
    },
    /**
     * APIから取得した絞り込み情報をコンボボックスで使用できる3点セットにして返す
     */
    appraiser_filter_form_filter_organizations: (state) => {
      if (state.appraiser_filter_form) {
        return state.appraiser_filter_form.organizations.map((element) => {
          return {
            // コンボボックス用3点セット
            id: element.organization_id,
            name: element.organization_name,
            keyword: element.keyword,
          };
        });
      } else {
        return null;
      }
    },
  },

  mutations: {
    set_balloon_open(state, payload) {
      state.balloon = payload;
    },
    set_balloon_close(state) {
      state.balloon = false;
    },
    set_alert_information(state, payload) {
      state.alert_information = payload;
    },
    set_expected_working_dates_comment(state, payload) {
      state.expected_working_dates_comment = payload;
    },
    /**
     * アポをset
     * @param state
     */
    set_appointments(state, payload) {
      // 稼働時間予定の場合はpre_をつける
      state.appointments = payload.map((element) => {
        // アポ
        if (element.details) {
          // 1件目のアポが同行アポの場合
          if (element.details[0].is_accompany_appointment) {
            // 同行アポ
            element.ture_id = element.id; // 改変前の元のIDも保持しておく
            element.id =
              element.ture_id + 'accompany_' + element.expected_working_date_id;

            // 詳細の方のIDも変更
            _.mapValues(element.details, (o) => {
              if (o.is_accompany_appointment) {
                // @@@は同行アポを表す特殊な文字列としていれる
                o.appointment_ture_id = o.appointment_id;
                o.appointment_id =
                  element.expected_working_date_id + '@@@' + o.appointment_id;

                return o;
              } else {
                return o;
              }
            });

            return element;
          } else {
            return element;
          }
        } else {
          // 非稼働予定時間
          element.id = 'pre_' + element.id;
          return element;
        }
      });
    },

    set_detail(state, payload) {
      state.detail = payload;
    },

    set_route_information(state, payload) {
      state.route_information = payload;
    },

    set_line_base_information(state, payload) {
      state.line_base_information = payload;
      // line_base_informationはmapで処理するためOriginalを残しておく
      state.line_base_information_origin = payload;
    },

    set_google_ds(state, payload) {
      state.google_ds = payload;
    },

    set_transportation_form(state, payload) {
      state.transportation_form = payload;
    },

    set_territories(state, payload) {
      state.territories = payload;
    },

    set_territory_classifications_selected(state, payload) {
      state.territory_classifications_selected = payload;
    },

    set_territory_information_selected(state, payload) {
      state.territory_information_selected = payload;
    },

    set_territory_areas_selected(state, payload) {
      state.territory_areas_selected = payload;
    },

    set_appraiser_prefectures(state, payload) {
      state.appraiser_prefectures = payload;
    },

    set_prefectures_selected(state, payload) {
      state.prefectures_selected = payload;
    },

    set_field_address_filter(state, payload) {
      state.field_address_filter = payload;
    },

    set_appraiser_selected(state, payload) {
      state.appraiser_selected = payload;
    },

    set_is_beginner_selected(state, payload) {
      state.is_beginner_selected = payload;
    },

    set_appraiser_organizations_selected(state, payload) {
      state.appraiser_organizations_selected = payload;
    },

    set_pure_line_base_information(state, payload) {
      state.pure_line_base_information = payload;
    },

    set_todays_transportation_selected(state, payload) {
      state.todays_transportation_selected = payload;
    },

    set_line_base_information_refine(state) {
      let new_information = state.line_base_information_origin;

      // territory_classification_selectedを1つでも選択している場合
      if (state.territory_classifications_selected.length) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.territory_classifications_selected.includes(
              element.territory_information.master_territory_classification.id,
            )
          ) {
            return element;
          }
        });
      }

      // territory_info_selectedを1つでも選択している場合
      if (state.territory_information_selected.length) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.territory_information_selected.includes(
              element.territory_information.territory_id,
            )
          ) {
            return element;
          }
        });
      }

      // territory_info_selectedを選択している場合
      if (state.appraiser_selected) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.appraiser_selected ==
            element.appraiser_information.appraiser_id
          ) {
            return element;
          }
        });
      }

      // appraiser_organizations_selectedを選択している場合
      if (state.appraiser_organizations_selected) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.territory_information_selected.includes(
              element.territory_information.territory_id,
            )
          ) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.appraiser_organizations_selected ==
            element.appraiser_information.organization_id
          ) {
            return element;
          }
        });
      }

      // is_beginnerを選択している場合
      if (state.is_beginner_selected !== null) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }

          // 選択したIDが存在するものをmap
          if (
            state.is_beginner_selected ==
            element.appraiser_information.is_beginner
          ) {
            return element;
          }
        });
      }

      // todays_transportationを選択している場合
      if (state.todays_transportation_selected) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) {
            return element;
          }
          if (
            state.todays_transportation_selected ===
            element.todays_information.master_transportation.id
          ) {
            return element;
          }
        });
      }

      // テリトリーの地方で絞り込みを行う場合
      if (state.territory_areas_selected.length !== 0) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) return element;

          const matched = state.territory_areas_selected.some(
            (selected_area) =>
              element.territory_information.area === selected_area,
          );

          if (matched) return element;
        });
      }

      // 都道府県の絞り込む（prefectures_selectedを1つでも選択している場合）
      if (state.prefectures_selected.length) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) return element;

          // 1本の稼働日予定ライン上にあるすべての査定先住所を配列に詰める
          const field_addresses = state.appraiser_prefectures.filter(
            (item) => item.expected_working_date_id == element.key,
          );
          // 1本の稼働日予定ライン上にあるすべての査定先住所のうち、1件でも都道府県条件と一致すれば、表示対象のラインとする
          const matched = state.prefectures_selected.some((selected) => {
            return field_addresses.some(
              // 都道府県名は前方一致検索
              (address) => address.field_address[0].indexOf(selected) === 0,
            );
          });

          if (matched) return element;
        });
      }

      // 住所フリー入力で絞り込む（field_address_filterを入力している場合）
      if (state.field_address_filter.length) {
        new_information = new_information.filter((element) => {
          // 査定員未割り当てははそのまま返して終わる
          if (!element.appraiser_information) return element;

          // 1本の稼働日予定ライン上にあるすべての査定先住所を配列に詰める
          const field_addresses = state.appraiser_prefectures.filter(
            (item) => item.expected_working_date_id == element.key,
          );
          // 1本の稼働日予定ライン上にあるすべての査定先住所のうち、1件でも自由入力条件と一致すれば、表示対象のラインとする
          const matched = field_addresses.some(
            // 自由入力は部分一致検索
            (address) =>
              address.field_address[0].indexOf(state.field_address_filter) !=
              -1,
          );

          if (matched) return element;
        });
      }

      state.line_base_information = new_information;
    },

    set_appraiser_filter_form(state, payload) {
      state.appraiser_filter_form = payload;
    },

    set_upload_classifications(state, payload) {
      state.upload_classifications = payload;
    },

    set_upload_territories(state, payload) {
      state.upload_territories = payload;
    },

    set_upload_histories(state, payload) {
      state.upload_histories = payload;
    },

    set_upload_last(state, payload) {
      // 1件でもあった場合
      if (
        payload.registered_schedule_histories &&
        payload.registered_schedule_histories.length
      ) {
        state.upload_last = payload.registered_schedule_histories[0];
      } else {
        state.upload_last = false;
      }
    },

    set_upload_result(state, payload) {
      state.upload_result = payload;
    },

    set_error_messages(state, payload) {
      state.error_messages = payload;
    },

    // アポスケのデータをクリア
    set_clear_schedule(state) {
      state.line_base_information = [];
      state.line_base_information_origin = [];
      state.appointments = [];
    },

    set_appraisers(state, payload) {
      state.appraisers = payload;
    },

    set_working_appraisers(state, payload) {
      state.working_appraisers = payload;
      state.working_appraisers_sub = _.drop(payload);
    },

    set_appointments_search_results(state, payload) {
      state.appointments_search_results = payload;
    },

    set_appointment_filter_forms(state, payload) {
      state.appointment_filter_forms = payload;
    },

    set_appointment_search_filters(state, payload) {
      state.appointment_search_filters = payload;
    },

    set_appointment_search_query(state, payload) {
      state.appointment_search_query = payload;
    },

    set_sales_appraiser_search_forms(state, payload) {
      state.sales_appraiser_search_forms = payload;
    },

    set_sales_appraiser_search_query(state, payload) {
      state.sales_appraiser_search_query = payload;
    },

    set_sales_appraiser_search_results(state, payload) {
      state.sales_appraiser_search_results = payload;
    },

    set_sales_appraiser_detail(state, payload) {
      state.sales_appraiser_detail = payload;
    },

    set_sales_appraiser_search_filters(state, payload) {
      state.sales_appraiser_search_filters = payload;
    },
    set_new_sales_appraiser_search_forms(state, payload) {
      state.new_sales_appraiser_search_forms = payload;
    },
  },
  actions: {
    /**
     * BFF_RSV_00047 査定員情報登録更新API
     * 従業員を査定員として新規登録・更新する
     */
    async post_sales_appraisers({ state }, params) {
      Vue.$log.info('BFF_RSV_00047 査定員情報登録更新API');

      return Vue.http.post(state.api + 'sales_appraisers', params).then(
        // 正常系
        (response) => {
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_RSV_00041 査定員管理検索条件登録
     * 入力内容を元に、査定員の絞り込み条件セットを新規登録する
     */
    async post_sales_appraiser_search_filters({ state }, params) {
      Vue.$log.info('BFF_RSV_00041 査定員管理検索条件登録');

      return Vue.http.post(
        state.api + 'search_filters/sales_appraisers',
        params,
      );
    },
    /**
     * BFF_RSV_00042 査定員管理検索条件一覧取得API
     * 査定員管理検索条件から該当するレコードを取得する
     *
     * ここはstateを使うことが好ましくないのでComponentから直接呼び出す
     */
    async get_sales_appraiser_search_filters({ commit, state }) {
      Vue.$log.info('BFF_RSV_00042 査定員管理検索条件一覧取得');

      return Vue.http.get(state.api + 'search_filters/sales_appraisers').then(
        // 正常系
        (response) => {
          commit('set_sales_appraiser_search_filters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_RSV_00043 査定員検索条件削除
     * アポイント管理の保存した検索条件を削除する
     */
    async delete_sales_appraiser_search_filters({ state }, params) {
      Vue.$log.info('BFF_RSV_00043 査定員検索条件削除');

      return Vue.http.delete(state.api + 'search_filters/' + params).then(
        // 正常系
        (response) => {
          Vue.$log.debug(response);
          Vue.toasted.global.success({
            message: response.message,
          });
          return;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: error.message,
          });
        },
      );
    },
    /**
     * BFF_RSV_00039 査定員検索API
     * 検索条件に従い査定員の検索を行い、検索結果を返却する
     */
    async get_sales_appraiser_search({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00039 査定員検索API');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // 他で使うため、最終検索paramsを保持する
      commit('set_sales_appraiser_search_query', params);

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'sales_appraisers/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_sales_appraiser_search_results', response);
            Vue.$log.debug(state.sales_appraiser_search_results);
            // リミットオーバー
            // commit('set_search_result', response.is_limit_over);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_RSV_00038 査定員検索フォーム取得API
     * 査定員管理画面の検索フォームを取得する
     *
     */
    async get_sales_appraiser_search_forms({ commit, state }) {
      if (state.sales_appraiser_search_forms) return;
      Vue.$log.info('BFF_RSV_00038 査定員検索フォーム取得API');

      return Vue.http.get(state.api + 'sales_appraiser_search_forms').then(
        (response) => {
          commit('set_sales_appraiser_search_forms', response);
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_RSV_00044 査定員詳細情報取得API
     * 該当の査定員情報を取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_sales_appraiser_details({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00044 査定員詳細情報取得API');

      return Vue.http.get(state.api + 'sales_appraisers/' + params).then(
        (response) => {
          commit('set_sales_appraiser_detail', response);
          Vue.$log.debug(state.sales_appraiser_detail);
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00045 査定員情報入力フォーム情報取得API
     * 査定員新規登録のフォーム情報を取得する
     *
     */
    async get_new_sales_appraiser_search_forms({ commit, state }) {
      if (state.new_sales_appraiser_search_forms) return;
      Vue.$log.info('BFF_RSV_00045 査定員情報入力フォーム情報取得API');

      return Vue.http.get(state.api + 'sales_appraisers/new').then(
        (response) => {
          commit('set_new_sales_appraiser_search_forms', response);
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_RSV_00001 アポイント検索フォーム情報取得
     * アポイント検索をするためのフォーム情報を取得する
     *
     */
    async get_appointment_filter_forms({ commit, state }) {
      if (state.appointment_filter_forms) return;
      Vue.$log.info('BFF_RSV_00001 アポイント検索フォーム情報取得');

      return Vue.http.get(state.api + 'appointment_filter_forms').then(
        (response) => {
          commit('set_appointment_filter_forms', response);
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00005 アポイント管理保存検索条件一覧取得
     * アポイント管理検索条件から該当するレコードを取得する
     * ここはstateを使うことが好ましくないのでComponentから直接呼び出す
     */
    async get_appointment_search_filters({ commit, state }) {
      Vue.$log.info('BFF_RSV_00005 アポイント管理保存検索条件一覧取得');

      return Vue.http.get(state.api + 'appointment_search_filters').then(
        // 正常系
        (response) => {
          commit('set_appointment_search_filters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00004 アポイント管理検索条件登録
     * 入力内容を元に、アポイントの絞り込み条件セットを新規登録する
     */
    async post_appointment_search_filters({ state }, params) {
      Vue.$log.info('BFF_RSV_00004 アポイント管理検索条件登録');

      return Vue.http.post(state.api + 'appointment_search_filters', params);
    },

    /**
     * BFF_RSV_00066 アポイント対応時間変更
     * アポイント到着予定日時,アポイント終了予定日時を変更する
     */
    async put_appointments_scheduled_times({ state }, params) {
      Vue.$log.info('BFF_RSV_00066 アポイント対応時間変更');

      return Vue.http
        .put(
          state.api + 'appointments/' + params.id + '/scheduled_times',
          params.body,
        )
        .then(
          // 正常系
          (response) => {
            Vue.$log.debug(response);
            Vue.toasted.global.success({
              message: '処理が正常に終了しました',
            });
          },
          // 異常系
          (error) => {
            for (let message of getErrorMessages(error, 'appointment_id')) {
              Vue.toasted.global.error({ message });
            }

            return Promise.reject(error);
          },
        );
    },

    /**
     * BFF_RSV_00003 アポイントメント管理検索条件保存フォーム情報取得
     * アポイント検索条件を保存するフォームの初期表示に必要な情報を取得する
     */
    async get_appointment_search_filters_new({ state }) {
      Vue.$log.info(
        'BFF_RSV_00003 アポイントメント管理検索条件保存フォーム情報取得',
      );

      return Vue.http
        .get(state.api + 'appointment_search_filters/new', {
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            return response;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: error.message,
            });
          },
        );
    },

    /**
     * BFF_RSV_00006 アポイント管理保存検索条件削除
     * アポイント管理の保存した検索条件を削除する
     */
    async delete_appointment_search_filters({ state }, params) {
      Vue.$log.info('BFF_RSV_00006 アポイント管理保存検索条件削除');

      return Vue.http
        .delete(state.api + 'appointment_search_filters/' + params)
        .then(
          // 正常系
          (response) => {
            Vue.$log.debug(response);
            Vue.toasted.global.success({
              message: response.message,
            });
            return;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: error.message,
            });
          },
        );
    },

    /**
     * BFF_RSV_00021 アポイントアラート許容情報更新
     * アポイントメント情報を更新する
     */
    async post_appointments_alert({ state }, params) {
      Vue.$log.info('BFF_RSV_00021 アポイントアラート許容情報更新');

      return Vue.http
        .post(state.api + 'appointments/' + params.id + '/alert', {
          is_alert_acceptable: params.is_alert_acceptable,
        })
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
            throw error;
          },
        );
    },
    /**
     * BFF_RSV_00076 稼働ライン更新
     * 稼働ラインの偶数、奇数更新
     */
    async patch_expected_working_dates_line({ state }, params) {
      Vue.$log.info('BFF_RSV_00077 稼働ライン更新');

      return Vue.http
        .patch(state.api + 'expected_working_dates/' + params.id + '/line', {
          is_even_line: params.is_even_line,
          is_permitted_via_web_reservation:
            params.is_permitted_via_web_reservation,
        })
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
            throw error;
          },
        );
    },
    /**
     * BFF_RSV_00077 アポイント固定フラグ更新
     * アポイントメント固定状態を更新
     */
    async patch_appointments_fix({ state }, params) {
      Vue.$log.info('BFF_RSV_00077 アポイント固定フラグ更新');

      return Vue.http
        .patch(state.api + 'appointments/' + params.id + '/fix', {
          is_fixed: params.is_fixed,
          expected_working_date_id: params.expected_working_date_id,
        })
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
            throw error;
          },
        );
    },
    /**
     * BFF_RSV_00009 アポイント同行査定員情報更新API
     * アポイントに対応する同行査定員情報を更新する
     */
    async post_appointments_accompany_appraisers({ state }, params) {
      Vue.$log.info('BFF_RSV_00009 アポイント同行査定員情報更新API');

      return Vue.http
        .post(
          state.api + 'appointments/' + params.id + '/accompany_appraisers',
          params.body,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
          },
        );
    },

    /**
     * BFF_RSV_00068 アポイント担当査定員情報更新API
     * アポイントに紐づく(主)担当査定員を更新
     */
    async patch_appointments_main_appraisers({ state }, params) {
      Vue.$log.info('BFF_RSV_00068 アポイント担当査定員情報更新API');

      // ここのパラメーターは現在取得しているdetailからしか生成できないはず

      return Vue.http.patch(
        state.api + 'appointments/' + params.id + '/main_appraisers',
        params.body,
      );
    },

    /**
     * BFF_RSV_00007 アポイント詳細情報取得
     * 該当のアポイント情報を取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_details({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00007 アポイント詳細情報取得');

      return Vue.http
        .get(state.api + 'appointments/' + params + '/details')
        .then(
          (response) => {
            commit('set_detail', response);
          },
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00002 アポイント検索
     * 検索条件に従いアポイントの検索を行い、検索結果を返す
     */
    async get_appointments_search({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00002 アポイント検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // 他で使うため、最終検索paramsを保持する
      commit('set_appointment_search_query', params);

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'appointments/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_appointments_search_results', response);

            // リミットオーバー
            // commit('set_search_result', response.is_limit_over);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_RSV_00008 査定員一覧取得
     * 査定員一覧取得
     */
    async get_appraisers({ commit, state }) {
      Vue.$log.info('BFF_RSV_00008 査定員一覧取得');

      return Vue.http.get(state.api + 'appraisers').then(
        // 正常系
        (response) => {
          // ライン情報を先にセット
          commit('set_appraisers', response.appraisers);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00073 日別稼働査定員一覧取得
     * 査定員一覧取得
     */
    async get_working_appraisers({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00073 日別稼働査定員一覧取得');

      return Vue.http
        .get(state.api + 'working_appraisers', {
          params: {
            date: params,
          },
        })
        .then(
          // 正常系
          (response) => {
            // ライン情報を先にセット
            commit('set_working_appraisers', response.working_appraisers);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00032 当日移動手段更新処理
     * 経路情報の移動手段を更新した際の処理
     */
    async patch_expected_working_dates_transportation(
      { commit, state },
      params,
    ) {
      Vue.$log.info('BFF_RSV_00032 当日移動手段更新処理');

      return Vue.http
        .patch(
          state.api +
            'expected_working_dates/' +
            params.expected_working_date_id +
            '/transportation',
          {
            transportation: params.id,
          },
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
            commit('set_balloon_close');
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * 非稼働予定一括登録API
     * 非稼働予定時間の登録・更新処理を行う
     */
    async bulk_create({ commit, state }, params) {
      Vue.$log.info('非稼働予定一括登録API');
      return Vue.http
        .post(state.api + 'pre_reserved_times/bulk_create', params)
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
            commit('set_balloon_close');
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
          },
        );
    },
    /**
     * BFF_RSV_00033 非稼働予定登録・更新API
     * 非稼働予定時間の登録・更新処理を行う
     */
    async post_pre_reserved_times({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00033 非稼働予定登録・更新API');

      return Vue.http
        .post(
          state.api +
            'expected_working_dates/' +
            params.expected_working_date_id +
            '/pre_reserved_times',
          params.body,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });

            // 成功の場合、schedulerから削除する
            window.scheduler.deleteEvent(params.delete_id);

            commit('set_balloon_close');
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
          },
        );
    },

    /**
     * BFF_RSV_00037 営業スケジュール取り込み処理
     * 営業スケジュールCSVを取り込んだ際の処理
     */
    async post_import_csv({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00037 営業スケジュール取り込み処理');

      let data = new FormData();
      data.append('file', params.file);
      data.append('target_date', params.target_date);
      data.append(
        'territory_classification_id',
        params.territory_classification_id,
      );
      data.append('territory_id', params.territory_id);

      return Vue.http
        .post(state.api + 'expected_working_dates/import_csv', data)
        .then(
          // 正常系
          (response) => {
            commit('set_upload_result', response.message);

            Vue.toasted.global.success({
              message: '正常にアップロード処理が完了しました',
            });
          },
          // 異常系
          (error) => {
            Vue.$log.debug(error);
            Vue.toasted.global.error({
              message: 'CSVアップロードが失敗しました',
            });
          },
        );
    },
    /**
     * BFF_RSV_00011 アポイントライン情報取得API(店舗案件で使用)
     * クエリで指定された査定員、テリトリーで絞り込んだライン情報を返す
     * ルート編成画面で呼ばれる形だと、未分類用の要素が末尾に追加されるため別で定義
     */
    async get_pure_line_base_information({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00011 アポイントライン情報取得API');

      return Vue.http.get(state.api + 'line_base_information', { params }).then(
        (response) => {
          commit(
            'set_pure_line_base_information',
            response.line_base_information,
          );
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00011 アポイントライン情報取得API
     * クエリで指定された査定員、テリトリーで絞り込んだライン情報を返す
     * ルート編成画面初期表示時に呼ばれる
     */
    async get_line_base_information({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00011 アポイントライン情報取得API');

      return Vue.http.get(state.api + 'line_base_information', { params }).then(
        (response) => {
          // 未分類用の要素を末尾に追加
          let date = params.date.replace(/-/g, '') + '_9999_0_00000000';
          response.line_base_information.push({ key: date });

          // ライン情報を先にセット
          commit('set_line_base_information', response.line_base_information);

          // 絞り込みを継続する
          commit('set_line_base_information_refine');
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00014 アポイントD&D更新API
     * 営業スケジュール画面でD&Dで移動したアポイント情報を更新する
     */
    async patch_appointments_rearrange({ state }, params) {
      Vue.$log.info('BFF_RSV_00014 アポイントD&D更新API');

      return Vue.http.patch(state.api + 'appointments/rearrange', params).then(
        (response) => {
          Vue.toasted.global.success({
            message: response.message,
          });
        },
        (error) => {
          for (let message of getErrorMessages(error, 'appointment_id')) {
            Vue.toasted.global.error({ message });
          }
        },
      );
    },

    /**
     * BFF_RSV_00030 移動手段更新フォーム情報取得
     * 移動手段更新フォーム情報の取得
     */
    async get_transportation_form({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00030 移動手段更新フォーム情報取得');

      commit('set_transportation_form', false);

      return Vue.http
        .get(state.api + 'expected_working_dates/' + params + '/transportation')
        .then(
          (response) => {
            commit('set_transportation_form', response.transportation);
          },
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00053 営業スケジュールフォーム情報取得API
     * 営業スケジュール取り込み画面の構築に必要な情報を取得
     */
    async get_registered_schedule_forms({ commit, state }) {
      Vue.$log.info('BFF_RSV_00053 営業スケジュールフォーム情報取得API');

      return Vue.http.get(state.api + 'expected_working_dates/import_csv').then(
        (response) => {
          commit('set_upload_classifications', response.classifications);
          commit('set_upload_territories', response.territories);
        },
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_RSV_00036 営業スケジュール取り込み日時一覧取得
     * 営業スケジュール取り込み履歴情報を一覧取得する
     */
    async get_registered_schedule_histories_search({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00036 営業スケジュール取り込み日時一覧取得');

      let body = params.body;

      return Vue.http
        .get(state.api + 'registered_schedule_histories/search', {
          params: body,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // 履歴が求められたとき
            if (params.mode == 'history') {
              commit('set_upload_histories', response);
            }

            // 最終アップロードが求められたとき
            if (params.mode == 'last') {
              // エラークリア
              commit('set_error_messages', { details: [], messages: [] });

              commit('set_upload_last', response);
            }
          },

          // 異常系
          (error) => {
            if (params.mode == 'last') {
              Vue.$log.debug(error.response.data);
              commit('set_upload_last', '');
              commit('set_error_messages', error.response.data);
            }
          },
        );
    },

    /**
     * BFF_RSV_00028 コメント情報取得
     * 該当査定員の当日コメント情報を取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_expected_working_dates_comment({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00028 コメント情報取得');

      // 処理開始時に空欄にしておく
      commit('set_expected_working_dates_comment', '');

      return Vue.http
        .get(state.api + 'expected_working_dates/' + params + '/comment')
        .then(
          // 正常系
          (response) => {
            commit('set_expected_working_dates_comment', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00029 コメント情報登録更新
     * 該当査定員の当日コメント情報、要対応フラグを更新する
     *
     * @param commit
     * @param state
     * @param params
     */
    async patch_expected_working_dates_comment({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00029 コメント情報登録更新');

      return Vue.http
        .patch(
          state.api + 'expected_working_dates/' + params.path + '/comment',
          params.params,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
            commit('set_balloon_close');
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00019 テリトリー絞り込みフォーム情報取得
     * テリトリー絞り込みフォーム情報を取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_territories({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00019 テリトリー絞り込みフォーム情報取得');

      params = { date: Vue.moment(params).format('YYYY-MM-DD') };

      return Vue.http
        .get(state.api + 'territories', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          (response) => {
            commit('set_territories', response);
          },
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00020 査定員絞り込みフォーム情報取得API
     * 組織一覧情報と現場査定員情報取得API
     *
     * @param commit
     * @param state
     */
    async get_appraisers_organizations({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00020 査定員絞り込みフォーム情報取得API');

      params = { date: Vue.moment(params).format('YYYY-MM-DD') };

      let toast = Vue.toasted.global.load({
        message: '査定員絞り込みフォーム情報取得中',
      });

      return Vue.http
        .get(state.api + 'appraisers_organizations', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_appraiser_filter_form', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_RSV_00026 調整確認アラート情報取得API
     * API概要 日付を必須パラメータとして、 該当日付のアラート数、該当日付のアラート情報、浮き玉アポ件数、直近未来１週間のアラート件数を取得する
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_alert_information({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00026 調整確認アラート情報取得API');

      // 日付のみが必要
      params = { date: Vue.moment(params).format('YYYY-MM-DD') };

      return Vue.http
        .get(state.api + 'alert_information', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_alert_information', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00074 経路情報取得API
     * アポイントメントの経路情報表示に必要な情報を取得
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_route_information({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00074 経路情報取得API');

      commit('set_route_information', null);

      return Vue.http
        .get(state.api + 'appointments/' + params.id + '/route_information', {
          params: { expected_working_date_id: params.expected_working_date_id },
        })
        .then(
          // 正常系
          (response) => {
            commit('set_route_information', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00035 非稼動スケジュール削除
     * 指定された日時の時間予定の情報を削除する
     *
     * @param commit
     * @param state
     * @param params
     */
    async delete_pre_reserved_times({ dispatch, commit, state }, params) {
      Vue.$log.info('BFF_RSV_00035 非稼動スケジュール削除');

      return Vue.http
        .delete(
          state.api +
            'expected_working_dates/' +
            params.expected_working_date_id +
            '/pre_reserved_times/' +
            params.id.replace('pre_', ''),
        )
        .then(
          (response) => {
            Vue.toasted.global.success({
              message: response.message,
            });
            commit('set_balloon_close');

            // スケジュールを呼び直す
            dispatch('post_schedules', new Date());
          },
          (error) => {
            let errorMessage = '';
            if (error.response.status === 422) {
              errorMessage =
                '店舗案件に紐づいているため非稼働予定を削除できません';
            } else {
              errorMessage = error.message;
            }

            Vue.toasted.global.error({
              message: errorMessage,
            });
            throw Error(error);
          },
        );
    },

    /**
     * BFF_RSV_00013 アポイント情報取得API
     * クエリで指定された日にち(yyyy-MM-dd)、査定員、テリトリーで絞り込んだアポイントメントを返す
     * ルート編成画面初期表示時とアポイントが更新された時に呼ばれる
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_schedules({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00013 アポイント情報取得API');

      // ここでは絞り込んだものを要求できるが、絞り込みをFE側で実装しているため日付だけを渡してすべてのデータをもらう
      params = {
        date: Vue.moment(params.date).format('YYYY-MM-DD'),
        uses_cache: params.uses_cache,
      };

      return Vue.http
        .get(state.api + 'schedules', {
          params,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_appointments', response.appointments);
            const new_appraiser_prefectures = [];
            const inDetailsAppointments = response.appointments.filter(
              (appointment) => 'details' in appointment,
            );
            inDetailsAppointments.forEach((appointment) => {
              const new_field_address = [];
              appointment.details.forEach((detail) => {
                new_field_address.push(detail.field_address);
              });
              new_appraiser_prefectures.push({
                expected_working_date_id: appointment.expected_working_date_id,
                field_address: new_field_address,
              });
            });
            commit('set_appraiser_prefectures', new_appraiser_prefectures);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00076 営業準備金口座情報クリア
     *
     * @param commit
     * @param state
     * @param params
     */
    async clear_bank_account({ state }, params) {
      Vue.$log.info('BFF_RSV_00076 営業準備金口座情報クリア');
      var api_url = state.api;
      // TODO: appraiser_idは不要なので、BFF、Swaggerから削除後に消す
      // TODO: swagger定義はRSVだが、BFF実装がfundのRoutingで作られてしまっているのでBFF側要修正
      return Vue.http
        .delete(
          api_url.replace('reservation', 'fund') +
            'sales_reserve_account/' +
            params.id +
            '?appraiser_id=' +
            params.appraiser_id,
        )
        .then(
          // 正常系
          (response) => {
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_RSV_00056 テリトリーマスタファイルダウンロードAPI
     * @param state
     * @returns {Promise<AxiosResponse<T>>}
     */
    async get_territories_csv({ state }) {
      Vue.$log.info('BFF_RSV_00056 テリトリーマスタファイルダウンロードAPI');
      return Vue.http.get(state.api + 'territories/csv');
    },

    /**
     * BFF_RSV_00055 CSVファイルアップロード
     * @param state
     * @param file
     * @returns {Promise<AxiosResponse<T>>}
     */
    async post_territories_csv({ state }, file) {
      Vue.$log.info('BFF_RSV_00037 営業スケジュール取り込み処理');

      let data = new FormData();
      data.append('file', file);

      return Vue.http.post(state.api + 'territories/csv', data);
    },
    /**
     * BFF_RSV_00079 営業目標アップロードAPI
     */
    async post_budget_csv({ commit, state }, params) {
      Vue.$log.info('BFF_RSV_00079 営業目標アップロードAPI');

      let data = new FormData();
      data.append('file', params.file);
      data.append('target_date', params.target_date);
      data.append('territory_id', params.territory_id);

      return Vue.http.post(state.api + 'budgets/csv', data).then(
        // 正常系
        (response) => {
          commit('set_upload_result', response.message);

          Vue.toasted.global.success({
            message: '正常にアップロード処理が完了しました',
          });
        },
        // 異常系
        (error) => {
          commit('set_upload_result', error.message);
          Vue.$log.debug(error);
          Vue.toasted.global.error({
            message: 'CSVアップロードが失敗しました',
          });
        },
      );
    },
  },
};
