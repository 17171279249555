import Vue from 'vue';
// import _ from 'lodash';

/**
 * ステートの初期値
 * ステートを初期化する際、ミューテーションから呼び出して用いる
 */
function initialState() {
  return {
    /**
     * APIのURLとして利用
     */
    api: 'assessment/v1/',
    appraisal_api: 'appraisal/v1/',

    /**
     * enum
     */
    // 日付
    enum_master_assessment_date_classification: {
      NOTSPECIFIED: 1, // 指定しない
      TODAY: 2, // 本日の日付
      SPECIFIEDDATE: 3, // 日付で期間を指定
    },
    // 性別
    gender_name: ['未定義', '男性', '女性', '無回答', '不明'],

    // 案件対応状況
    enum_master_event_status: {
      CONFIRMED: 2,
      CANCELED: 4,
    },

    // 案件種別
    enum_master_event_type: {
      VISIT: 1, // 訪問案件
      BRING: 2, // 持ち込み案件
      DELIVERY: 3, // 宅配案件
      CORPORATION: 4, // 法人案件
      SIMPLE: 5, // 簡易案件
    },

    // 売買契約 or 預かり証契約
    enum_contract_classification: {
      SALES_CONTRACT: {
        id: 1,
        text: '売買契約',
      },
      KEEP_THE_ITEM_CONTRACT: {
        id: 2,
        text: '預かり証契約',
      },
    },

    // 査定商材状況
    enum_assessment_status: {
      UN_ASSESSED: 0,
      ASSESSING: 1,
      LACK_OF_ASSESSMENT_INFORMATION: 2,
      REQUIRED_KEEP_ASSESSMENT: 3,
      BUYABLE: 4,
      NON_BUYABLE: 5,
    },

    /**
     * サンプルなので消してください
     *
     * stateの初期化は場所によって様々です。
     * ほとんどの場合はnullで良いと思いますが、boolや空配列もありえるでしょう。
     * 例えば検索結果などは[]で初期化しています。
     */
    communication_customers_search: null,
    ass_masters: {
      master_assessment_item_types: [],
      master_assessment_statuses: [],
      master_assessment_types: [],
      master_check_lists: [],
      master_contract_statuses: [],
      master_contract_types: [],
      master_delivery_desired_time_zones: [],
      master_event_statuses: [],
      master_event_types: [],
      master_kit_sizes: [],
      master_jobs: [],
      master_payment_classifications: [],
      master_pickup_statuses: [],
      master_purchase_permission_statuses: [],
      master_registered_mail_statuses: [],
      master_returned_statuses: [],
      master_sales_contract_checklists: [],
      master_shipping_baggage_types: [],
      master_shipping_statuses: [],
      master_warehouse_assessment_contract_checklists: [],
      master_easy_input_of_estimated_sales: [],
      master_confirmation_document_questions: [],
      master_confirmation_document_answers: [],
      master_closing_methods: [],
      master_closing_statuses: [],
      master_document_deficiency_statuses: [],
      master_event_returned_statuses: [],
    },
    assessment_search_event_results: {},
    assessment_search_contract_results: {
      sales_contracts: [],
      keep_the_item_contracts: [],
    },

    /**
     * BFF_ASS_00108 案件情報取得 レスポンス
     * FIXME: プロパティ毎の初期値を設定
     */
    event_by_event_id_response: {
      appointment: {
        appointment_status: {},
        related_appointments: [],
        corresponding_appraiser: [],
      },
      assessment_offer_for_event: {
        visit_assessment: {
          identification: {},
        },
        bring_assessment: {
          identification: {},
        },
      },
      event: {
        id: '',
        lock_version: 0,
        agent: {},
        event_delivery: {
          kits: [],
          check_lists: [],
        },
      },
    },

    // BFF_ASS_00110 案件関連契約情報取得 レスポンス
    assessment_event_contract: {
      keep_the_item_contracts: [],
      sales_contracts: [],
    },

    // BFF_ASS_00143 時間指定未対応地域判別 レスポンス
    unsupported_area: {
      is_non_target_pickup: false,
      is_non_time_specification: false,
    },

    /**
     * BFF_ASS_00113 査定商材登録フォーム商材項目取得API レスポンス
     */
    product_specific_forms: null,
    /**
     * BFF_ASS_00225 査定商材登録フォーム共通項目取得API レスポンス
     */
    products_mutual_forms: null,
    /**
     * BFF_ASS_00126 単価マスタ取得 レスポンス
     */
    unit_price_master: null,

    sales_contracts: null,

    keep_the_item_contracts: null,

    events_products: {},

    // 生成したBLOBのURL
    files: {},

    // BFF_ASS_00200 専門査定明細情報検索APIのレスポンス
    // PC、SPともに使う
    assessment_search_product: null,

    // 査定の検索だけ特殊な要件があり
    assessment_search_product_params: null,

    // 査定検索の再検索フラグ
    is_again_assessment_search: false,

    // 主にSPの閲覧モードで使用する
    sp_products: null,
    // 主にPCで使用する
    pc_products: null,
    // 主にSPの閲覧モードで使用する
    sp_item: null,
    // 主にPCで使用する
    pc_item: null,

    // 販売予定価格の推定額
    predicted_selling_price: 0,

    /**
     * trueで査定モード・falseは閲覧モード
     * 受け取るデータはsp_productsで同一だが入ってくるAPIが異なるので
     * mutationsにて調整する
     */
    sp_products_edit: false,

    // 過去査定履歴
    past_assessments: [],

    // PCの査定管理の一覧で選択したID
    // ここはこのような実装にしないととても難解になる
    assessment_selected_id: '',

    // 保存案件情報
    event_search_filters: null,

    // 査定商材検索条件
    product_search_filters: null,

    // BFF_ASS_121 アンケート情報取得API(新規作成)
    questionnaire_by_event_id_response: {},

    // 査定商材登録進捗API
    item_import_progress_error_message: {},

    // （データメンテナンス）案件完了日
    event_dates_response: { events: [] },
  };
}

/**
 * Assessmentに関連するStore
 */
export const assessment = {
  namespaced: true,
  state: initialState(),
  getters: {
    /**
     * table_name と id を引数として与え、該当するマスターデータの object を返す
     */
    get_ass_master_object: (state) => (table_name, id) => {
      const { ass_masters = {} } = state;
      if (ass_masters[table_name]) {
        return state.ass_masters[table_name].find((obj) => obj.id == id) || {};
      } else {
        return {};
      }
    },

    /**
     * table_name と id を引数として与え、該当するマスターデータの name を返す
     */
    get_ass_master_name: (_, getters) => (table_name, id) => {
      const { get_ass_master_object } = getters;
      const { name = '' } = get_ass_master_object(table_name, id);
      return name;
    },

    /**
     * table_name と id を引数として与え、該当するマスターデータの object を返す
     */

    get_products_mutual_forms_object: (state) => (table_name, id) => {
      const { products_mutual_forms = {} } = state;
      if (products_mutual_forms[table_name]) {
        return (
          products_mutual_forms[table_name].selections.find(
            (obj) => obj.id == id,
          ) || {}
        );
      } else {
        return {};
      }
    },

    get_products_mutual_forms_name: (_, getters) => (table_name, id) => {
      const { get_products_mutual_forms_object } = getters;
      const { name = '' } = get_products_mutual_forms_object(table_name, id);
      return name;
    },

    /**
     *  gender_id を引数として与え、該当するenum_genderの name を返す
     */
    get_gender_name: (state) => (id) => {
      if (state.enum_gender) {
        const result = state.enum_gender.find((obj) => obj.id == id) || {};
        return result.name;
      } else {
        return {};
      }
    },
    /**
     * 契約検索結果を表示する形に整形
     */
    shaping_contract_search_results: (state) => {
      let new_sales_contracts =
        state.assessment_search_contract_results.sales_contracts;

      for (let i = 0; i < new_sales_contracts.length; i++) {
        new_sales_contracts[i].master_document_classfications_id = 2;
        new_sales_contracts[i].master_document_classfications_name =
          '売買契約書';
      }
      let new_keep_the_item_contracts =
        state.assessment_search_contract_results.keep_the_item_contracts;

      for (let n = 0; n < new_keep_the_item_contracts.length; n++) {
        new_keep_the_item_contracts[n].master_document_classfications_id = 3;
        new_keep_the_item_contracts[n].master_document_classfications_name =
          '預かり契約書';
      }

      const join_array = new_sales_contracts.concat(
        new_keep_the_item_contracts,
      );
      if (join_array) {
        return join_array.map((data) => {
          const customer_name =
            data.last_name +
            ' ' +
            data.first_name +
            '(' +
            data.kana_last_name +
            ' ' +
            data.kana_first_name +
            ')';
          const contract_creation_employee_name =
            data.contract_creation_employee_name;
          const {
            id,
            event_id,
            contract_number,
            master_document_classfications_id,
            master_document_classfications_name,
            master_contract_type_id,
            contract_creation_date,
            contract_date,
            master_contract_status_id,
            gender,
            prefecture,
            birthday,
          } = data;
          return {
            // 契約ID
            id,
            event_id,
            // 契約No
            contract_number,
            // 契約種別区分ID
            master_document_classfications_id,
            // 契約種別区分(売買契約書or預かり契約書)
            master_document_classfications_name,
            // 契約種別ID
            master_contract_type_id,
            // 作成日時
            contract_creation_date,
            // 契約締結日
            contract_date,
            // 契約状況ID
            master_contract_status_id,
            // 顧客名
            customer_name,
            // 性別
            gender,
            // 都道府県
            prefecture,
            // 生年月日
            birthday,
            // 登録従業員名
            contract_creation_employee_name,
          };
        });
      }
    },

    /**
     * events_productsから指定したObjectを返す
     * @param state
     * @returns {Function}
     */
    get_events_products_object: (state) => (id) => {
      if (id in state.events_products) {
        return state.events_products[id];
      } else {
        return null;
      }
    },

    /**
     * 査定商材IDの配列から指定した商材の合計販売予想価格を返す
     * @param state
     * @returns {Function}
     */
    get_total_expected_sale_price_by_events_assessment_item_ids:
      (_, getters) => (ids) => {
        const { get_events_products_object } = getters;
        let sum = 0;
        for (let i = 0, len = ids.length; i < len; i++) {
          const product = get_events_products_object(ids[i]);
          sum += product.expected_sale_price;
        }
        return sum;
      },

    /**
     * 「マスタの査定商材種別ID」を引数とし、該当の「査定商材登録フォーム」を返す
     * @param {number} masterAssessmentItemTypeId マスタの査定商材種別ID
     * @returns {AssessmentProductSpecificForm | undefined | null} 査定商材登録フォーム
     */
    getAssessmentProductSpecificForm: (state) => (assessmentItemTypeId) => {
      if (Array.isArray(state.product_specific_forms)) {
        return state.product_specific_forms.find(
          (product_specific_form = {}) =>
            product_specific_form.assessment_item_type_id ==
            assessmentItemTypeId,
        );
      } else {
        return null;
      }
    },

    /**
     * 「マスタの査定商材種別ID」を引数とし、該当の「査定商材項目一覧」を返す
     * @param {number} masterAssessmentItemTypeId マスタの査定商材種別ID
     * @returns {Array<AssessmentItemElement> | undefined} 査定商材項目一覧
     */
    getAssessmentItemElements: (state, getters) => (assessmentItemTypeId) => {
      const assessmentProductSpecificForm =
        getters.getAssessmentProductSpecificForm(assessmentItemTypeId);
      if (assessmentProductSpecificForm) {
        return assessmentProductSpecificForm.assessment_item_elements;
      } else {
        return [];
      }
    },

    /**
     * 査定商材種別を引数とし、販促リスト価格を含む査定商材項目を返す
     * @param {number} masterAssessmentItemTypeId マスタの査定商材種別ID
     * @returns {AssessmentItemElement | undefined} 販促リスト価格を含む査定商材項目
     */
    getMasterElementIncludeSalesPromotionListPriceList:
      (state, getters) => (masterAssessmentItemTypeId) => {
        const assessmentItemElements = getters.getAssessmentItemElements(
          masterAssessmentItemTypeId,
        );
        if (Array.isArray(assessmentItemElements)) {
          return assessmentItemElements.filter(
            (x = {}) => x.unit_price_master_name !== '',
          );
        } else {
          return [];
        }
      },

    /**
     * 「単価マスタ一覧」より、「テーブル名」を用いて絞り込む
     * @param {string} tableName 単価マスタのテーブル名
     * @returns {UnitPriceMaster | undefined | null} 該当する「単価マスタ」を返す
     */
    getUnitPriceMaster: (state) => (tableName) => {
      const unitPriceMasters = state.unit_price_master?.data;
      if (Array.isArray(unitPriceMasters)) {
        return unitPriceMasters.find((x) => x?.master_table_name === tableName);
      } else {
        return null;
      }
    },

    // 案件
    // //////////////////////////////////////////////////////////////////////////////////////////////////

    // 案件結果を表示するか否か
    is_event_search_result: (state) => {
      return Object.keys(state.assessment_search_event_results).length > 0;
    },

    // 案件詳細を表示するか否か
    is_event_search_detail: (state) => {
      const event = state.event_by_event_id_response.event || {};
      return event.id !== '';
    },

    // 簡易査定案件か否か
    is_simple_event: (state) => {
      const { enum_master_event_type, event_by_event_id_response } = state;
      const event = event_by_event_id_response.event || {};
      const { master_event_type_id } = event;
      return master_event_type_id === enum_master_event_type.SIMPLE;
    },

    // 宅配査定案件か否か
    is_delivery_event: (state) => {
      const { enum_master_event_type, event_by_event_id_response } = state;
      const event = event_by_event_id_response.event || {};
      const { master_event_type_id } = event;
      return master_event_type_id === enum_master_event_type.DELIVERY;
    },

    // 訪問査定案件か否か
    is_visit_event: (state) => {
      const { enum_master_event_type, event_by_event_id_response } = state;
      const event = event_by_event_id_response.event || {};
      const { master_event_type_id } = event;
      return master_event_type_id === enum_master_event_type.VISIT;
    },

    // 持ち込み査定案件か否か
    is_bring_event: (state) => {
      const { enum_master_event_type, event_by_event_id_response } = state;
      const event = event_by_event_id_response.event || {};
      const { master_event_type_id } = event;
      return master_event_type_id === enum_master_event_type.BRING;
    },

    // 法人査定案件か否か
    is_corporation_event: (state) => {
      const { enum_master_event_type, event_by_event_id_response } = state;
      const event = event_by_event_id_response.event || {};
      const { master_event_type_id } = event;
      return master_event_type_id === enum_master_event_type.CORPORATION;
    },

    // 対応状況が「キャンセル」か否か
    // FIXME: Magic number
    is_cancel_event: (state) => {
      const event = state.event_by_event_id_response.event || {};
      const { event_status_id } = event;
      return event_status_id === 4;
    },

    // 持ち込み査定
    bring_assessment: (state) => {
      const assessment_offer_for_event =
        state.event_by_event_id_response.assessment_offer_for_event || {};
      return assessment_offer_for_event.bring_assessment || {};
    },

    // 持ち込み査定
    visit_assessment: (state) => {
      const assessment_offer_for_event =
        state.event_by_event_id_response.assessment_offer_for_event || {};
      return assessment_offer_for_event.visit_assessment || {};
    },

    // 本人確認 (訪問査定案件 or 持ち込み査定案件)
    identification: (state, getters) => {
      const {
        is_bring_event,
        is_visit_event,
        bring_assessment,
        visit_assessment,
      } = getters;
      if (is_bring_event) return bring_assessment.identification || {};
      else if (is_visit_event) return visit_assessment.identification || {};
      else return {};
    },

    // 本人確認書類 (訪問査定案件 or 持ち込み査定案件)
    master_identification_document_id: (state, getters) => {
      const { identification } = getters;
      return identification.master_identification_document_id;
    },

    // 対応注意 (訪問査定案件 or 持ち込み査定案件)
    is_sensitive_serving_required: (state, getters) => {
      const {
        is_bring_event,
        is_visit_event,
        bring_assessment,
        visit_assessment,
      } = getters;
      if (is_bring_event)
        return (bring_assessment.is_sensitive_serving_required || {}).value;
      else if (is_visit_event)
        return (visit_assessment.is_sensitive_serving_required || {}).value;
      else return false;
    },

    // 査定員への連絡特記事項(訪問査定案件 or 持ち込み査定案件)
    notification_to_appraiser: (state, getters) => {
      const {
        is_bring_event,
        is_visit_event,
        bring_assessment,
        visit_assessment,
      } = getters;
      if (is_bring_event) return bring_assessment.notification_to_appraiser;
      else if (is_visit_event)
        return visit_assessment.notification_to_appraiser;
      else return '';
    },
    item_import_progress_error_message: (state, getters) => {
      return state.item_import_progress_error_message;
    },
    get_maintenance_event_dates: (state) => {
      const events = state.events || {};
      return events;
    },
  },
  mutations: {
    /**
     * mutations
     * 単純な値の設定はstateの名前にset_と接頭語をつけて定義しています
     * 複雑な処理が伴うようなものは良いように名前をつけてください
     * 例えばAPIから受け取った値を処理してから登録や、何個かのstateに同時に登録するなどです。
     * gettersはあまり使っていませんが必要な場面があれば使ってください。
     */
    set_communication_customers_search(state, payload) {
      state.communication_customers_search = payload;
    },
    set_all_masters(state, payload) {
      state.ass_masters = payload;
    },
    set_assessment_search_event_results(state, payload) {
      state.assessment_search_event_results = payload;
    },

    set_assessment_search_contract_results(state, payload) {
      state.assessment_search_contract_results = payload.contracts;
    },

    set_product_specific_forms(state, payload) {
      state.product_specific_forms = payload;
    },

    set_products_mutual_forms(state, payload) {
      state.products_mutual_forms = payload;
    },

    set_unit_price_master(state, payload) {
      state.unit_price_master = payload;
    },

    set_assessment_event_contract(state, payload) {
      state.assessment_event_contract = payload;
    },

    set_keep_the_item_contracts(state, payload) {
      // 対になっている詳細を消す
      state.sales_contracts = null;
      // こちらが必要な方
      state.keep_the_item_contracts = payload;
    },
    set_sales_contracts(state, payload) {
      // 対になってる詳細を消す
      state.keep_the_item_contracts = null;
      // こちらが必要な方
      state.sales_contracts = payload;
    },

    /**
     * BFF_ASS_00108 案件情報取得
     */
    set_event_by_event_id(state, payload) {
      state.event_by_event_id_response = payload;
    },

    /**
     *  BFF_ASS_00107 買取案件情報登録更新API
     *	BFF_ASS_00124 簡易案件情報登録更新
     *	BFF_ASS_00125 法人案件情報登録更新API
     *	BFF_ASS_00123 宅配案件登録更新
     *  BFF_ASS_00403 店舗案件登録更新API
     */
    set_event(state, payload) {
      state.event_by_event_id_response.event = payload;
    },

    // BFF_ASS_00109 案件関連査定商材一覧取得のレスポンス
    // ここは少し特殊な要件で、keyをもとに配列をためていくような実装を行う
    // また、取得方法も特殊なのでgetterを使う
    set_events_products(state, payload) {
      // getterに影響するのでVue.setで操作する
      payload.data.forEach((element) => {
        Vue.set(state.events_products, element.id, element);
      });
    },
    set_files(state, payload) {
      // getterに影響するのでVue.setで操作する
      Vue.set(state.files, payload.key, payload.src);
    },
    set_assessment_search_product(state, payload) {
      state.assessment_search_product = payload;
    },
    set_assessment_search_product_params(state, payload) {
      state.assessment_search_product_params = payload;
    },
    set_is_again_assessment_search(state, payload) {
      state.is_again_assessment_search = payload;
    },
    set_sp_products(state, payload) {
      state.sp_products = payload;
      state.sp_products_edit = false;
    },
    set_pc_products(state, payload) {
      state.pc_products = payload;
    },
    set_sp_item(state, payload) {
      state.sp_item = payload;
    },
    set_pc_item(state, payload) {
      state.pc_item = payload;
    },
    set_predicted_selling_price(state, payload) {
      state.predicted_selling_price = payload.data;
    },

    /**
     * ステートの特定のキーを初期化
     * 検索等のステートを初期化するために利用
     */
    reset_state_by_key(state, key) {
      const s = Object.assign({}, initialState());
      state[key] = s[key];
    },
    set_sp_products_edit(state, payload) {
      state.sp_products = payload;
      state.sp_products_edit = true;
    },
    set_past_assessments(state, payload) {
      state.past_assessments = payload;
    },
    set_assessment_selected_id(state, payload) {
      state.assessment_selected_id = payload;
    },
    set_unsupported_area(state, payload) {
      state.unsupported_area = payload;
    },
    set_event_search_filters(state, payload) {
      state.event_search_filters = payload;
    },
    set_product_search_filters(state, payload) {
      state.product_search_filters = payload;
    },
    /**
     * BFF_ASS_121 アンケート情報取得API(新規作成)
     */
    set_questionnaire_by_event_id(state, payload) {
      state.questionnaire_by_event_id_response = payload;
    },
    set_item_import_progress_error_message(state, payload) {
      state.item_import_progress_error_message = payload;
    },
    /**
     * BFF_ASS_00421 （データメンテナンス）案件完了日修正フォーム情報取得
     * BFF_ASS_00422 （データメンテナンス）案件完了日検索
     * BFF_ASS_00423 （データメンテナンス）案件完了日更新
     */
    set_event_dates_response(state, payload) {
      state.event_dates_response = payload;
    },
  },
  actions: {
    /**
     * Actionsに関して
     *
     * store.js以外、各マイクロサービスで使用するAPIへのリクエストのみを記載しています。
     *
     * 先頭のVue.$log.infoはあると嬉しいです。
     *
     * 通常はResponse→commit→必要に応じてComponentにてstateを参照していますが、
     * stateが適していないようなものに関してはresponseの中でreturnを行い、axiosのawaitをまってthen()で受けてください。
     * これは例えば、state経由にするほどでもないもの、動きが異なる箇所で別々の場所で呼び出す必要があるものなどを想定しています。
     *
     * エラー処理も個別にかかないといけないようなレアケースの場合、thenを記載せずに
     * return Vue.http.get('hogehoge');と1行にしてしまってもいいと思います。
     *
     * 関数名はRESTのMethodを頭につけ、そのあとにRESTのURLをつけています。（スラッシュはアンダースコアにしてpathが含まれるものは入れていません）
     * 例としてBFF_ASS_00201を記載しておきます
     *
     */

    /**
     * BFF_ASS_00201 専門査定明細検索絞り込み条件フォーム情報取得
     *
     * API概要 - 査定商材一覧の検索に必要な情報を取得 —
     *
     * ## バリデーション - 参照権限がない場合は403 —
     * ## SS API - 査定商材種別マスタ取得 - 査定種別マスタ取得 - 査定状況ステータスマスタ取得 - 検索条件日付区分マスタ取得 - 従業員一覧取得
     * ## 注意 - 名寄せされた顧客は名寄せ後の値のみを表示させる - 例）Aさんの検索結果が4件あったので、名寄せして1件にまとめた場合はその1件のみが表示される
     *
     * @param commit
     * @param state
     */
    async get_analogical_customers_search({ commit, state }) {
      Vue.$log.info(
        'BFF_ASS_00201 専門査定明細検索絞り込み条件フォーム情報取得',
      );

      return Vue.http
        .get(state.api + 'analogical_customers/search', {
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('get_analogical_customers_search', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_ASS_00223 マスタ一括取得
     *
     * ## API概要
     * - アセスメントの全マスタ取得を一括で行う
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     *
     * ## SS API
     * - ass_00104 案件情報登録更新
     */
    async get_all_masters({ commit, state }) {
      // ２回の取得は必要ない
      if (state.ass_masters.master_check_lists.length > 0) return true;
      Vue.$log.info('BFF_ASS_00223 マスタ一括取得');

      return Vue.http.get(state.api + 'all_masters').then(
        // 正常系
        (response) => {
          commit('set_all_masters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_00108 案件情報取得
     *
     * ## API概要
     * - 案件情報を取得するAPI 各種SSを呼び出し、結果を返却する
     *
     * ## バリデーション
     * - parametersが不正の場合は400
     * - データが存在しない場合は404
     * - 権限エラーの場合は403
     */
    async get_event_by_event_id({ commit, state }, id) {
      Vue.$log.info('BFF_ASS_00108 案件情報取得');

      return Vue.http.get(state.api + `events/${id}`).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event_by_event_id', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_ASS_00334 預かり契約情報取得
     */
    async get_keep_the_item_contracts(
      { commit, state },
      keep_the_item_contracts_id,
    ) {
      Vue.$log.info('BFF_ASS_00334 預かり契約情報取得');

      return Vue.http
        .get(
          state.api + `keep_the_item_contracts/${keep_the_item_contracts_id}`,
        )
        .then(
          // 正常系
          (response) => {
            commit('set_keep_the_item_contracts', response);
            // フルモーダルでは結果をComponent側で持ちたい
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_ASS_00302 売買契約情報取得
     */
    async get_sales_contracts({ commit, state }, id) {
      Vue.$log.info('BFF_ASS_00302 売買契約情報取得');

      return Vue.http.get(state.api + `sales_contracts/${id}`).then(
        // 正常系
        (response) => {
          // 詳細表示用
          commit('set_sales_contracts', response);
          // フルモーダルでは結果をComponent側で持ちたい
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_ASS_00107 買取案件情報登録更新API
     *
     * ## API概要
     * - 訪問・持ち込み査定案件の登録・更新を行うAPI
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     */
    async post_events_purchase({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00107 買取案件情報登録更新API');

      return Vue.http.post(state.api + 'events/purchase', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_ASS_00124 簡易案件情報登録更新
     *
     * ## API概要
     * - 簡易案件を登録・更新するAPI
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     */
    async post_events_simplicity({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00124 簡易案件情報登録更新');

      return Vue.http.post(state.api + 'events/simplicity', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },

    /**
     * BFF_ASS_00125 法人案件情報登録更新API
     *
     * ## API概要
     * - 法人案件を登録・更新するAPI
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     */
    async post_events_corporations({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00124 法人案件情報登録更新');

      return Vue.http.post(state.api + 'events/corporations', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },
    /**
     * BFF_ASS_00123 宅配案件登録更新
     *
     * ## API概要
     * - 宅配案件を登録・更新するAPI
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     */
    async post_events_delivery({ state, commit }, params) {
      Vue.$log.info('BFF_ASS_00123 宅配案件登録更新');

      return Vue.http.post(state.api + 'events/delivery', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },
    /**
     * BFF_ASS_00403 店舗案件登録更新API
     *
     * ## API概要
     * - 店舗案件を登録・更新する
     * - 店舗対応時間を非稼働時間予定テーブルに登録する
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     * - 更新に失敗した場合は422
     */
    async post_events_store({ state, commit }, params) {
      Vue.$log.info('BFF_ASS_00403 店舗案件登録更新API');

      return Vue.http.post(state.api + 'events/store', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が正常に終了しました',
          });
          commit('set_event', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
          return Promise.reject(error);
        },
      );
    },

    async get_events_keep_the_item_contracts_warehouse_receipts(
      { state },
      params,
    ) {
      Vue.$log.info('BFF_ASS_00205 専門査定開始API', params);

      const { event_id, id } = params;
      return Vue.http.get(
        state.api +
          `events/${event_id}/keep_the_item_contracts/${id}/warehouse_receipts`,
        {
          responseType: 'blob',
        },
      );
    },

    /**
     * BFF_ASS_00205 専門査定開始API
     * @param state
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    async patch_products_start_evaluation({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00205 専門査定開始API', params);

      return Vue.http
        .patch(state.api + `products/${params.id}/start_evaluation`, {
          is_forced_assessment: params.is_forced_assessment,
          lock_version: params.lock_version,
        })
        .then(
          // 正常系
          (response) => {
            // ライン情報を先にセット
            commit('set_sp_products_edit', response);
          },
          // 異常系
          (error) => {
            if (error.response.status === 409) {
              // 査定開始できない場合(409エラー)の場合はデータ更新
              commit('set_pc_products', error.response.data);
              commit('set_sp_products', error.response.data);
            }
            Vue.$log.error(error);
            throw error;
          },
        );
    },

    /**
     * 過去査定商材情報取得API
     * @param commit
     * @param state
     * @param params
     * @returns {Promise<AxiosResponse<T>>}
     */
    async get_past_assessments({ commit, state }, params) {
      Vue.$log.info('過去査定商材情報取得API', params);

      return Vue.http.get(state.api + 'past_assessments', { params }).then(
        // 正常系
        (response) => {
          // ライン情報を先にセット
          commit('set_past_assessments', response.data);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_ASS_00101 案件情報検索
     * 検索条件に従い案件情報の検索を行い、検索結果を返却する
     * API概要 - 案件情報を検索するAPI
     * - 100件を超えた場合は、フラグを返す（件数は変わる可能性があるので定数で持つ）
     * — ## バリデーション
     * - parametersが不正の場合は400
     * - 参照権限がない場合は403
     * - データが存在しない場合は404
     * — ## SS API - ass_00100 案件情報検索
     */
    async get_assessment_search_event({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00101 案件情報検索 ');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'events/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_assessment_search_event_results', response);
            Vue.$log.debug(state.assessment_search_event_results);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_ASS_00200 専門査定明細情報検索API
     * - 条件に応じた、査定依頼中の査定明細の一覧を返します
     * - 条件が未設定の場合は表示可能な査定依頼中の査定明細を全て返却します
     */
    get_assessment_search_product({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00200 専門査定明細情報検索API', params);

      // 検索フォームとば別のところで再検索を行うため、paramsが飛んでこないときがある
      // ただし、この画面に到達しているときは必ず1回は検索しているため、保存しているものを利用する
      if (params === null) {
        params = state.assessment_search_product_params;
        commit('set_is_again_assessment_search', true);
      } else {
        commit('set_assessment_search_product_params', params);
        commit('set_is_again_assessment_search', false);
      }

      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      Vue.http
        .get(state.api + 'products/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_assessment_search_product', response); // swaggerと違うがこれがあってそう
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * BFF_ASS_00113 査定商材登録フォーム商材項目取得API
     * API概要 - 商材登録に必要なフォーム情報で、商材ごとに異なる項目を取得する
     */
    async get_product_specific_forms({ commit, state }) {
      // ２回の取得は必要ない
      if (state.product_specific_forms) return true;

      Vue.$log.info('BFF_ASS_00113 査定商材登録フォーム商材項目取得API');

      return Vue.http.get(state.api + 'products/specific_forms').then(
        // 正常系
        (response) => {
          // ここは参照するだけなのでstateに設定
          commit('set_product_specific_forms', response.data);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * BFF_ASS_00225 査定商材登録フォーム共通項目取得API
     * API概要 - 査定商材登録に必要なフォーム情報で、商材に依存しない共通の項目を取得する
     */
    async get_products_mutual_forms({ commit, state }) {
      // ２回の取得は必要ない
      if (state.products_mutual_forms) return true;

      Vue.$log.info('BFF_ASS_00225 査定商材登録フォーム共通項目取得API');

      return Vue.http.get(state.api + 'products/mutual_forms').then(
        // 正常系
        (response) => {
          // ここは参照するだけなのでstateに設定
          commit('set_products_mutual_forms', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_00126 単価マスタ取得
     * API概要 - 貴金属相場、テレカ、骨董品の変動する価格を取得
     */
    async get_unit_price_master({ commit, state }) {
      // ２回の取得は必要ない
      if (state.unit_price_master) return true;

      Vue.$log.info('BFF_ASS_00126 単価マスタ取得');

      return Vue.http.get(state.api + 'unit_price_master').then(
        // 正常系
        (response) => {
          // ここは参照するだけなのでstateに設定
          commit('set_unit_price_master', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },

    /**
     * 契約情報検索API
     * 検索条件に従い案件情報の検索を行い、検索結果を返却する
     * API概要 - 案件情報を検索するAPI
     * - 100件を超えた場合は、フラグを返す（件数は変わる可能性があるので定数で持つ）
     * — ## バリデーション
     * - parametersが不正の場合は400
     * - 参照権限がない場合は403
     * - データが存在しない場合は404
     * — ## SS API - ass_00100 案件情報検索
     */
    async get_assessment_search_contract({ commit, state }, params) {
      Vue.$log.info('契約情報検索API');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'contracts/search', {
          params: params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_assessment_search_contract_results', response);
            Vue.$log.debug(state.assessment_search_contract_results);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },

    /**
     * 案件関連契約情報取得API
     *
     * @param state
     * @param id
     * @returns {Promise<R>}
     */
    async get_assessment_event_contract({ commit, state }, id) {
      Vue.$log.info('BFF_ASS_00110 案件関連契約情報取得 ');

      return Vue.http.get(state.api + 'events/' + id + '/contracts').then(
        // 正常系
        (response) => {
          commit('set_assessment_event_contract', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: error.messge,
          });
        },
      );
    },

    /*
     * BFF_ASS_00203 査定商材登録情報取得
     * IDで指定された査定商材登録情報を取得する
     *
     * @param state
     * @param id
     * @returns {Promise<R>}
     */
    async get_products({ state, commit }, id) {
      Vue.$log.info('BFF_ASS_00203 査定商材登録情報取得');

      return Vue.http.get(state.api + 'products/' + id).then(
        // 正常系
        (response) => {
          // 検索結果
          commit('set_sp_products', response);
          // SPとの処理的な差分を考える必要あるかもしれないため、同一内容だがいれておく
          commit('set_pc_products', response);

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /*
     * BFF_ASS_00203 査定商材登録情報取得
     * IDで指定された査定商材登録情報を取得する
     *
     * @param state
     * @param id
     * @returns {Promise<R>}
     */
    async get_item({ state, commit }, id) {
      Vue.$log.info('BFF_APP_00002 CASH情報取得');

      return Vue.http.get(state.appraisal_api + 'items/' + id).then(
        // 正常系
        (response) => {
          // 検索結果
          commit('set_sp_item', response);
          // SPとの処理的な差分を考える必要あるかもしれないため、同一内容だがいれておく
          commit('set_pc_item', response);

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /*
     * BFF_ASS_00203 査定商材登録情報取得
     * IDで指定された査定商材登録情報を取得する
     *
     * @param state
     * @param id
     * @returns {Promise<R>}
     */
    async get_item_as_product({ state, commit }, id) {
      Vue.$log.info('BFF_APP_00003 CASH情報取得');

      return Vue.http.get(state.appraisal_api + 'products/' + id).then(
        // 正常系
        (response) => {
          // 検索結果
          commit('set_sp_products', response);
          // SPとの処理的な差分を考える必要あるかもしれないため、同一内容だがいれておく
          commit('set_pc_products', response);

          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_APP_00005 CASH Item 更新
     */
    async update_item({ state }, params) {
      Vue.$log.info('BFF_APP_00005  CASH Item 更新', params);
      const item_id = params.id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.put(
        state.appraisal_api + 'items/' + item_id,
        params,
        config,
      );
    },
    /**
     * BFF_APP_00006 販売予想額推定
     */
    async predict_selling_price({ state, commit }, params) {
      Vue.$log.info('BFF_APP_00006 販売予想額推定', params);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        timeout: process.env.VUE_APP_TIME_OUT,
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      const requestParams = {
        id: params.id,
        master_assessment_type_id: params.master_assessment_type_id,
        master_assessment_item_type_id: params.master_assessment_item_type_id,
        master_assessment_status_id: params.master_assessment_status_id,
        assessment_classification_id: params.assessment_classification_id,
        assessment_item_elements: params.assessment_item_elements,
      };
      return Vue.http
        .post(
          state.appraisal_api + 'products/predict_price',
          requestParams,
          config,
        )
        .then(
          // 正常系
          (response) => {
            // 推定結果
            commit('set_predicted_selling_price', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /*
     * BFF_ASS_00143 時間指定未対応地域判別
     */
    async get_unsupported_area({ state, commit }, params) {
      Vue.$log.info('BFF_ASS_00143 時間指定未対応地域判別');

      return Vue.http
        .get(state.api + 'master_time_specified_unsupported_areas/search', {
          params,
        })
        .then(
          // 正常系
          (response) => {
            Vue.$log.info('BFF_ASS_00143 時間指定未対応地域判別');
            commit('set_unsupported_area', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: error.messge,
            });
          },
        );
    },

    /**
     * BFF_ASS_00114 案件関連査定商材情報一時保存
     */
    async post_events_products({ state }, params) {
      Vue.$log.info('BFF_ASS_00114 案件関連査定商材情報一時保存', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };

      return Vue.http.post(
        state.api + 'events/' + event_id + '/products',
        params,
        config,
      );
    },

    /**
     * BFF_ASS_00128 案件関連査定商材専門査定依頼
     */
    async post_events_products_offer({ state }, params) {
      Vue.$log.info('BFF_ASS_00128 案件関連査定商材専門査定依頼', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };

      return Vue.http.post(
        state.api + 'events/' + event_id + '/products/offer',
        params,
        config,
      );
    },

    /**
     * BFF_ASS_00129 案件関連査定商材買取可能
     */
    async post_events_products_buyable({ state }, params) {
      Vue.$log.info('BFF_ASS_00129 案件関連査定商材買取可能', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };

      return Vue.http.post(
        state.api + 'events/' + event_id + '/products/buyable',
        params,
        config,
      );
    },

    /**
     * BFF_ASS_00130 案件関連査定商材買取不可登録API
     */
    async post_events_products_non_buyable({ state }, params) {
      Vue.$log.info('BFF_ASS_00130 案件関連査定商材買取不可登録API', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };

      return Vue.http.post(
        state.api + 'events/' + event_id + '/products/non_buyable',
        params,
        config,
      );
    },

    /**
     * BFF_ASS_00131 案件関連査定商材預かり依頼API
     */
    async post_events_products_warehouse_offer({ state }, params) {
      Vue.$log.info('BFF_ASS_00131 案件関連査定商材預かり依頼API', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };

      return Vue.http.post(
        state.api + 'events/' + event_id + '/products/warehouse_offer',
        params,
        config,
      );
    },

    /**
     * BFF_ASS_00132 案件関連査定商材情報入力完了API
     */
    async post_events_products_information_fixed({ state }, params) {
      Vue.$log.info('BFF_ASS_00132 案件関連査定商材情報入力完了API', params);
      const event_id = params.event_id;

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(
        state.api + 'events/' + event_id + '/products/information_fixed',
        params,
        config,
      );
    },

    /**
     *BFF_ASS_00224  専門査定商材情報更新（一時保存）
     */
    async post_products({ state }, params) {
      Vue.$log.info('BFF_ASS_00224  専門査定商材情報更新（一時保存）', params);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(state.api + 'products', params, config);
    },

    /**
     * BFF_ASS_00211  査定情報登録(買取可能)
     * @param commit
     * @param state
     * @param AssessmentItem
     */
    async post_products_buyable({ state }, AssessmentItem) {
      Vue.$log.info('BFF_ASS_00211  査定情報登録(買取可能)', AssessmentItem);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(
        state.api + 'products/buyable',
        AssessmentItem,
        config,
      );
    },

    /**
     * BFF_ASS_00212  査定情報登録(買取不可)
     * @param commit
     * @param state
     * @param AssessmentItem
     */
    async post_products_non_buyable({ state }, AssessmentItem) {
      Vue.$log.info('BFF_ASS_00212  査定情報登録(買取不可)', AssessmentItem);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(
        state.api + 'products/non_buyable',
        AssessmentItem,
        config,
      );
    },

    /**
     * BFF_ASS_00213  査定情報登録(預かり依頼)
     * @param commit
     * @param state
     * @param AssessmentItem
     */
    async post_products_warehouse_offer({ state }, AssessmentItem) {
      Vue.$log.info('BFF_ASS_00213  査定情報登録(預かり依頼)', AssessmentItem);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(
        state.api + 'products/warehouse_offer',
        AssessmentItem,
        config,
      );
    },

    /**
     * BFF_ASS_00214  査定情報登録(情報入力依頼
     * @param commit
     * @param state
     * @param AssessmentItem
     */
    async post_products_information_offer({ state }, AssessmentItem) {
      Vue.$log.info('BFF_ASS_00214  査定情報登録(情報入力依頼', AssessmentItem);

      // TODO:これは最終的に必要ないので消すが影響範囲が多すぎるためにすぐに消せない
      const config = {
        transformResponse: Vue.http.defaults.transformResponse.concat(
          (data) => {
            data.vue_http_use_response = true;
            return data;
          },
        ),
      };
      return Vue.http.post(
        state.api + 'products/information_offer',
        AssessmentItem,
        config,
      );
    },

    /**
     * BFF_ASS_00206 専門査定辞退
     */
    async patch_products_decline_evaluation({ state }, params) {
      Vue.$log.info(' BFF_ASS_00206 専門査定辞退', params);
      return Vue.http.patch(
        state.api + 'products/' + params.id + '/decline_evaluation',
        {
          // ここは単純に渡せばいいだけの想定
          lock_version: params.lock_version,
        },
      );
    },

    /**
     * BFF_ASS_00229 査定やり直しAPI
     */
    async patch_products_cancel({ state }, params) {
      Vue.$log.info('BFF_ASS_00229 査定やり直しAPI', params);
      return Vue.http.patch(state.api + 'products/' + params.id + '/cancel', {
        // ここは単純に渡せばいいだけの想定
        lock_version: params.lock_version,
      });
    },

    /**
     * BFF_ASS_00109 案件関連査定商材一覧取得
     *
     * @param state
     * @param event_id
     * @returns {Promise<any|Thenable<any>|promise|promise>}
     */
    async get_events_products({ commit, state }, event_id) {
      Vue.$log.info('BFF_ASS_00109 案件関連査定商材一覧取得', event_id);

      return Vue.http
        .get(state.api + `events/${event_id}/products`, {
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // 検索結果
            commit('set_events_products', response);
            return response.data;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    /**
     * BFF_ASS_XXXXX ファイル取得
     * ファイル取得APIはバイナリで返ってくるため文字列だけ生成する
     * ファイル区分(1:契約系ファイル(契約書、関連書類等)、2:査定商材画像ファイル(撮影画像、書き込み画像等)、3:案件関連書類ファイル（不招請非該当確認書類、査定終了確認書類）)
     * @param state
     * @param params
     * @returns {Promise<string> | Thenable<string> | promise | promise | PromiseLike<string>}
     */
    get_file_path({ commit, state }, params) {
      const key = `file?name=${params.name}&type=${params.type}`;
      // const key = 'file?name=IMAGE_sample_square.jpg&type=2';

      if (key in state.files) {
        Vue.$log.info(
          'BFF_ASS_XXXXX ファイル取得 キャッシュファイル返却',
          params,
        );
        return state.files[key];
      }

      Vue.$log.info('BFF_ASS_XXXXX ファイル取得', params);

      return Vue.http
        .get(state.api + key, {
          timeout: process.env.VUE_APP_TIME_OUT,
          responseType: 'blob',
        })
        .then(
          // 正常系
          (response) => {
            let src;

            let type;

            if (params.name.match(/pdf/)) {
              // MIMEタイプ
              type = 'application/pdf';
            } else {
              // MIMEタイプ
              type = 'image/jpeg'; // ここはPNGが混じっても大丈夫だと思われる
            }

            let blob = new Blob([response], { type });
            src = URL.createObjectURL(blob);

            // 一度発行したURLを保持する
            // これはブラウザを閉じるまで有効
            commit('set_files', { key, src });

            return src;
          },
        )
        .catch((error) => {
          Vue.$log.error(error);
          throw error;
        });
    },

    post_events_sales_contracts({ state }, SalesContract) {
      Vue.$log.info('BFF_ASS_00118 売買契約情報登録更新', SalesContract);
      return Vue.http.post(
        state.api + `events/${SalesContract.event_id}/sales_contracts`,
        SalesContract,
      );
    },

    post_events_sales_contracts_contract_document_creation(
      { state },
      SalesContract,
    ) {
      Vue.$log.info('売買契約情報登録更新（売買契約書作成時）', SalesContract);
      return Vue.http.post(
        state.api +
          `events/${SalesContract.event_id}/sales_contracts/contract_document_creation`,
        SalesContract,
      );
    },

    /**
     * BFF_ASS_00337 売買契約登録更新（保存）
     * @param state
     * @param SalesContract
     * @returns {*}
     */
    post_sales_contracts({ state }, SalesContract) {
      Vue.$log.info('BFF_ASS_00337 売買契約登録更新（保存）', SalesContract);
      return Vue.http.post(state.api + 'sales_contracts', SalesContract);
    },

    /**
     * BFF_ASS_00341 預かり証契約登録更新（保存）
     * @param state
     * @param KeepTheItemContract
     * @returns {*}
     */
    post_keep_the_item_contracts({ state }, KeepTheItemContract) {
      Vue.$log.info(
        'BFF_ASS_00341 預かり証契約登録更新（保存）',
        KeepTheItemContract,
      );
      return Vue.http.post(
        state.api + 'keep_the_item_contracts',
        KeepTheItemContract,
      );
    },

    /**
     * BFF_ASS_00341 預かり証契約登録更新（保存）
     * @param state
     * @param KeepTheItemContract
     * @returns {*}
     */
    post_events_keep_the_item_contracts({ state }, KeepTheItemContract) {
      const { event_id } = KeepTheItemContract;
      Vue.$log.info(
        'BFF_ASS_00137 預かり契約情報登録更新',
        KeepTheItemContract,
      );
      return Vue.http.post(
        state.api + `events/${event_id}/keep_the_item_contracts`,
        KeepTheItemContract,
      );
    },

    /**
     * BFF_ASS_00338 売買契約登録更新（決済）
     * @param state
     * @param SalesContract
     * @returns {*}
     */
    post_sales_contracts_settle({ state }, SalesContract) {
      Vue.$log.info('BFF_ASS_00338 売買契約登録更新（決済）', SalesContract);
      return Vue.http.post(state.api + 'sales_contracts/settle', SalesContract);
    },

    /**
     * BFF_ASS_00342 預かり証契約登録更新（決済）
     * @param state
     * @param KeepTheItemContract
     * @returns {*}
     */
    post_keep_the_item_contracts_settle({ state }, KeepTheItemContract) {
      Vue.$log.info(
        'BFF_ASS_00342 預かり証契約登録更新（決済）',
        KeepTheItemContract,
      );
      return Vue.http.post(
        state.api + 'keep_the_item_contracts/settle',
        KeepTheItemContract,
      );
    },

    /**
     * BFF_ASS_00340 売買契約登録更新（キャンセル）
     * @param state
     * @param SalesContract
     * @returns {*}
     */
    post_sales_contracts_cancel({ state }, SalesContract) {
      Vue.$log.info(
        'BFF_ASS_00340 売買契約登録更新（キャンセル）',
        SalesContract,
      );
      return Vue.http.post(state.api + 'sales_contracts/cancel', SalesContract);
    },

    /**
     * BFF_ASS_00344 預かり証契約登録更新（キャンセル）
     * @param state
     * @param KeepTheItemContract
     * @returns {*}
     */
    post_keep_the_item_contracts_cancel({ state }, KeepTheItemContract) {
      Vue.$log.info(
        'BFF_ASS_00344 預かり証契約登録更新（キャンセル）',
        KeepTheItemContract,
      );
      return Vue.http.post(
        state.api + 'keep_the_item_contracts/cancel',
        KeepTheItemContract,
      );
    },

    /**
     * BFF_ASS_00135 売買契約書PDF作成（訪問用）
     * @param state
     * @param params
     * @returns {*}
     */
    post_events_sales_contracts_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00135 売買契約書PDF作成（訪問用）', params);
      return Vue.http.post(
        state.api +
          `events/${params.event_id}/sales_contracts/${params.sales_contract_id}/documents`,
        params.body,
      );
    },
    /**
     * BFF_ASS_00347 売買契約書PDF作成（郵送用）
     * @param state
     * @param params
     * @returns {*}
     */
    post_sales_contracts_documents_for_mail({ state }, params) {
      Vue.$log.info('BFF_ASS_00347 売買契約書PDF作成（郵送用）', params);
      return Vue.http.post(
        state.api +
          `sales_contracts/${params.sales_contract_id}/documents/for_mail`,
        params.body,
      );
    },
    /**
     * BFF_ASS_00345 売買契約書PDF取得
     * @param state
     * @param params
     * @returns {*}
     */
    get_events_sales_contracts_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00345 売買契約書PDF取得', params);
      return Vue.http.get(
        state.api +
          `events/${params.event_id}/sales_contracts/${params.sales_contract_id}/document`,
        {
          responseType: 'blob',
        },
      );
    },
    /**
     * BFF_ASS_00346 預かり証PDF取得
     *
     * @param state
     * @param params
     * @returns {*}
     */
    get_events_keep_the_item_contracts_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00346 預かり証PDF取得', params);
      return Vue.http.get(
        state.api +
          `events/${params.event_id}/keep_the_item_contracts/${params.keep_the_item_contract_id}/documents`,
        {
          responseType: 'blob',
        },
      );
    },
    /**
     * BFF_ASS_00142 預かり査定利用規約PDF取得
     * @param state
     * @param params
     * @returns {*}
     */
    get_keep_the_item_contract_terms_of_service({ state }, params) {
      Vue.$log.info('BFF_ASS_00142 預かり査定利用規約PDF取得', params);
      return Vue.http.get(
        state.api + 'keep_the_item_contract/terms_of_service',
        {
          params,
          responseType: 'blob',
        },
      );
    },
    /**
     * BFF_ASS_00139 預かり契約書PDF作成
     * @param state
     * @param params
     * @returns {*}
     */
    post_events_keep_the_item_contracts_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00139 預かり契約書PDF作成', params);
      return Vue.http.post(
        state.api +
          `events/${params.event_id}/keep_the_item_contracts/${params.keep_the_item_contract_id}/documents`,
        params.body,
      );
    },

    /**
     * BFF_ASS_00112 売買契約/預かり証契約追加可能査定商材一覧取得
     *
     * @param state
     * @param params
     * @returns {Promise<any|Thenable<any>|promise|promise>}
     */
    async get_contract_possible_products({ state }, params) {
      Vue.$log.info(
        'BFF_ASS_00112 売買契約/預かり証契約追加可能査定商材一覧取得',
        params,
      );

      return Vue.http.get(
        state.api + `events/${params.event_id}/contract_possible_products`,
        {
          params: {
            contract_type: params.contract_type,
            master_assessment_type_id: params.master_assessment_type_id,
          },
        },
      );
    },
    /**
     * BFF_ASS_00103 案件管理検索条件登録
     * 入力された検索条件を登録する
     */
    async post_event_search_filters({ state }, params) {
      Vue.$log.info('BFF_ASS_00103 案件管理検索条件登録');

      return Vue.http.post(state.api + 'event_quick_searches', params);
    },
    /**
     * BFF_ASS_00104 案件管理保存検索条件一覧取得
     * 案件管理検索条件から該当するレコードを取得する
     *
     * ここはstateを使うことが好ましくないのでComponentから直接呼び出す
     */
    async get_event_search_filters({ commit, state }) {
      Vue.$log.info('BFF_ASS_00104 案件管理保存検索条件一覧取得');

      return Vue.http.get(state.api + 'event_quick_searches').then(
        // 正常系
        (response) => {
          commit('set_event_search_filters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_00401 検索条件削除
     * 案件管理の保存した検索条件を削除する
     */
    async delete_event_search_filters({ state }, params) {
      Vue.$log.info('BFF_ASS_00401 検索条件削除');

      return Vue.http.delete(state.api + 'quick_searches/' + params).then(
        // 正常系
        (response) => {
          Vue.$log.debug(response);
          Vue.toasted.global.success({
            message: response.message,
          });
          return;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: error.message,
          });
        },
      );
    },
    /**
     * BFF_ASS_00220 査定商材管理検索条件登録
     * 入力された検索条件を登録する
     */
    async post_product_search_filters({ state }, params) {
      Vue.$log.info('BFF_ASS_00220 査定商材管理検索条件登録');

      return Vue.http.post(state.api + 'product_quick_searches', params);
    },
    /**
     * BFF_ASS_00221 査定商材管理検索条件一覧取得
     * 査定商材管理検索条件から該当するレコードを取得する
     *
     * ここはstateを使うことが好ましくないのでComponentから直接呼び出す
     */
    async get_product_search_filters({ commit, state }) {
      Vue.$log.info('BFF_ASS_00221 査定商材管理検索条件一覧取得');

      return Vue.http.get(state.api + 'product_quick_searches').then(
        // 正常系
        (response) => {
          commit('set_product_search_filters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_00401 検索条件削除
     * 査定商材管理の保存した検索条件を削除する
     */
    async delete_product_search_filters({ state }, params) {
      Vue.$log.info('BFF_ASS_00401 検索条件削除');

      return Vue.http.delete(state.api + 'quick_searches/' + params).then(
        // 正常系
        (response) => {
          Vue.$log.debug(response);
          Vue.toasted.global.success({
            message: response.message,
          });
          return;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: error.message,
          });
        },
      );
    },
    /**
     * BFF_ASS_00228 査定商材画像ファイルアップロード
     * API概要
     * - GCSにファイルをアップロードし、生成されたファイル名を返す
     *
     * ## バリデーション
     * - 入力チェックエラーの場合は400
     * - GCSへのファイルアップロードが失敗した場合は422
     * - 取得権限がない場合は403
     *
     * ## SS API
     * - なし
     *
     * ## 処理フロー
     * - 1. GCSにファイルをアップロードする
     * - 2. アップロードしたファイル名を返却する
     */
    async post_assessment_item_images({ state }, params) {
      Vue.$log.info('BFF_ASS_00228 査定商材画像ファイルアップロード');

      return Vue.http.post(state.api + 'assessment_item_images', params);
    },
    /**
     * BFF_ASS_121 アンケート情報取得API
     *
     * ## API概要
     * - アンケート情報を取得するAPI 各種SSを呼び出し、結果を返却する
     *
     * ## バリデーション
     * - parametersが不正の場合は400
     * - データが存在しない場合は404
     * - 権限エラーの場合は403
     */
    async get_questionnaire_by_event_id({ commit, state }, id) {
      Vue.$log.info('BFF_ASS_121 アンケート情報取得API');

      return Vue.http.get(state.api + 'events/' + id + '/questionnaire').then(
        // 正常系
        (response) => {
          commit('set_questionnaire_by_event_id', response);
          return response;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_122 アンケート情報登録更新API
     *
     * ## API概要
     * - アンケート情報の登録・更新を行うAPI
     * - uuidはフロントで発行しサーバサイドはUPSERTを行う
     * - エラー時はRequestされた値をそのまま返却し、バージョンも変わらない
     * - 登録・更新が成功した場合、更新後の値を返却する
     *
     * ## バリデーション
     * - 入力値が不正な場合は400
     * - 楽観排他バージョンが古い場合は409
     */
    async post_events_questionnaire({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_122 アンケート情報登録更新API');

      return Vue.http
        .post(
          state.api + 'events/' + params.event_id + '/questionnaire',
          params,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '処理が正常に終了しました',
            });
            commit('set_questionnaire_by_event_id', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.toasted.global.error({
              message: error.response.data.details
                .map((d) => d.field_messages.join())
                .join(),
            });
            Vue.$log.error(error);
            return Promise.reject(error);
          },
        );
    },
    /**
     * BFF_ASS_00408 不招請非該当確認書類PDF作成API
     *
     * ## API概要
     * - 不招請書類を作成する。
     * - 案件IDと署名画像(base64)を引数として渡す
     * - responseには実行結果を返却する。
     *
     * ## バリデーション
     *
     * - 入力エラーの場合は400
     * - 更新権限がない場合は403
     */
    async post_uninvited_solicitation_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00408 不招請非該当確認書類PDF作成API');

      return Vue.http
        .post(
          state.api +
            'events/' +
            params.event_id +
            '/uninvited_solicitation_documents',
          params,
        )
        .then(
          // 正常系
          () => {
            Vue.toasted.global.success({
              message: '処理が正常に終了しました',
            });
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            return Promise.reject(error);
          },
        );
    },
    /**
     * BFF_ASS_00409 不招請非該当確認書類PD取得API
     *
     * ## API概要
     * - サインなしのPDFを取得する
     * - responseにはバイナリーデータを返却する。
     *
     * ## バリデーション
     *
     * - 入力エラーの場合は400
     * - 更新権限がない場合は403
     */
    get_uninvited_solicitation_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00409 不招請非該当確認書類PD取得API');
      return Vue.http.get(
        state.api +
          'events/' +
          params.event_id +
          '/uninvited_solicitation_documents',
        {
          responseType: 'blob',
        },
      );
    },
    /**
     * BFF_ASS_00410 査定終了確認書PDF作成
     *
     * ## API概要
     * - 査定終了確認書類を作成する。
     * - 案件IDと確認項目・回答内容のセット、署名画像(base64)を引数として渡す
     * - responseには実行結果を返却する。
     *
     * ## バリデーション
     *
     * - 入力エラーの場合は400
     * - 更新権限がない場合は403
     */
    async post_confirmation_documents({ state }, params) {
      Vue.$log.info('BFF_ASS_00410 査定終了確認書PDF作成');

      return Vue.http
        .post(
          state.api + 'events/' + params.event_id + '/confirmation_documents',
          params,
        )
        .then(
          // 正常系
          () => {
            Vue.toasted.global.success({
              message: '処理が正常に終了しました',
            });
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            return Promise.reject(error);
          },
        );
    },
    /**
     * BFF_ASS_413 査定商材アップロードAPI
     *
     * ## API概要
     * - 案件管理画面からCSVに入力された商材を一括でアップロードするAPI
     * ## バリデーション
     * - 入力チェックエラーの場合は400
     * - 更新権限がない場合は403
     * ## SS API
     * - ass_00112 案件関連査定商材買取可能登録
     * ## 処理フロー
     * 1. FEより登録処理できる状態に整形されたRequestBody（査定商材の配列）を受け取る
     * 2. RequestBodyのバリデーションを行う為、案件関連査定商材買取可能登録APIを叩く
     * 3. 案件関連査定商材買取可能登録APIより、バリデーションの結果を受け取る
     *   a. バリデーションが正常に通った場合、common.Doneを返却する
     *   b. 不正なパラメータが存在した場合、ActiveModel::Errorsが返却される
     * 4. 3の処理を通過した後、商材を買取可能にする為、案件関連査定商材買取可能登録APIを再度叩く
     * 5. APIの実行速度に影響しないよう一部の処理を非同期で行う
     *   a. メインスレッドではFEに成功レスポンスを返却
     *   b. 別スレッドでは以下の処理を行う
     *     i. CSVデータチェックAPIより受け取ったレスポンスを買取可能にする為、案件関連査定商材買取可能登録APIを叩く
     *     ii. アップロードの進捗状況をRedisに書き込む
     * ## 注意点
     * - このAPIを叩いてアップロードする商材は全て買取可能の想定
     */

    async post_assessment_item_import({ state }, params) {
      Vue.$log.info('BFF_ASS_00413 査定商材アップロードAPI');

      return Vue.http
        .post(
          state.api + '/events/' + params.event_id + '/products/import_csv',
          params.body,
        )
        .then(
          // 正常系
          () => {
            Vue.toasted.global.success({
              message:
                '商品の一括登録を受け付けました。進捗は上部のバーに表示されます。',
            });
          },
          // 異常系
          (error) => {
            return Promise.reject(error.response.data.details);
          },
        );
    },
    /**
     * BFF_ASS_00414 査定アップロード進捗状況取得API
     *
     * ## API概要
     *  - 査定商材アップロードAPIよりアップロードされたCSVの進捗状況を取得するAPI
     * ## バリデーション
     *  - 入力チェックエラーの場合は400
     *  - 参照権限がない場合は403
     * ## SS API
     *  なし
     * ## 処理フロー
     *  1. RedisよりアップロードされたCSVの進捗状況を取得する
     *  2. FEに返却
     * ## 特記
     *  - key:value
     *  - keyは"event_import_products_csv:該当の案件ID"
     *  - valueは進捗状況(現在のアップロード数 / 総アップロード数)
     * ## 注意点
     *  - レスポンスについて
     *  - キャッシュを読み込んだ際にデータがなかった場合はnullを返却
     *  - キャッシュを読み込んだ際にデータが存在しており進捗が0%の場合は0.0を返却
     *  - キャッシュ使用フラグがfalseの場合はnullを返却
     */
    async get_assessment_item_import_progress({ state }, event_id) {
      Vue.$log.info('BFF_ASS_00414 査定アップロード進捗状況取得API');

      return Vue.http
        .get(state.api + 'events/' + event_id + '/products/import_csv_progress')
        .then(
          // 正常系
          (response) => {
            return Promise.resolve(response['import_csv_progress']);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            return Promise.reject(error);
          },
        );
    },
    /**
     * BFF_ASS_00421 （データメンテナンス）案件完了日修正フォーム情報取得
     *
     * API概要 - 案件完了日、流入登録日修正画面の初期表示用項目を取得
     *
     * ## バリデーション - 参照権限がない場合は403 —
     *
     * @param commit
     * @param state
     */
    async get_event_dates({ commit, state }) {
      Vue.$log.info(
        'BFF_ASS_00421 （データメンテナンス）案件完了日修正フォーム情報取得',
      );

      return Vue.http.get(state.api + 'maintenance/events/dates').then(
        // 正常系
        (response) => {
          commit('set_event_dates_response', response);
          return response.events;
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_ASS_00422 （データメンテナンス）案件完了日検索
     *
     * API概要 - 案件idをkeyに案件に紐づく、案件完了日を検索する
     *
     * ## バリデーション - 参照権限がない場合は403 —
     * ## SS API - （データ修正用）案件検索
     *
     * @param commit
     * @param state
     */
    async post_event_dates_search({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00422 （データメンテナンス）案件完了日検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .post(state.api + 'maintenance/events/dates/search', params)
        .then(
          // 正常系
          (response) => {
            commit('set_event_dates_response', response);
            return response.events;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_ASS_00423 （データメンテナンス）案件完了日更新
     *
     * API概要 - 案件idをkeyに案件に紐づく、案件完了日を更新する
     *
     * ## バリデーション - 参照権限がない場合は403 —
     * ## SS API - （データ修正用）案件更新
     *
     * @param commit
     * @param state
     */
    async patch_event_dates_update_all({ commit, state }, params) {
      Vue.$log.info('BFF_ASS_00423 （データメンテナンス）案件完了日更新');
      let toast = Vue.toasted.global.load({
        message: '更新中',
      });

      return Vue.http
        .patch(state.api + 'maintenance/events/dates/update_all', params)
        .then(
          // 正常系
          (response) => {
            commit('set_event_dates_response', response);
            return response.events;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_ASS_00424 査定商材入力項目変換API
     *
     * ## API概要
     * - 査定商材種別移行先IDをkeyに入力した査定商材項目、査定商材項目選択肢を更新する
     *
     */
    async post_convert_assessment_item({ state, commit }, params) {
      Vue.$log.info('BFF_ASS_00424 査定商材入力項目変換API');
      const {
        event_id,
        master_assessment_item_type_destination_id,
        assessment_item,
      } = params;

      return Vue.http
        .post(
          state.api +
            `events/${event_id}/products/${master_assessment_item_type_destination_id}/convert`,
          assessment_item,
        )
        .then(
          // 正常系
          (response) => {
            Vue.toasted.global.success({
              message: '処理が正常に終了しました',
            });
            commit('set_event', response);
            return response;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
            return Promise.reject(error);
          },
        );
    },
    /**
     * BFF_ASS_00425 変換可能査定商材種別一覧取得API
     *
     * ## API概要
     * 査定商材種別IDを送り、変換可能な査定商材種別の情報の一覧を返す
     */
    get_assessment_item_type_conversion_mappings(
      { state },
      master_assessment_item_type_id,
    ) {
      Vue.$log.info(
        'BFF_ASS_00425 変換可能査定商材種別一覧取得API',
        master_assessment_item_type_id,
      );
      return Vue.http.get(
        state.api + `/master_assessment_item_type_conversion_mappings`,
        {
          params: { master_assessment_item_type_id },
        },
      );
    },
  },
};
/**
 * moment
 */
import VueMoment from 'vue-moment';
const moment = require('moment');
require('moment/locale/ja');
Vue.use(VueMoment, {
  moment,
});
// 現在の年齢
Vue.filter('age', function (datetime) {
  if (datetime) {
    let birth = moment(datetime);

    return ' ' + moment().diff(birth, 'years') + '歳' + ' ';
  } else {
    return '';
  }
});
