import Vue from 'vue';

/**
 * fundに関連するStore
 */
export const fund = {
  namespaced: true,
  state: {
    /**
     * APIのURLとして利用
     */
    api: 'fund/v1/',
    /**
     * 營業準備金検索
     */
    sales_reserve_search_form: Object,
    sales_reserves: [],
    tab: null,
    /**
     * 營業準備金詳細
     */
    sales_reserve: {
      account_information: {
        sales_reserve_type: null,
        account: null,
        pincode: null,
        account_name: null,
      },
      appraiser_information: {
        employee_last_name: null,
        employee_first_name: null,
        employee_last_name_kana: null,
        employee_first_name_kana: null,
        employee_number: null,
        is_resigned: false,
        organization_name: null,
      },
      sales_reserve_information: {
        daily_summary_balance: 0,
        date: null,
        reported_account_balance: 0,
        reported_money_amount: 0,
        reported_possession_money: 0,
        with_diffrences: false,
      },
    },
    /**
     * 入出金
     */
    account_activity_form: null,
    account_activity_result: null,
    /**
     * 入出金履歴検索
     */
    account_activity_search_form: Object,
    account_activity_types: [
      { id: 1, name: '入金' },
      { id: 2, name: '出金' },
      { id: 3, name: '買取金支払' },
      { id: 4, name: '報告' },
    ],
    account_activities: [],
    /**
     * 店舗口座登録更新用マスタ
     */
    store_account_masters: {
      stores: [],
      master_banks: [],
      master_bank_account_types: [],
    },
  },

  mutations: {
    set_sales_reserve_search_form(state, payload) {
      state.sales_reserve_search_form = payload;
    },
    set_sales_reserve(state, payload) {
      // TODO: swaggerのインラインスキーマを修正後に修正する
      state.sales_reserve = payload.SalesReserveAccountModel;
    },
    set_sales_reserves(state, payload) {
      // TODO: swaggerのインラインスキーマを修正後に修正する
      state.sales_reserves = payload.sales_reserve_accounts;
    },
    set_account_activity_form(state, payload) {
      state.account_activity_form = payload;
    },
    set_account_activity_success(state, payload) {
      state.account_activity_result = payload;
    },
    set_account_activity_search_form(state, payload) {
      state.account_activity_search_form = payload;
    },
    set_account_activities(state, payload) {
      state.account_activities = payload;
    },
    set_sales_reserve_balance_success(state, payload) {
      state.sales_reserve_balance_result = payload;
    },
    set_store_account_masters(state, payload) {
      state.store_account_masters = payload;
    },
  },
  actions: {
    /**
     * BFF_FUND_00001 営業準備金検索フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_sales_reserve_search_form({ commit, state }) {
      if (Object.keys(state.sales_reserve_search_form).length) return true;
      Vue.$log.info('BFF_CRM_00001 營業準備金検索フォーム情報取得');

      return Vue.http.get(state.api + 'sales_reserve_search_form').then(
        // 正常系
        (response) => {
          commit('set_sales_reserve_search_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_FUND_00002 営業準備金検索
     *
     * @param commit
     * @param state
     * @param params
     */
    async search_sales_reserves({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00002 営業準備金検索');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      // timeoutを設定しているAPIに使用するキャンセル処理
      this.source && this.source.cancel('リクエストキャンセル');
      this.source = Vue.http.CancelToken.source();

      return Vue.http
        .get(state.api + 'sales_reserve_accounts/search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
          cancelToken: this.source.token,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_sales_reserves', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_FUND_00019 営業準備金詳細取得
     *
     * @param commit
     * @param state
     * @param params
     */
    async get_sales_reserve({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00019 営業準備金詳細取得');

      return Vue.http
        .get(
          state.api +
            'sales_reserve_accounts?sales_reserve_id=' +
            params.id +
            '&date=' +
            params.date,
        )
        .then(
          // 正常系
          (response) => {
            commit('set_sales_reserve', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_FUND_00003 営業準備金入出金登録フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_account_activity_form({ commit, state }) {
      if (state.account_activity_form) return true;
      Vue.$log.info('BFF_CRM_00003 營業準備金入出金登録フォーム情報取得');

      return Vue.http.get(state.api + 'account_activity_form').then(
        // 正常系
        (response) => {
          commit('set_account_activity_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_FUND_00004 営業準備金口座入出金処理
     *
     * @param commit
     * @param state
     * @param params
     */
    async register_account_activity({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00004 営業準備金口座入出金処理');
      return Vue.http.post(state.api + 'account_activities', params).then(
        // 正常系
        (response) => {
          commit('set_account_activity_success', response);
        },
        // 異常系
        (error) => {
          commit('set_account_activity_success', false);
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_FUND_00005 営業準備金口座入出金金額修正
     *
     * @param commit
     * @param state
     * @param params
     */
    async edit_account_activity({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00005 営業準備金口座入出金金額修正');
      return Vue.http
        .patch(
          state.api + 'account_activities/' + params.id,
          params.request_body,
        )
        .then(
          // 正常系
          (response) => {
            commit('set_account_activity_success', response);
          },
          // 異常系
          (error) => {
            commit('set_account_activity_success', false);
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_FUND_00017 営業準備金入出金履歴検索フォーム情報取得
     *
     * @param commit
     * @param state
     */
    async get_account_activity_search_form({ commit, state }) {
      if (Object.keys(state.account_activity_search_form).length) return true;
      Vue.$log.info('BFF_CRM_00017 營業準備金入出金履歴検索フォーム情報取得');

      return Vue.http.get(state.api + 'account_activity_search_form').then(
        // 正常系
        (response) => {
          commit('set_account_activity_search_form', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_FUND_00008 営業準備金入出金情報取得（検索）
     *
     * @param commit
     * @param state
     * @param params
     */
    async search_account_activities({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00008 営業準備金入出金情報取得（検索）');
      let toast = Vue.toasted.global.load({
        message: '検索中',
      });

      return Vue.http
        .get(state.api + 'account_activities', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_account_activities', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        )
        .finally(() => toast.goAway());
    },
    /**
     * BFF_FUND_00009 営業準備金残高報告修正
     *
     * @param commit
     * @param state
     * @param params
     */
    async edit_sales_reserve_balance({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00009 営業準備金残高報告修正');
      return Vue.http
        .patch(
          state.api + 'sales_reserve_balances/' + params.id,
          params.request_body,
        )
        .then(
          // 正常系
          (response) => {
            commit('set_sales_reserve_balance_success', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    /**
     * BFF_FUND_00021 店舗口座登録更新用マスタ取得
     *
     * @param commit
     * @param state
     */
    async get_store_account_masters({ commit, state }) {
      if (state.store_account_masters.stores.length > 0) return;
      Vue.$log.info('BFF_FUND_00021 店舗口座登録更新用マスタ取得');

      return Vue.http.get(state.api + 'store_account_masters').then(
        // 正常系
        (response) => {
          commit('set_store_account_masters', response);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    /**
     * BFF_FUND_00021 店舗口座登録更新
     *
     * @param commit
     * @param state
     * @param params
     */
    async post_store_account({ commit, state }, params) {
      Vue.$log.info('BFF_FUND_00021 店舗口座登録更新');
      return Vue.http.post(state.api + 'store_account', params).then(
        // 正常系
        (response) => {
          Vue.toasted.global.success({
            message: '処理が成功しました',
          });
          return response;
        },
        // 異常系
        (error) => {
          Vue.toasted.global.error({
            message: '処理が失敗しました',
          });
        },
      );
    },
  },
};
