<template lang="pug">
v-autocomplete(
  :readonly='readonly',
  :disabled='disabled',
  :error-messages='errorMessages',
  :error='is_error',
  :clearable='clearable && !readonly',
  v-model='local_value',
  :items='filter_items',
  no-filter,
  :search-input.sync.lazy='search',
  no-data-text='見つかりません',
  :required='isRequired'
)
</template>
<script>
export default {
  /**
   * props
   */
  props: {
    items: Array,
    disabled: Boolean,
    errorMessages: Array,
    value: [String, Number],

    readonly: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    // HTML5のバリデート用
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * data
   */
  data: function () {
    return {
      // フィルタリングしたものを表示に使用するが、初期値としてpropsのitemsを使う
      filter_items: null,
      search: null,
      is_error: false,
      local_items: null,
    };
  },

  /**
   * computed
   */
  computed: {
    local_value: {
      // getter 関数
      get: function () {
        return this.value;
      },
      // setter 関数
      set: function (newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  /**
   * watch
   */
  watch: {
    search: function (val) {
      if (Array.isArray(this.items)) {
        if (val) {
          // 入力されている場合は配列をフィルタリングする
          this.filter_items = this.local_items.filter((text) => {
            if (text.indexOf('その他') >= 0) {
              return true;
            } else if (text.indexOf(val) >= 0) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          // 入力されていない場合は元データを使う
          this.filter_items = this.local_items;
        }
      }
    },
    items: function () {
      this.init();
    },
  },
  /**
   * created
   */
  created: function () {
    this.init();
  },
  /**
   * methods
   */
  methods: {
    // 初期化&更新
    init() {
      if (!Array.isArray(this.items)) {
        this.$log.error('VAutocompleteSearch', '配列ではありません');
        this.is_error = true;
        this.error_messages = '配列が設定されていません';
      } else {
        this.local_items = this.items;
        this.filter_items = this.items;
      }
    },
  },
};
</script>
