import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import authority from '@/authority.js';

Vue.use(Vuex);

// AES関連
import CryptoJS from 'crypto-js';
const STORAGE_KEY = 'id_token';
const AZURE_AD_STORAGE_KEY = 'azureAdAccount';
const KEY = 'mcjiypw7yixami3e4z5875uu5h7r83ze';
const IV = 's6ftmky7hetb2pyw';

/**
 * 全ページに関連するStore
 */
const global = {
  namespaced: true,

  state: {
    /**
     * スマホ判定
     */
    is_mobile: false,

    /**
     * 現在通信中かどうか
     * リクエスト開始時にインクリメントされ、通信終了時にデクリメントされる
     * 0以外の時に表示されればいい
     */
    http_loading: 0,

    /**
     * 現在通信中かどうか
     * リクエスト開始時にインクリメントされ、通信終了時にデクリメントされる
     * 0以外の時に表示されればいい
     * これは画面をロックしない小さな物にする
     */
    http_loading_quiet: 0,

    /**
     * 500や404の深刻なエラーが発生した時にtrueにする
     * trueのあと、それをどうするかは別途検討
     */
    http_error: false,

    /**
     * 400で返ってきたレスポンスを格納する
     */
    error_messages: { details: [], messages: [] },

    /**
     * ログイン中のユーザー
     */
    current_user: {
      profile: {
        name: '',
        params: {
          gyro_role: false,
          gyro_acquisition_scope: '',
          gyro_display_scope: '',
          gyro_user_classification: '',
          first_name: '',
          last_name: '',
          organization_code: '',
        },
      },
      expires_at: 0,
      authenticatorError: false,
    },

    /**
     * スナックバー(当面はデバッグ用)
     */
    snackbar: {
      display: false,
      text: '',
    },

    /**
     * vuetifyjsのルール用
     * Validationはここで行う
     */
    rules: {
      required: (value) => !!value || '入力してください',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },

    /**
     * enum
     */
    // 日付
    enum_master_assessment_date_classification: {
      NOTSPECIFIED: 1, // 指定しない
      TODAY: 2, // 本日の日付
      SPECIFIEDDATE: 3, // 日付で期間を指定
    },
    // アポランク
    enum_master_appointment_rank: [
      { id: 1, name: 'A' },
      { id: 2, name: 'B' },
      { id: 3, name: 'C' },
      { id: 4, name: 'D' },
      { id: 5, name: 'E' },
      { id: 6, name: 'ランクなし' },
    ],
    // 査定種別
    // FIXME: IDが間違っている
    enum_master_assessment_type_for_buysell: [
      { id: 5, name: '査定依頼無し' },
      { id: 1, name: '訪問査定' },
      { id: 2, name: '持込査定' },
      { id: 3, name: '宅配査定' },
      { id: 4, name: '簡易査定' },
      { id: 6, name: '店舗査定' },
      { id: 7, name: 'CASH査定' },
      { id: 8, name: '店舗査定（TL）' },
    ],
    enum_master_assessment_type: [
      { id: 5, name: '査定依頼無し' },
      { id: 1, name: '訪問査定' },
      { id: 2, name: '持込査定' },
      { id: 3, name: '宅配査定' },
      { id: 4, name: '簡易査定' },
      { id: 6, name: '店舗査定' },
      { id: 7, name: 'CASH査定' },
    ],
    // 案件種別
    enum_master_event_type: {
      VISIT: 1, // 訪問案件
      BRING: 2, // 持ち込み案件
      DELIVERY: 3, // 宅配案件
      CORPORATION: 4, // 法人案件
      SIMPLE: 5, // 簡易案件
      STORE: 6, // 店舗案件
    },
    // 宅配キット種類
    enum_master_shipping_baggage_type: {
      CARDBOARD: 1, // ダンボール + 書類
    },
    // 本人限定受取状況
    enum_master_registered_mail_status: {
      SENT: 2, // 送信済み
      RECEIVING_VERIFIED: 3, // 受取確認済み
    },
    // 契約種別ID
    enum_master_contract_types: [
      { id: 1, name: null },
      { id: 2, name: '現場査定契約' },
      { id: 3, name: '預かり査定契約' },
      { id: 4, name: '宅配査定契約' },
      { id: 5, name: '法人査定契約' },
    ],

    // 契約種別ID
    enum_assessment_operation: [
      { id: 1, name: '未選択' },
      { id: 2, name: '買取可能' },
      { id: 3, name: '買取不可' },
      { id: 4, name: '預かり査定が必要' },
      { id: 5, name: '査定するための情報が不足' },
    ],

    enum_sort_type: {
      BY_ASSESSMENT_DATE: 1,
      BY_EVENT: 2,
    },

    // 共通ダイアログ
    common_dialog_vo: {
      is_open: false,
      message: '',
      done_callback: null,
      cancel_callback: null,
    },
  },
  getters: {
    /**
     */
    v_error:
      (state) =>
      (...args) => {
        if (state.error_messages && state.error_messages.details) {
          let index = state.error_messages.details.findIndex((k) =>
            args.includes(k.field_name),
          );
          if (index >= 0) {
            return [state.error_messages.details[index].field_messages];
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    /**
     * エラーの配列を単純な配列にして返す
     */
    v_error_f:
      (state) =>
      (...args) => {
        if (state.error_messages && state.error_messages.details) {
          let index = state.error_messages.details.findIndex((k) =>
            args.includes(k.field_name),
          );
          if (index >= 0) {
            return [
              _.flattenDeep(state.error_messages.details[index].field_messages),
            ];
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    /**
     * エラーの配列をさらに単純な配列にして返す
     */
    v_error_ff: (state) => (name) => {
      if (state.error_messages && state.error_messages.details) {
        let index = state.error_messages.details.findIndex(
          (k) => k.field_name == name,
        );
        if (index >= 0) {
          return _.flattenDeep(
            _.flattenDeep(state.error_messages.details[index].field_messages),
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    gyro_role: (state) => {
      if (state.current_user.profile.params.gyro_role) {
        return state.current_user.profile.params.gyro_role;
      } else {
        // gyro_roleは文字列かfalseを返し、参照先で先にfalseを確認する
        return false;
      }
    },
    /**
     * ロールの名称を返す
     * @returns {String}
     */
    gyro_role_name: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return '-';
      return authority[gyro_role].name;
    },
    show_customer_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].customer_management;
    },
    show_identify_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].identify_management;
    },
    show_communication_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].communication_management;
    },
    show_appointment_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].appointment_management;
    },
    show_appoint_schedule_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].appoint_schedule_management;
    },
    show_sales_appraiser_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].sales_appraiser_management;
    },
    show_sales_schedule_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].sales_schedule_management;
    },
    show_event_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].event_management;
    },
    show_event_related_assessment_item: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].event_related_assessment_item;
    },
    show_event_related_sales_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].event_related_sales_management;
    },
    show_assessment_item: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].assessment_item;
    },
    show_settlement_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].settlement_management;
    },
    show_sales_reserves_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].sales_reserves_management;
    },
    show_master_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].master_management;
    },
    show_campaign_master_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].campaign_master_management;
    },
    show_budget_upload: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].budget_upload;
    },
    show_employee_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].employee_management;
    },
    show_data_management: (_, getters) => {
      const { gyro_role } = getters;
      if (!gyro_role) return false;
      return authority[gyro_role].data_management;
    },
  },
  mutations: {
    /**
     * スマホ判定を変更
     * @param state
     * @param phone
     */
    set_is_mobile(state, phone) {
      state.is_mobile = phone;
    },

    /**
     * Loadingの変更
     * @param state
     * @param isLoading
     */
    set_http_loading(state, isLoading) {
      if (isLoading) {
        state.http_loading = state.http_loading + 1;
      } else {
        state.http_loading = state.http_loading - 1;
      }
    },

    /**
     * Loadingの変更
     * @param state
     * @param isLoading
     */
    set_http_loading_quiet(state, isLoading) {
      if (isLoading) {
        state.http_loading_quiet = state.http_loading_quiet + 1;
      } else {
        state.http_loading_quiet = state.http_loading_quiet - 1;
      }
    },

    /**
     * axiosでエラーが発生したときに使用
     *
     * @param state
     * @param error
     */
    set_http_error(state, error) {
      Vue.$log.error(error);
      state.http_error = true;
    },

    /**
     * Azure ADからのJWT
     *
     * @param state
     * @param current_user
     */
    set_login(state, user) {
      Vue.http.defaults.headers.common[
        'authorization'
      ] = `Bearer ${user.id_token}`;
      state.current_user = user;

      // このとき、同時にローカルストレージにも保持する
      try {
        localStorage.setItem(
          STORAGE_KEY,
          // Satelliteと同一のアルゴリズムを使用する
          CryptoJS.AES.encrypt(
            JSON.stringify(user),
            CryptoJS.enc.Utf8.parse(KEY),
            {
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
              iv: CryptoJS.enc.Utf8.parse(IV),
            },
          ).toString(),
        );
      } catch (e) {
        Vue.$log.error(e);
      }
    },

    /**
     * 400エラーを格納し、各所から参照する
     *
     * @param state
     * @param error_messages
     */
    set_error_messages(state, error_messages) {
      state.error_messages = error_messages;
    },

    /**
     * 400エラーを削除する
     *
     * @param state
     */
    delete_error_messages(state) {
      state.error_messages = { details: [], messages: [] };
    },

    /**
     * 主にDebug用
     *
     * @param state
     * @param error_messages
     */
    set_snackbar(state, params) {
      state.snackbar = {
        display: true,
        color: params.color,
        text: params.text,
      };
    },

    set_common_dialog_vo(state, vo) {
      state.common_dialog_vo = vo;
    },
  },
};

/**
 * サービス毎のStore
 */

import { assessment } from './stores/Assessment';
import { reservation } from './stores/Reservation';
import { crm } from './stores/Crm';
import { fund } from './stores/Fund';
import { common } from './stores/Common';
import { promas } from './stores/Promas';

export default new Vuex.Store({
  modules: {
    global,
    assessment,
    reservation,
    crm,
    fund,
    common,
    promas,
  },
  // ここのactionsは全ての画面のbeforeMountで呼ぶようにしているため、ブラウザで開いた最初の1回だけ純粋に呼ばれる
  actions: {
    // 認証情報の復元
    userRestoration({ commit }) {
      // 認証を走らせる前にローカルストレージに認証情報が存在するかを確認する
      if (localStorage.getItem(STORAGE_KEY)) {
        Vue.$log.info('ローカルストレージの認証情報をStoreに復元');
        // Satelliteと同一のアルゴリズムを使用する
        let store = CryptoJS.AES.decrypt(
          localStorage.getItem(STORAGE_KEY),
          CryptoJS.enc.Utf8.parse(KEY),
          {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse(IV),
            padding: CryptoJS.pad.Pkcs7,
          },
        ).toString(CryptoJS.enc.Utf8);

        // 取得したものがjsonなのかどうかを確認する
        let isValidJson = true;
        try {
          JSON.parse(store);
        } catch (e) {
          isValidJson = false;
        }

        let user;
        // ここは通常であればjsonだが、SatelliteからはJWTが文字列で入る
        if (isValidJson) {
          // これはjsonなので変数に戻す
          user = JSON.parse(store);
        } else {
          // ここに到達する場合、Satelliteから

          let ios_token;

          if (!localStorage.getItem(AZURE_AD_STORAGE_KEY)) {
            // throwされる場合、Satelliteのログイン画面に遷移する
            throw new Error(`${AZURE_AD_STORAGE_KEY}がない`);
          }

          try {
            let base64Url = store.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(
              atob(base64)
                .split('')
                .map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
            );
            ios_token = JSON.parse(jsonPayload);
          } catch (e) {
            ios_token = null;
          }

          // 再現する
          user = {
            id_token: store,
            session_state: undefined,
            access_token: store,
            refresh_token: undefined,
            token_type: 'Bearer',
            scope: 'openid params groups', // これを固定で入れてしまっても良いのかはよくわからいが動いてはいる
            profile: {
              ios_token,
              params: {
                // gyro_roleが必要なので入れる
                ...localStorage.getItem(AZURE_AD_STORAGE_KEY),
              },
            },
            expires_at: ios_token.exp,
            state: undefined,
            authenticatorError: false,
          };

          Vue.$log.info('Satelliteからの遷移', user);
        }

        // storeに書き込む
        // ただし、この情報が期限切れでないかどかは、認証が走るときに委ねる
        commit('global/set_login', user);
      } else {
        Vue.$log.info('ローカルストレージに認証情報なし');
      }
    },

    // ローカルストレージからマスター関連の復元
    storeRestoration({ commit }) {
      let store;

      // 各ストア毎に処理する
      if (localStorage.getItem('store.crm')) {
        store = JSON.parse(localStorage.getItem('store.crm'));
        Vue.$log.debug('store.crmをローカルストレージから復元', store);

        // CRM系復元
        commit('crm/set_customer_form', store.crm.customer_form);
        commit('crm/set_communication_form', store.crm.communication_form);
        commit(
          'crm/set_claim_and_cooling_off_form',
          store.crm.claim_and_cooling_off_form,
        );
        commit(
          'crm/set_assessment_offer_bring_form',
          store.crm.assessment_offer_bring_form,
        );
        commit(
          'crm/set_assessment_offer_visit_form',
          store.crm.assessment_offer_visit_form,
        );
        commit(
          'crm/set_expected_assessment_item_summary',
          store.crm.expected_assessment_item_summary,
        );
        commit(
          'crm/set_expected_assessment_items_form',
          store.crm.expected_assessment_items_form,
        );
        commit(
          'crm/set_sense_of_value_and_purchaser',
          store.crm.sense_of_value_and_purchaser,
        );
      }

      // 各ストア毎に処理する
      if (localStorage.getItem('store.reservation')) {
        store = JSON.parse(localStorage.getItem('store.reservation'));
        Vue.$log.debug('store.reservationをローカルストレージから復元', store);

        // Reservation復元
        commit(
          'reservation/set_appointment_filter_forms',
          store.reservation.appointment_filter_forms,
        );
      }
      // 各ストア毎に処理する
      if (localStorage.getItem('store.assessment')) {
        const store = JSON.parse(localStorage.getItem('store.assessment'));
        Vue.$log.debug('store.assessmentをローカルストレージから復元', store);

        // Assessment復元
        commit('assessment/set_all_masters', store.assessment.ass_masters);
        commit(
          'assessment/set_product_specific_forms',
          store.assessment.product_specific_forms,
        );
        commit(
          'assessment/set_products_mutual_forms',
          store.assessment.products_mutual_forms,
        );
      }

      // 各ストア毎に処理する 'common.cmn_masters',
      if (localStorage.getItem('store.common')) {
        const store = JSON.parse(localStorage.getItem('store.common'));
        Vue.$log.debug('store.commonをローカルストレージから復元', store);

        // Common復元
        commit('common/set_all_masters', store.common.cmn_masters);
      }
    },
  },
});
