import { isProduction } from '@/helpers';
import Vue from 'vue';

export const promas = {
  namespaced: true,
  state: {
    api: 'promas/v1',

    // 検索結果に表示する elements
    display_elements: [
      { master_product_type_id: 1, keys: [] },
      { master_product_type_id: 2, keys: [] },
      { master_product_type_id: 3, keys: [] },
      { master_product_type_id: 4, keys: [] },
      { master_product_type_id: 5, keys: [] },
      { master_product_type_id: 6, keys: [] },
      {
        master_product_type_id: 7,
        keys: [
          'PRODUCT_NAME',
          'MANUFACTURE_NAME',
          'MANUFACTURE_NAME_PRONUNCIATION',
          'CAPACITY',
          'COMMENT',
        ],
      },
      { master_product_type_id: 8, keys: [] },
      { master_product_type_id: 9, keys: [] },
      { master_product_type_id: 10, keys: [] },
      { master_product_type_id: 11, keys: [] },
      { master_product_type_id: 12, keys: [] },
      {
        master_product_type_id: 13,
        keys: [
          'PRODUCT_NAME',
          'MODEL_NUMBER',
          'BRACELET',
          'COLOR',
          'VIEWPOINTS',
        ],
      },
      { master_product_type_id: 14, keys: [] },
      { master_product_type_id: 15, keys: [] },
      { master_product_type_id: 16, keys: [] },
      { master_product_type_id: 17, keys: [] },
      {
        master_product_type_id: 18,
        keys: ['PRODUCT_NAME', 'MODEL_NUMBER', 'SIZE'],
      },
      { master_product_type_id: 19, keys: [] },
      { master_product_type_id: 20, keys: [] },
      {
        master_product_type_id: 21,
        keys: ['PRODUCT_NAME', 'MANUFACTURE_NAME', 'CATEGORY'],
      },
      { master_product_type_id: 22, keys: [] },
      { master_product_type_id: 23, keys: [] },
      {
        master_product_type_id: 29,
        keys: ['PRODUCT_NAME', 'MODEL_NUMBER', 'MATERIAL', 'SIZE'],
      },
      {
        master_product_type_id: 30,
        keys: ['MODEL_NUMBER', 'MANUFACTURE_NAME', 'CATEGORY'],
      },
    ],

    // 検索条件に表示するform要素の順番
    // keysを昇順で並べる / keysに指定していない要素は表示されない
    // PRODUCT_NAME = 商品名, PRODUCT_ID = 商品マスタID, IMAGE_EXITS = 画像の有無, それ以外はpromasのelements名に準ずる
    search_form_orders: [
      { master_product_type_id: 1, keys: [] },
      { master_product_type_id: 2, keys: [] },
      { master_product_type_id: 3, keys: [] },
      { master_product_type_id: 4, keys: [] },
      { master_product_type_id: 5, keys: [] },
      { master_product_type_id: 6, keys: [] },
      {
        master_product_type_id: 7,
        keys: [
          'IMAGE',
          'SEARCH_KEYWORD',
          'TYPE',
          'MANUFACTURE_NAME',
          'PRODUCT_NAME',
          'CAPACITY',
          'GRADE',
          'PRODUCT_ID',
          'IMAGE_EXISTS',
        ],
      },
      { master_product_type_id: 8, keys: [] },
      { master_product_type_id: 9, keys: [] },
      { master_product_type_id: 10, keys: [] },
      { master_product_type_id: 11, keys: [] },
      { master_product_type_id: 12, keys: [] },
      {
        master_product_type_id: 13,
        keys: [
          'BRAND',
          'PRODUCT_NAME',
          'MODEL_NUMBER',
          'MOVEMENT',
          'BRACELET',
          'MATERIAL',
          'COLOR',
          'GENDER',
          'SIZE_W_MM',
          'VIEWPOINTS',
          'PRODUCT_ID',
          'IMAGE_EXISTS',
        ],
      },
      { master_product_type_id: 14, keys: [] },
      { master_product_type_id: 15, keys: [] },
      { master_product_type_id: 16, keys: [] },
      { master_product_type_id: 17, keys: [] },
      {
        master_product_type_id: 18,
        keys: [
          'IMAGE',
          'BRAND',
          'PRODUCT_NAME',
          'LINE',
          'MODEL_NUMBER',
          'TYPE',
          'COLOR',
          'MATERIAL',
          'ENGRAVED',
          'SIZE_W',
          'SIZE_H',
          'SIZE_D',
          'PRODUCT_ID',
          'IMAGE_EXISTS',
        ],
      },
      { master_product_type_id: 19, keys: [] },
      { master_product_type_id: 20, keys: [] },
      {
        master_product_type_id: 21,
        keys: [
          'JAN_CODE',
          'MANUFACTURE_NAME',
          'MANUFACTURE_NAME_PRONUNCIATION',
          'PRODUCT_NAME',
          'LARGE_CATEGORY',
          'CATEGORY',
          'PRODUCT_ID',
          'IMAGE_EXISTS',
        ],
      },
      { master_product_type_id: 22, keys: [] },
      { master_product_type_id: 23, keys: [] },
      {
        master_product_type_id: 29,
        keys: [
          'BRAND',
          'PRODUCT_NAME',
          'LINE',
          'MODEL_NUMBER',
          'TYPE',
          'MATERIAL',
          'ENGRAVED',
          'SIZE_W',
          'SIZE_H',
          'SIZE_D',
          'PRODUCT_ID',
          'IMAGE_EXISTS',
        ],
      },
      {
        master_product_type_id: 30,
        keys: ['MANUFACTURE_NAME', 'CATEGORY', 'MODEL_NUMBER', 'IMAGE_EXISTS'],
      },
    ],

    // GYROとPromasのマスターの持ち方に差異がある場合に設定する
    // ex) GYRO: ブランド品(id=18) <=> Promas: ブランド品/LOUIS VUITTON(id=18) | ブランド品/GUCCI(id=29)
    product_type_groups: [
      {
        gyro_master_product_type_id: 18,
        master_product_element_key_name: 'BRAND',
        assessment_item_element_name: 'ブランド名',
        promas_master_product_type_ids: [
          { id: 18, selection_name: 'LOUIS VUITTON' },
          { id: 29, selection_name: 'GUCCI' },
        ],
      },
      {
        gyro_master_product_type_id: 23,
        assessment_item_element_name: '種別',
        promas_master_product_type_ids: [
          { id: 30, selection_name: 'オーディオ機器' },
        ],
      },
    ],

    // 撮影画像フォームに使用するガイドのIDと順番
    // keysを昇順で並べる / keysに指定していない要素は表示されない
    // 推論に利用する画像だけをここで指定する
    image_search_form_orders: [
      { master_product_type_id: 1, guides: [] },
      { master_product_type_id: 2, guides: [] },
      { master_product_type_id: 3, guides: [] },
      { master_product_type_id: 4, guides: [] },
      { master_product_type_id: 5, guides: [] },
      { master_product_type_id: 6, guides: [] },
      {
        master_product_type_id: 7, // 酒
        guides: [
          70001, // 全体写真・ラベル表
          70002, // キャップ
          70003, // ラベル裏面
        ],
      },
      { master_product_type_id: 8, guides: [] },
      { master_product_type_id: 9, guides: [] },
      { master_product_type_id: 10, guides: [] },
      { master_product_type_id: 11, guides: [] },
      { master_product_type_id: 12, guides: [] },
      { master_product_type_id: 13, guides: [] },
      { master_product_type_id: 14, guides: [] },
      { master_product_type_id: 15, guides: [] },
      { master_product_type_id: 16, guides: [] },
      { master_product_type_id: 17, guides: [] },
      {
        master_product_type_id: 18,
        guides: [
          180001, // 全体写真前面
          180003, // ブランドロゴ
          180005, // シリアル機番
          180006, // ハンドル(持ち手)
          180007, // 底面
          180012, // 内側全体
          180023, // 付属品
        ],
      },
      { master_product_type_id: 19, guides: [] },
      { master_product_type_id: 20, guides: [] },
      { master_product_type_id: 21, guides: [] },
      { master_product_type_id: 22, guides: [] },
      { master_product_type_id: 23, guides: [] },
      { master_product_type_id: 29, guides: [] },
      { master_product_type_id: 30, guides: [] },
    ],

    // 検索条件の「他サイトへのリンク」の検索queryに追加する文言
    // elementsのkeyと一致する項目のvalueにprefixとsuffixを追加する
    // SIZE_W = 20, prefix = 'w' の場合は w20としてqueryに追加される
    query_affix: [
      { master_product_type_id: 1, elements: [] },
      { master_product_type_id: 2, elements: [] },
      { master_product_type_id: 3, elements: [] },
      { master_product_type_id: 4, elements: [] },
      { master_product_type_id: 5, elements: [] },
      { master_product_type_id: 6, elements: [] },
      { master_product_type_id: 7, elements: [] },
      { master_product_type_id: 8, elements: [] },
      { master_product_type_id: 9, elements: [] },
      { master_product_type_id: 10, elements: [] },
      { master_product_type_id: 11, elements: [] },
      { master_product_type_id: 12, elements: [] },
      { master_product_type_id: 13, elements: [] },
      { master_product_type_id: 14, elements: [] },
      { master_product_type_id: 15, elements: [] },
      { master_product_type_id: 16, elements: [] },
      { master_product_type_id: 17, elements: [] },
      {
        master_product_type_id: 18,
        elements: [
          { key: 'SIZE_W', prefix: 'w', suffix: '' },
          { key: 'SIZE_H', prefix: 'h', suffix: '' },
          { key: 'SIZE_D', prefix: 'd', suffix: '' },
        ],
      },
      { master_product_type_id: 19, elements: [] },
      { master_product_type_id: 20, elements: [] },
      { master_product_type_id: 21, elements: [] },
      { master_product_type_id: 22, elements: [] },
      { master_product_type_id: 23, elements: [] },
      {
        master_product_type_id: 29,
        elements: [
          { key: 'SIZE_W', prefix: 'w', suffix: '' },
          { key: 'SIZE_H', prefix: 'h', suffix: '' },
          { key: 'SIZE_D', prefix: 'd', suffix: '' },
        ],
      },
      { master_product_type_id: 30, elements: [] },
    ],

    // 実績価格から推測値の算出する際に用いるconfig用データ
    // configに記載してない値は"-"と表示される
    // id = promas/condition_id, name = ランク名, priority = 優先順位, assessment_weight = 査定係数
    trade_results_last_90days_table_config: [
      { master_product_type_id: 1, config: [] },
      { master_product_type_id: 2, config: [] },
      { master_product_type_id: 3, config: [] },
      { master_product_type_id: 4, config: [] },
      { master_product_type_id: 5, config: [] },
      { master_product_type_id: 6, config: [] },
      { master_product_type_id: 7, config: [] },
      { master_product_type_id: 8, config: [] },
      { master_product_type_id: 9, config: [] },
      { master_product_type_id: 10, config: [] },
      { master_product_type_id: 11, config: [] },
      { master_product_type_id: 12, config: [] },
      { master_product_type_id: 13, config: [] },
      { master_product_type_id: 14, config: [] },
      { master_product_type_id: 15, config: [] },
      { master_product_type_id: 16, config: [] },
      { master_product_type_id: 17, config: [] },
      {
        master_product_type_id: 18,
        config: [
          {
            id: 3,
            name: 'A',
            priority: 4,
            assessmentWeight: 1.7,
          },
          {
            id: 4,
            name: 'AB',
            priority: 2,
            assessmentWeight: 1.5,
          },
          {
            id: 5,
            name: 'B',
            priority: 1,
            assessmentWeight: 1,
          },
          {
            id: 6,
            name: 'C',
            priority: 3,
            assessmentWeight: 0.8,
          },
          {
            id: 7,
            name: 'D',
            priority: 5,
            assessmentWeight: 0.5,
          },
        ],
      },
      { master_product_type_id: 19, config: [] },
      { master_product_type_id: 20, config: [] },
      { master_product_type_id: 21, config: [] },
      { master_product_type_id: 22, config: [] },
      { master_product_type_id: 23, config: [] },
      {
        master_product_type_id: 29,
        config: [
          {
            id: 29,
            name: 'A',
            priority: 4,
            assessmentWeight: 1.7,
          },
          {
            id: 30,
            name: 'AB',
            priority: 2,
            assessmentWeight: 1.5,
          },
          {
            id: 31,
            name: 'B',
            priority: 1,
            assessmentWeight: 1,
          },
          {
            id: 32,
            name: 'C',
            priority: 3,
            assessmentWeight: 0.8,
          },
          {
            id: 33,
            name: 'D',
            priority: 5,
            assessmentWeight: 0.5,
          },
        ],
      },
      { master_product_type_id: 30, config: [] },
    ],

    // 商品マスタが利用可能な商材種別IDのリスト
    available_assessment_item_type_ids: [7, 13, 18, 21, 23],

    /**
     * Gyro の master_assessment_item_elements の項目名と
     * Promas の master_product_elements の項目名の対応付け
     * 商品スペックの情報を査定商材情報のフォームに入力補完する際に使う
     */
    autocomplete_targets: [
      {
        master_product_type_id: 7,
        elements: [
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'PRODUCT_NAME', name: '商品名' },
          },
          {
            gyro: { name: 'メーカー名', can_overwrite_parent: false },
            promas: { key_name: 'MANUFACTURE_NAME', name: 'メーカー名' },
          },
          {
            gyro: { name: '種別', can_overwrite_parent: true },
            promas: { key_name: 'TYPE', name: '種別' },
          },
          {
            gyro: { name: '容量', can_overwrite_parent: false },
            promas: { key_name: 'CAPACITY', name: '容量' },
          },
          {
            gyro: { name: '等級', can_overwrite_parent: false },
            promas: { key_name: 'GRADE', name: '等級' },
          },
        ],
      },
      {
        master_product_type_id: 13,
        elements: [
          {
            gyro: { name: 'ブランド名', can_overwrite_parent: false },
            promas: { key_name: 'BRAND', name: 'ブランド名' },
          },
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'PRODUCT_NAME', name: '商品名' },
          },
          {
            gyro: { name: '型番', can_overwrite_parent: false },
            promas: { key_name: 'MODEL_NUMBER', name: '型番' },
          },
          {
            gyro: { name: 'ムーブメント', can_overwrite_parent: false },
            promas: { key_name: 'MOVEMENT', name: 'ムーブメント' },
          },
          {
            gyro: { name: 'ケース材質', can_overwrite_parent: false },
            promas: { key_name: 'MATERIAL', name: '素材' },
          },
          {
            gyro: {
              name: 'ケースサイズ W（リューズ除く）',
              can_overwrite_parent: false,
            },
            promas: { key_name: 'SIZE_W_MM', name: 'サイズW(mm)' },
          },
        ],
      },
      {
        master_product_type_id: 18,
        elements: [
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'PRODUCT_NAME', name: '商品名' },
          },
          {
            gyro: { name: 'ブランド名', can_overwrite_parent: false },
            promas: { key_name: 'BRAND', name: 'ブランド名' },
          },
          {
            gyro: { name: 'ライン名', can_overwrite_parent: false },
            promas: { key_name: 'LINE', name: 'ライン名' },
          },
          {
            gyro: { name: '種類', can_overwrite_parent: true },
            promas: { key_name: 'TYPE', name: '種別' },
          },
          {
            gyro: { name: '型番', can_overwrite_parent: false },
            promas: { key_name: 'MODEL_NUMBER', name: '型番' },
          },
          {
            gyro: { name: '色', can_overwrite_parent: false },
            promas: { key_name: 'COLOR', name: 'カラー' },
          },
          {
            gyro: { name: '材質', can_overwrite_parent: false },
            promas: { key_name: 'MATERIAL', name: '材質' },
          },
          {
            gyro: { name: '刻印', can_overwrite_parent: false },
            promas: { key_name: 'ENGRAVED', name: '刻印' },
          },
          {
            gyro: { name: 'サイズ W（横）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_W', name: 'サイズW(cm)' },
          },
          {
            gyro: { name: 'サイズ H（高さ）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_H', name: 'サイズH(cm)' },
          },
          {
            gyro: { name: 'サイズ D（奥行）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_D', name: 'サイズD(cm)' },
          },
        ],
      },
      {
        master_product_type_id: 21,
        elements: [
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'PRODUCT_NAME', name: '商品名' },
          },
          {
            gyro: { name: 'メーカー名', can_overwrite_parent: false },
            promas: { key_name: 'MANUFACTURE_NAME', name: 'メーカー名' },
          },
          {
            gyro: { name: '種別', can_overwrite_parent: false },
            promas: { key_name: 'LARGE_CATEGORY', name: '大カテゴリ' },
          },
        ],
      },
      {
        master_product_type_id: 23,
        elements: [
          {
            gyro: { name: 'メーカー名', can_overwrite_parent: false },
            promas: { key_name: 'MANUFACTURE_NAME', name: 'メーカー名' },
          },
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'CATEGORY', name: 'カテゴリ' },
          },
          {
            gyro: { name: '型番', can_overwrite_parent: false },
            promas: { key_name: 'MODEL_NUMBER', name: '型番' },
          },
        ],
      },
      {
        master_product_type_id: 29,
        elements: [
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'PRODUCT_NAME', name: '商品名' },
          },
          {
            gyro: { name: 'ブランド名', can_overwrite_parent: false },
            promas: { key_name: 'BRAND', name: 'ブランド名' },
          },
          {
            gyro: { name: 'ライン名', can_overwrite_parent: false },
            promas: { key_name: 'LINE', name: 'ライン名' },
          },
          {
            gyro: { name: '種類', can_overwrite_parent: true },
            promas: { key_name: 'TYPE', name: '種別' },
          },
          {
            gyro: { name: '型番', can_overwrite_parent: false },
            promas: { key_name: 'MODEL_NUMBER', name: '型番' },
          },
          {
            gyro: { name: '材質', can_overwrite_parent: false },
            promas: { key_name: 'MATERIAL', name: '材質' },
          },
          {
            gyro: { name: '刻印', can_overwrite_parent: false },
            promas: { key_name: 'ENGRAVED', name: '刻印' },
          },
          {
            gyro: { name: 'サイズ W（横）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_W', name: 'サイズW(cm)' },
          },
          {
            gyro: { name: 'サイズ H（高さ）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_H', name: 'サイズH(cm)' },
          },
          {
            gyro: { name: 'サイズ D（奥行）', can_overwrite_parent: false },
            promas: { key_name: 'SIZE_D', name: 'サイズD(cm)' },
          },
        ],
      },
      {
        master_product_type_id: 30,
        elements: [
          {
            gyro: { name: 'メーカー名', can_overwrite_parent: false },
            promas: { key_name: 'MANUFACTURE_NAME', name: 'メーカー名' },
          },
          {
            gyro: { name: '商品名', can_overwrite_parent: false },
            promas: { key_name: 'CATEGORY', name: 'カテゴリ' },
          },
          {
            gyro: { name: '型番', can_overwrite_parent: false },
            promas: { key_name: 'MODEL_NUMBER', name: '型番' },
          },
        ],
      },
    ],

    // 選択された商品スペック
    selected_promas_product: null,

    // 商品検索フォームマスタ
    product_search_form: [],

    // グラフ表示用マスタ
    graph_masters: null,

    // 検索オプション
    search_options: {
      range_search_targets: [
        { elementKey: 'SIZE_W', range: 5 },
        { elementKey: 'SIZE_H', range: 5 },
        { elementKey: 'SIZE_D', range: 5 },
        { elementKey: 'CAPACITY', range: 100 },
      ],
    },
  },
  getters: {
    get_master_conditions: (state) => (master_product_type_id) => {
      if (state.graph_masters) {
        return state.graph_masters.master_conditions
          .filter((mc) => mc.master_product_type_id === master_product_type_id)
          .sort((x, y) => x.display_order - y.display_order);
      }
      return [];
    },
  },
  mutations: {
    set_product_search_form(state, payload) {
      state.product_search_form = payload;
    },
    set_selected_promas_product(state, payload) {
      state.selected_promas_product = payload;
    },
    set_graph_masters(state, payload) {
      state.graph_masters = payload;
    },
  },
  actions: {
    async get_product_search_form({ commit, state }) {
      // ２回の取得は必要ない
      if (state.product_search_form.length > 0) return;
      Vue.$log.info('BFF_PRM_00001 商品マスタ 検索フォーム用マスタ取得API');

      return Vue.http.get(state.api + '/masters/search_form').then(
        // 正常系
        (response) => {
          commit('set_product_search_form', response.master_product_types);
        },
        // 異常系
        (error) => {
          Vue.$log.error(error);
        },
      );
    },
    async get_graph_masters({ commit, state }) {
      // ２回の取得は必要ない
      if (state.graph_masters !== null) return;
      Vue.$log.info('BFF_PRM_00002 商品マスタ グラフ表示用マスタ取得');

      return Vue.http
        .get(state.api + '/masters/graph', {
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            commit('set_graph_masters', response);
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_search_products({ state }, params) {
      Vue.$log.info('BFF_PRM_00003 商品マスタ 商品スペック検索');

      return Vue.http
        .get(state.api + '/products/search', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            return response.searchProduct;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_search_products_with_ai_assist({ state }, params) {
      Vue.$log.info('BFF_PRM_00015 商品マスタ 商品スペック画像検索');

      return Vue.http
        .get(state.api + '/products/search_with_ai_assist', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            return response.searchProductWithAiAssist;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_signed_download_url({ state }, params) {
      Vue.$log.info(
        'BFF_PRM_00004 商品マスタ 商品画像参照用署名付きURL取得API',
      );

      return Vue.http
        .get(state.api + '/signed_download_url', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            return response.signedDownloadUrl;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_competitor_prices_daily({ state }, params) {
      Vue.$log.info('BFF_PRM_00006 商品マスタ 他社実績 (日毎) 取得');

      return Vue.http
        .get(state.api + '/competitor_prices_daily', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            if (!response.getCompetitorPricesDaily) return [];

            return response.getCompetitorPricesDaily;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_group_prices_last_90days({ state }, params) {
      Vue.$log.info('BFF_PRM_00010 商品マスタ グループ実績(90日集計)取得');

      return Vue.http
        .get(state.api + '/group_prices_last_ninety_days', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            if (!response.getGroupPricesLast90days) return [];

            return response.getGroupPricesLast90days;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_group_prices_daily({ state }, params) {
      Vue.$log.info('BFF_PRM_00008 商品マスタ グループ実績(日毎)取得');

      return Vue.http
        .get(state.api + '/group_prices_daily', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            if (!response.getGroupPricesDaily) return [];

            return response.getGroupPricesDaily;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
    async get_product_price({ state }, product_id) {
      Vue.$log.info('BFF_PRM_00009 商品マスタ 商品価格取得');

      return Vue.http
        .get(state.api + `/products/${product_id}/price`, {
          product_id,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          // 正常系
          (response) => {
            // NOTE: Hasura(GraphQL) の仕様上ステータスコードが 200 でも Not Found のケースが存在する
            if (!response.getPrice) {
              return {
                id: null,
                product_id: null,
                max_purchase_price: '{"condition_price_list": []}',
                expected_to_b_sales_price: '{"condition_price_list": []}',
                expected_to_c_sales_price: '{"condition_price_list": []}',
                tenant_id: '',
                created_at: '',
                updated_at: '',
              };
            }

            return response.getPrice;
          },
          // 異常系
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    async get_private_product_condition_images({ state }, params) {
      Vue.$log.info('BFF_PRM_00012 商品マスタ プライベート状態画像取得');
      // product_code はクエリパラメータに含めなくて良いので params を分割している
      const { product_code, ...query_params } = params;

      return Vue.http
        .get(
          state.api +
            `/products/${product_code}/private_product_condition_images`,
          {
            params: query_params,
            timeout: process.env.VUE_APP_TIME_OUT,
          },
        )
        .then(
          (response) => {
            if (!response.getPrivateProductConditionImages) return [];

            return response.getPrivateProductConditionImages;
          },
          (error) => {
            Vue.$log.error(error);
          },
        );
    },

    async get_signed_download_url_for_condition_image({ state }, params) {
      Vue.$log.info(
        'BFF_PRM_00013 商品マスタ 状態画像参照用署名付きURL取得API',
      );

      return Vue.http
        .get(state.api + '/signed_download_url_for_condition_image', {
          params,
          timeout: process.env.VUE_APP_TIME_OUT,
        })
        .then(
          (response) => {
            return response.getSignedUrlForPrivateConditionImage;
          },
          (error) => {
            Vue.$log.error(error);
          },
        );
    },
  },
};
