import moment from 'moment';

// 年齢(現在年齢なし)
function age(datetime) {
  if (datetime) {
    let birth = moment(datetime);
    return ' ' + moment().diff(birth, 'years') + '歳' + ' ';
  } else {
    return '';
  }
}

/**
 * 年齢(現在年齢なし)
 * @param {Number} age 年齢
 * @returns {String} 「'X歳'」or「''」
 */
function age_suffix(age) {
  if (typeof age === 'number') {
    return `${age}歳`;
  } else {
    return '';
  }
}

// 現在の年齢
function current_age(datetime) {
  if (datetime) {
    let birth = moment(datetime);
    return '現在年齢 ' + moment().diff(birth, 'years') + '歳';
  } else {
    return '';
  }
}

export default Object.freeze({
  install(Vue) {
    Vue.filter('age', age);
    Vue.filter('age_suffix', age_suffix);
    Vue.filter('current_age', current_age);
  },
});
