/**
 * 認証用ミドルウェア
 * @param next
 * @param router
 * @returns {*}
 *
 * 参考:https://github.com/buysell-technologies/gyro_frontend/wiki/%E8%AA%8D%E8%A8%BC%E3%83%BB%E8%AA%8D%E5%8F%AF
 */
import store from '../store';

export default async function auth({ next, router }) {
  router.app.$log.debug('Azure AD認証開始');
  let idToken = '';

  // storeのユーザー情報チェック
  let current_user = await store.state.global.current_user;

  try {
    // localStorageデータを復元
    await store.dispatch('userRestoration');
  } catch (e) {
    console.error(e);
  }
  current_user = await store.state.global.current_user;
  const isCalledAuthorizationApi = current_user.profile.params.gyro_role;
  const isExpired = current_user.expires_at < new Date().getTime() / 1000;

  // AzureADから戻ってきた時、認証フローを完了するための呼び出し
  // TODO: リダイレクト処理が入ることがあるため、初回はAPIの二回呼び出しがある
  // パフォーマンスの観点から、ブランクページにリダイレクトした方が良いが、後続処理が複雑なのでそのまま
  const redirectResponse = await router.app.$msal.handleRedirectPromise();
  router.app.$log.debug('redirectResponse:', redirectResponse);

  if (redirectResponse !== null) {
    // 認証画面から認証直後は、このルートを通る
    idToken = redirectResponse.idToken;
  } else {
    // --- azureAdAccountはSatellite判定するためのｓ苦肉の策で使用するのみで、値としては意味を持たない ---
    // 参考：https://buysell-tech.atlassian.net/browse/GYRO-14739
    if (current_user.id_token && localStorage.getItem('azureAdAccount')) {
      // Satellite
      idToken = current_user.id_token;
    } else {
      // Web
      if (current_user.id_token && !isExpired) {
        idToken = current_user.id_token;
      } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        // サインインしている場合は、アカウントを取得(サイレントログイン用)
        let account;
        const accounts = router.app.$msal.getAllAccounts();
        if (accounts?.length > 0) {
          account = accounts[0];
        }
        router.app.$log.debug('ログインユーザー:', account);
        // ログイン実行
        idToken = await router.app.$msal.acquireTokenSilent(account, isExpired);
      }
    }
  }
  router.app.$log.debug('idToken:', idToken);

  const account = router.app.$msal.getAllAccounts()[0];
  if (account) {
    // 従業員番号取得
    const employeeNumber = account.idTokenClaims.employee_number;
    // ロール情報をstoreから設定
    let employeesAuthorizationData = {
      gyro_acquisition_scope:
        current_user.profile.params.gyro_acquisition_scope,
      gyro_display_scope: current_user.profile.params.gyro_display_scope,
      gyro_role: current_user.profile.params.gyro_role,
      gyro_user_classification:
        current_user.profile.params.gyro_user_classification,
      first_name: current_user.profile.params.first_name,
      last_name: current_user.profile.params.last_name,
      organization_code: current_user.profile.params.organization_code,
    };
    // storeにgyro_roleが存在しない場合は、API呼び出し
    if (!isCalledAuthorizationApi) {
      // API呼び出しのために、一旦Bearer設定を行う
      await store.commit(
        'global/set_login',
        Object.assign(current_user, {
          id_token: idToken,
        }),
      );
      // ユーザー情報取得APIを呼ぶ
      try {
        await store.dispatch('common/get_gyro_user_me');
      } catch (error) {
        // エラー時はtrueをセット(VSplash.vueの判定で使用する)
        await store.commit(
          'global/set_login',
          Object.assign(current_user, {
            authenticatorError: true,
          }),
        );
        return;
      }
      // APIの取得結果
      employeesAuthorizationData = await store.state.common.gyro_user_me;
    }

    // ユーザー情報セット
    const user = {
      id_token: idToken,
      access_token: idToken,
      token_type: 'Bearer',
      scope: 'openid params groups',
      profile: {
        sub: account.idTokenClaims.sub,
        email: account.idTokenClaims.preferred_username,
        preferred_username: account.idTokenClaims.preferred_username,
        params: {
          gyro_display_scope: employeesAuthorizationData.gyro_display_scope,
          first_name: employeesAuthorizationData.first_name,
          last_name: employeesAuthorizationData.last_name,
          gyro_role: employeesAuthorizationData.gyro_role,
          organization_code: employeesAuthorizationData.organization_code,
          gyro_user_classification:
            employeesAuthorizationData.gyro_user_classification,
          employee_number: employeeNumber,
          gyro_acquisition_scope:
            employeesAuthorizationData.gyro_acquisition_scope,
        },
      },
      expires_at: account.idTokenClaims.exp,
      authenticatorError: false,
    };
    // ユーザー情報を設定
    await store.commit('global/set_login', user);
  }
  router.app.$log.debug('Azure AD認証終了');

  return next();
}
