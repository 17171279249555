import Vue from 'vue';
import Router from 'vue-router';
import auth from './middleware/auth';
import isMobile from 'ismobilejs';

// 別タブで開く用のmethodを定義
// toを使えない特殊な状況下で仕様する
Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, '_blank');
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    /**
     * ルーティングのルール
     *
     * path:nameに則る
     * name:ファイル名と同一にする
     * component:必ずPages配下のものだけにする
     * meta:もしかするとauth以外のものを一部にだけ適応する可能性があるため冗長だが全部に記載する
     * pageTitle: title表示に使用する。記載がない場合はGYROと表記される。
     */

    /**
     * ダッシュボード
     */
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('./components/pages/Dashboard.vue'),
      meta: {
        middleware: auth,
      },
    },

    {
      // 契約検索
      path: '/assessment/contract_search',
      name: 'Assessment/ContractSearch',
      component: () => import('./components/pages/Assessment/ContractSearch'),
      meta: {
        middleware: auth,
        pageTitle: '決済管理',
      },
    },
    {
      // 案件検索
      path: '/assessment/event_search',
      name: 'Assessment/EventSearch',
      component: () => import('./components/pages/Assessment/EventSearch'),
      meta: {
        middleware: auth,
        pageTitle: '案件管理',
      },
    },
    {
      // 査定依頼商材検索
      path: '/assessment/assessment_offer_search',
      name: 'Assessment/AssessmentOfferSearch',
      component: () =>
        import('./components/pages/Assessment/AssessmentOfferSearch'),
      meta: {
        middleware: auth,
        pageTitle: '商材査定',
      },
      beforeEnter: (to, from, next) => {
        if (isMobile.phone) {
          next({
            path: '/assessment/assessment_offer_search/sp',
            query: to.query,
          });
        } else {
          next();
        }
      },
    },
    {
      // SP版 査定依頼商材検索
      // ここは検索からつながる全てのページでこのURLを使う
      path: '/assessment/assessment_offer_search/sp',
      name: 'Assessment/AssessmentOfferSearchSp',
      component: () =>
        import('./components/pages/Assessment/AssessmentOfferSearchSp'),
      meta: {
        middleware: auth,
        pageTitle: '商材査定',
        sp: true,
      },
      beforeEnter: (to, from, next) => {
        if (!isMobile.phone) {
          next({
            path: '/assessment/assessment_offer_search',
            query: to.query,
          });
        } else {
          next();
        }
      },
    },
    {
      // データメンテナンス - 案件完了日修正
      path: '/assessment/event_dates_management',
      name: 'Assessment/EventDatesManagement',
      component: () =>
        import('./components/pages/Assessment/EventDatesManagement'),
      meta: {
        pageTitle: '案件完了日修正',
        middleware: auth,
      },
    },

    /**
     * Reservation
     */
    {
      // 査定スケジュール
      path: '/reservation/schedule_management',
      name: 'Reservation/ScheduleManagement',
      component: () =>
        import('./components/pages/Reservation/ScheduleManagement'),
      meta: {
        pageTitle: '査定スケジュール',
        middleware: auth,
      },
    },
    {
      // アポイントメント管理
      path: '/reservation/appointment_management',
      name: 'Reservation/AppointmentManagement',
      component: () =>
        import('./components/pages/Reservation/AppointmentManagement'),
      meta: {
        pageTitle: 'アポイントメント管理',
        middleware: auth,
      },
    },
    {
      // SP版 アポイントメント管理
      path: '/satellite/appointment_and_product/:id',
      name: 'Satellite/AppointmentAndProduct',
      component: () =>
        import('./components/pages/Satellite/AppointmentAndProduct'),
      meta: {
        pageTitle: 'アポイントメント管理',
        middleware: auth,
      },
    },
    {
      // 査定員管理
      path: '/reservation/appraiser_management',
      name: 'Reservation/AppraiserManagement',
      component: () =>
        import('./components/pages/Reservation/AppraiserManagement'),
      meta: {
        pageTitle: '査定員管理',
        middleware: auth,
      },
    },
    {
      // 営業スケジュール取り込み
      path: '/reservation/schedule_upload',
      name: 'Reservation/ScheduleUpload',
      component: () => import('./components/pages/Reservation/ScheduleUpload'),
      meta: {
        pageTitle: '営業スケジュール取り込み',
        middleware: auth,
      },
    },
    {
      // 営業目標取り込み
      path: '/reservation/budget_upload',
      name: 'Reservation/BudgetUpload',
      component: () =>
        import('./components/pages/Reservation/BudgetUpload.vue'),
      meta: {
        pageTitle: '営業目標取り込み',
        middleware: auth,
      },
    },

    /**
     * CRM
     */
    {
      // コミュニケーション検索
      path: '/crm/communication_search',
      name: 'Crm/CommunicationSearch',
      component: () => import('./components/pages/Crm/CommunicationSearch'),
      meta: {
        pageTitle: 'コミュニケーション管理',
        middleware: auth,
      },
    },
    {
      // コミュニケーション登録
      path: '/crm/communication_management',
      name: 'Crm/CommunicationManagement',
      component: () => import('./components/pages/Crm/CommunicationManagement'),
      meta: {
        pageTitle: 'コミュニケーション登録',
        middleware: auth,
      },
    },
    {
      // 顧客管理
      path: '/crm/customer_management',
      name: 'Crm/CustomerManagement',
      component: () => import('./components/pages/Crm/CustomerManagement'),
      meta: {
        pageTitle: '顧客管理',
        middleware: auth,
      },
    },
    {
      // 店舗顧客登録更新
      path: '/crm/store_form',
      name: 'Crm/StoreForm',
      component: () => import('./components/pages/Crm/StoreForm'),
      meta: {
        pageTitle: 'お客様情報入力フォーム',
        middleware: auth,
      },
    },
    {
      // SP版 顧客管理
      path: '/satellite/customer_management/:id',
      name: 'Satellite/CustomerManagement',
      component: () =>
        import('./components/pages/Satellite/CustomerManagement'),
      meta: {
        pageTitle: '顧客管理',
        middleware: auth,
      },
    },
    {
      // データメンテナンス - 流入登録日修正
      path: '/crm/inflow_dates_management',
      name: 'Crm/InflowDatesManagement',
      component: () => import('./components/pages/Crm/InflowDatesManagement'),
      meta: {
        pageTitle: '流入登録日修正',
        middleware: auth,
      },
    },
    {
      // データメンテナンス - 名寄せ解除
      path: '/crm/undo_identify_customer_management',
      name: 'Crm/UndoIdentifyCustomerManagement',
      component: () =>
        import('./components/pages/Crm/UndoIdentifyCustomerManagement'),
      meta: {
        pageTitle: '名寄せ解除',
        middleware: auth,
      },
    },

    /**
     * Fund
     */
    {
      // 営業準備金管理
      path: '/fund/sales_reserve_management',
      name: 'Fund/SalesReserveManagement',
      component: () => import('./components/pages/Fund/SalesReserveManagement'),
      meta: {
        pageTitle: '営業準備金管理',
        middleware: auth,
      },
    },
    /**
     * Common
     */
    {
      // マスタメンテナンス
      path: '/master_maintenance',
      name: 'MasterMaintenance',
      component: () => import('./components/pages/MasterMaintenance'),
      meta: {
        pageTitle: 'マスタメンテナンス',
        middleware: auth,
      },
    },
    {
      // データメンテナンス
      path: '/data_maintenance',
      name: 'DataMaintenance',
      component: () => import('./components/pages/DataMaintenance'),
      meta: {
        pageTitle: 'データメンテナンス',
        middleware: auth,
      },
    },
    {
      // 従業員管理
      path: '/common/employee_management',
      name: 'Common/EmployeeManagement',
      component: () => import('./components/pages/Common/EmployeeManagement'),
      meta: {
        pageTitle: '従業員管理',
        middleware: auth,
      },
    },

    /**
     * oneLogin
     */
    {
      // サイレントログイン
      path: '/callback/silent',
      name: 'CallbackSilent',
      component: () => import('./components/pages/CallbackSilent.vue'),
    },
    {
      // ログイン
      path: '/callback/login',
      name: 'CallbackLogin',
      component: () => import('./components/pages/CallbackLogin.vue'),
    },
    {
      // タイトル・ボタン・テーブル・リスト
      path: '/style_test',
      name: 'StyleTest',
      component: () => import('./components/static/StyleTest.vue'),
    },

    // Sentryでのエラー確認用ダミーページ
    {
      path: '/error_dummy',
      name: 'ErrorDummyPage',
      component: () => import('./components/pages/ErrorDummy.vue'),
      meta: {
        middleware: auth,
      },
    },

    // 404
    {
      path: '*',
      name: 'notFound',
      component: () => import('./components/pages/NotFound.vue'),
    },
  ],

  scrollBehavior(to) {
    // SPでない場合はスクロール位置を戻す
    // SPでこれをやると不都合がおおい
    if (!('sp' in to.meta)) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 });
        }, 300);
      });
    }
  },
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
