import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';
import * as Sentry from '@sentry/vue';

const ua = navigator.userAgent;
const isTablet = /iPad|Macintosh/i.test(ua) && 'ontouchend' in document;
if (isTablet) {
  require('@/assets/scss/tablet.scss');
} else {
  require('@/assets/scss/pc.scss');
}

/**
 * ログ用
 */
import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'fatal' : 'debug', // しばらくbuild後もログがでるようにする
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '<<<',
  showConsoleColors: true,
};
Vue.use(VueLogger, options);

/**
 * json表示用
 */
import VueJsonPretty from 'vue-json-pretty';
Vue.component('VueJsonPretty', VueJsonPretty);

/**
 * Vuetify用
 */
import Vuetify from 'vuetify';
Vue.use(Vuetify);

/**
 * オートコンプリートを無理やり消すPlugin
 */
import DisableAutocomplete from './plugins/vue-disable-autocomplete';
Vue.use(DisableAutocomplete);

/**
 * ほぼ全域で利用する自作Component
 */
import VAutocompleteSearch from './components/atoms/VAutocompleteSearch.vue';
import VAutocompleteSearchWrap from './components/atoms/VAutocompleteSearchWrap';
import VAutocompleteSearchText from './components/atoms/VAutocompleteSearchText';
import VComboboxSearch from './components/atoms/VComboboxSearch.vue';
import VComboboxSearchWrap from './components/atoms/VComboboxSearchWrap.vue';
import VJapaneseDatePicker from './components/atoms/VJapaneseDatePicker';
import VLoadFile from './components/atoms/VLoadFile';
import VLoadImg from './components/atoms/VLoadImg';
import TextEditDialog from './components/molecules/TextEditDialog';
Vue.component('VAutocompleteSearch', VAutocompleteSearch);
Vue.component('VAutocompleteSearchWrap', VAutocompleteSearchWrap);
Vue.component('VAutocompleteSearchText', VAutocompleteSearchText);
Vue.component('VComboboxSearch', VComboboxSearch);
Vue.component('VComboboxSearchText', VComboboxSearchText);
Vue.component('VComboboxSearchWrap', VComboboxSearchWrap);
Vue.component('VJapaneseDatePicker', VJapaneseDatePicker);
Vue.component('VLoadFile', VLoadFile);
Vue.component('VLoadImg', VLoadImg);
Vue.component('TextEditDialog', TextEditDialog);

/**
 * MSAL認証用モジュール
 */
import { msal } from './plugins/msal';
Vue.use(msal);

/**
 * axios
 */
import http from './plugins/http';
Vue.use(http, { store });

/**
 * moment
 */
import VueMoment from 'vue-moment';
const moment = require('moment');
require('moment/locale/ja');
Vue.use(VueMoment, {
  moment,
});

/**
 * Google Analytics
 */
import VueGtag from 'vue-gtag';
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
  },
  router,
);

/**
 * Toasted(REST通信デバッグ用)
 */
import Toasted from 'vue-toasted';
// import _ from 'lodash';
Vue.use(Toasted, {
  fullWidth: true,
  position: 'top-center',
  duration: 1000,
  singleton: false,
});

// register the toast with the custom message
Vue.toasted.register(
  'error',
  (payload) => {
    return payload.message;
  },
  {
    keepOnHover: true,
    duration: null,
    type: 'error',
    icon: 'error_outline',
    className: 'gyro_error_toast',
    action: {
      icon: 'close',
      class: 'gyro_error_toast--close_icon',
      onClick: (e, toastObject) => toastObject.goAway(0),
    },
  },
);
Vue.toasted.register(
  'load',
  (payload) => {
    return payload.message;
  },
  {
    duration: null,
    type: 'info',
    icon: 'cached',
  },
);
Vue.toasted.register(
  'success',
  (payload) => {
    return payload.message;
  },
  {
    type: 'success',
    icon: 'check_circle_outline',
  },
);
Vue.toasted.register(
  'warning',
  (payload) => {
    return payload.message;
  },
  {
    duration: null,
    type: 'warning',
    icon: 'warning',
    className: 'warning',
  },
);

/**
 * 見えたら表示するライブラリ
 */
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

/**
 * Vue Config
 */
Vue.config.productionTip = false;

/**
 * GoogleAuth
 */
import VueGapi from 'vue-gapi';
const gapiOption = {
  apiKey: process.env.VUE_APP_SPREAD_SHEET_API_KEY,
  clientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
  discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
};
Vue.use(VueGapi, gapiOption);

/**
 * Global filter
 */
import GlobalFilter from '@/plugins/filters';
Vue.use(GlobalFilter);

/**
 * Sentry
 */
if (process.env.VUE_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // エラー数が多すぎてプランの上限に達してしまうため、サンプリング率を下げる
    // TODO エラーを解消したら上げる
    sampleRate: 0.01,
  });
}

sync(store, router);
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    // LocalStorageからデータ読込
    // this.$store.dispatch('storeRestoration');
    // 認証時に復元するのでコメントアウト
    // this.$store.dispatch('userRestoration');
  },
}).$mount('#app');

/**
 * Global mixin
 */
import GlobalMixin from '@/plugins/mixins';
import VComboboxSearchText from '@/components/atoms/VComboboxSearchText';
Vue.mixin(GlobalMixin);

/**
 * ショートカットキー対応
 */
import VueShortkey from 'vue-shortkey';
Vue.use(VueShortkey);
