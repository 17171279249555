/**
 * authority.json からコメント記述可能なJSファイルにコンバート。
 *
 * `data_management: true` について、メニューバーに「データメンテナンス」を表示する以外の意味があります！
 * gyro_role によって、案件完了日・流入登録日修正のみ可能 or 名寄せ解除のみ可能 or 両方可能 という違いがあります。
 * gyro_bff の rbac.csv を参照してください。
 *
 * 背景: https://github.com/buysell-technologies/gyro_frontend/pull/2267#discussion_r1618597055
 */

const authority = {
  marketing: {
    name: 'MK',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: false,
    sales_appraiser_management: false,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: true,
    campaign_master_management: true,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  cc: {
    name: 'CC（一般）',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: true,
    employee_management: false,
  },
  cc_route: {
    name: 'CC（ルート）',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: true,
    employee_management: false,
  },
  cc_sv: {
    name: 'CC（SV以上）',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: true,
    campaign_master_management: false,
    budget_upload: false,
    data_management: true,
    employee_management: false,
  },
  store: {
    name: '買取店舗',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  store_manager: {
    name: '買取店舗店長',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: true,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: true,
    employee_management: false,
  },
  ru: {
    name: 'RU(査定員)',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: false,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  ru_office: {
    name: 'RU（事務・センター長）',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: false,
    campaign_master_management: false,
    budget_upload: true,
    data_management: false,
    employee_management: false,
  },
  ru_call: {
    name: 'FSコール',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  ru_headquarters: {
    name: 'RU事業本部',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: false,
    campaign_master_management: false,
    budget_upload: true,
    data_management: true,
    employee_management: false,
  },
  enablement: {
    name: 'イネーブル',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: false,
    campaign_master_management: false,
    budget_upload: true,
    data_management: false,
    employee_management: false,
  },
  product: {
    name: '商品戦略（1~3部）',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: false,
    appoint_schedule_management: false,
    sales_appraiser_management: false,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  sale_specialevent: {
    name: '販売戦略（催事）',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: false,
    appoint_schedule_management: false,
    sales_appraiser_management: false,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  delivery: {
    name: '商品戦略（宅配）',
    customer_management: true,
    identify_management: false,
    communication_management: true,
    appointment_management: false,
    appoint_schedule_management: true,
    sales_appraiser_management: false,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: true,
    employee_management: false,
  },
  rum: {
    name: 'RUM（客相・決済管理）',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: true,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  finance: {
    name: '財務',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: false,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: true,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  develop: {
    name: '開発者',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: true,
    campaign_master_management: true,
    budget_upload: true,
    data_management: true,
    employee_management: true,
  },
  system_admin: {
    name: 'システム管理者',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: true,
    campaign_master_management: true,
    budget_upload: true,
    data_management: true,
    employee_management: true,
  },
  subsidiary_store: {
    name: '子会社店舗',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: true,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
  power_user: {
    name: '強化ユーザー',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: true,
    settlement_management: true,
    sales_reserves_management: true,
    master_management: true,
    campaign_master_management: true,
    budget_upload: true,
    data_management: true,
    employee_management: true,
  },
  store_ru: {
    name: '店舗配属査定員',
    customer_management: true,
    identify_management: true,
    communication_management: true,
    appointment_management: true,
    appoint_schedule_management: true,
    sales_appraiser_management: true,
    sales_schedule_management: true,
    event_management: true,
    event_related_assessment_item: true,
    event_related_sales_management: true,
    assessment_item: false,
    settlement_management: false,
    sales_reserves_management: false,
    master_management: false,
    campaign_master_management: false,
    budget_upload: false,
    data_management: false,
    employee_management: false,
  },
};

export default authority;
