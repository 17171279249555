/**
 * @file VanillaJSで実装した関数群
 */

import * as Enum from '@/enum';
import { ENUM_MASTER_EVENT_TYPE, ENUM_MASTER_CONTRACT_TYPE } from '@/enum';

/**
 * ENUMを算出プロパティに設定するためのヘルパー関数
 * @param {...string} args
 * @returns {Object<string, Function>}
 */
export function mapEnum(...args) {
  const response = {};
  args.forEach((arg) => {
    response[arg] = function () {
      return Enum[arg];
    };
  });
  return response;
}

/**
 * uuidか否かを判定
 * propsのvalidatorで利用
 * @param {String} str 文字列
 * @returns {Boolean} uuidか否か
 */
export function test_uuid(str) {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  return regex.test(str);
}

/**
 * 案件種別ID と 契約種別IDをマッピングし、
 * 売買契約の契約種別IDを返す
 *
 * | 案件種別ID     | 契約種別ID      |
 * |---------------|---------------|
 * | 訪問査定案件    | 現場査定売買契約 |
 * | 持ち込み査定案件 | 現場査定売買契約 |
 * | 宅配査定案件    | 宅配査定売買契約 |
 * | 法人査定案件    | 法人査定売買契約 |
 * | 簡易査定案件    | なし           |
 * | 店舗査定案件    | 店舗査定売買契約 |
 *
 * @param {Number} masterEventTypeId 案件種別ID
 * @returns {Number} 契約種別ID
 */
export function getSalesContractId(masterEventTypeId) {
  switch (masterEventTypeId) {
    case ENUM_MASTER_EVENT_TYPE.VISIT:
    case ENUM_MASTER_EVENT_TYPE.BRING:
      return ENUM_MASTER_CONTRACT_TYPE.SALES_CONTRACT;
    case ENUM_MASTER_EVENT_TYPE.DELIVERY:
      return ENUM_MASTER_CONTRACT_TYPE.DELIVERY;
    case ENUM_MASTER_EVENT_TYPE.CORPORATION:
      return ENUM_MASTER_CONTRACT_TYPE.CORPORATION;
    case ENUM_MASTER_EVENT_TYPE.STORE:
      return ENUM_MASTER_CONTRACT_TYPE.STORE;
    default:
      return null;
  }
}

export function convertSinglebyteNumber(str) {
  // 半角数字へ変換
  if (str !== null) {
    str = str.replace(/[０-９]/g, function (value) {
      return String.fromCharCode(value.charCodeAt(0) - 0xfee0);
    });
  }
  return str;
}

/**
 * エラーオブジェクトとfield_nameを渡し、対応するfield_messagesを返す
 * @param {Object} error HTTPレスポンスのエラーオブジェクト
 * @param {string} field_name フィールド名
 * @returns {Array.<string>}
 */
export function getErrorMessages(error, field_name) {
  if (error.response.data && error.response.data.details) {
    const detail = error.response.data.details.find(
      (x) => x.field_name === field_name,
    );

    if (detail !== undefined) {
      return detail.field_messages;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

/**
 * オブジェクトが空か判別
 * @param {Object} object オブジェクト
 * @returns {Boolean}
 */
export function objectEmpty(object) {
  return object !== undefined && object !== null && object !== ''
    ? false
    : true;
}

/**
 * 本番環境判別
 * @returns {Boolean}
 */
// HACK: 環境変数がdevelop(staging)でもproductionになってるので応急処置
export function isProduction() {
  // return process.env.NODE_ENV === 'production';

  // NOTE: 環境ごとのURLに対応（本来はenvを使用すべき）
  switch (process.env.VUE_APP_TENANT_NAME) {
    case 'timeless':
      return location.hostname.includes('timeless-nu.bst-gyro.work');
    default:
      return location.hostname.includes('gyro-nu.bst-gyro.work');
  }
}

/**
 * タイムレス環境判別
 * @returns {Boolean}
 */
export function isTimeless() {
  return process.env.VUE_APP_TENANT_NAME === 'timeless';
}
