import _ from 'lodash';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { ENUM_GENDER, ENUM_MASKING_TYPE } from '@/enum';

// UUID用
import UuidV4 from 'uuid/v4';

export default {
  beforeCreate: function () {},
  created: function () {},
  beforeMount: function () {},
  mounted: function () {},
  beforeUpdate: function () {},
  updated: function () {},
  beforeDestroy: function () {},
  destroyed: function () {},
  /**
   * computed
   */
  computed: {
    /**
     * mapGetters
     */
    ...mapGetters('global', ['v_error', 'v_error_f', 'v_error_ff']),

    /**
     * mapState
     */
    ...mapState('global', ['common_dialog_vo', 'current_user']),

    // 契約書で必要な配列を返す
    is_ipad: function () {
      return (
        /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document
      );
    },

    // Buysell店舗権限では決済系のAPIの操作を無効とする
    isStoreRole() {
      return this.current_user.profile.params.gyro_role.startsWith('store');
    },
  },
  methods: {
    /**
     * mapMutations
     */
    ...mapMutations('global', ['set_common_dialog_vo']),

    // 氏名(カナ)の文字列を生成
    disp_name(last_name, first_name, last_name_kana, first_name_kana) {
      // 必要であればここにハイフンを返すような処理を入れる
      last_name = last_name ? last_name : '';
      first_name = first_name ? first_name : '';
      last_name_kana = last_name_kana ? last_name_kana : '';
      first_name_kana = first_name_kana ? first_name_kana : '';

      return (
        last_name + first_name + '(' + last_name_kana + first_name_kana + ')'
      );
    },

    // 住所の文字列を生成
    disp_address(prefecture, city, town, building_room) {
      // TODO: 本当はすべての間に半角スペースを入れたいところだが、表示された住所をコピペして検索を行う場合もあるそうなので、検索APIに半角スペースを省くような処理を入れないといけない
      //        とりあえずリリース前までは町名番地とマンション名の間だけ半角スペースを入れて欲しいとのことだったので暫定対応
      const disp_building_room = ' ' + (building_room ? building_room : '');
      // 必要であればここにハイフンを返すような処理を入れる
      let address = [prefecture, city, town, disp_building_room];
      return address.filter((e) => e).join('');
    },

    disp_zip_code(zip_code) {
      if (!zip_code) {
        return '';
      }
      let arr = zip_code.split('');
      arr.splice(3, 0, '-');
      return arr.join('');
    },

    // routerからqueryを取得
    get_search_query(params) {
      // Queryを反映
      if (this.$route.query.search) {
        params = _.assign(params, JSON.parse(this.$route.query.search));
        return params;
      } else {
        return params;
      }
    },
    // paramsからqueryを更新
    set_search_query(params) {
      this.$router.replace({
        query: {
          search: JSON.stringify(
            _.pickBy(params, (v) => {
              // nullでなければ反映
              return !_.isNull(v);
            }),
          ),
        },
      });
    },
    // paramsからqueryを更新
    set_id_query(id) {
      this.$router.replace({
        query: { id: id },
      });
    },

    // マスターのIDを取得してnameを返す
    name_by_id(obj, id) {
      let ret = obj.find((obj) => obj.id == id);
      if (ret) {
        return ret.name;
      } else {
        return null;
      }
    },

    // マスターのnameを取得してidを返す
    id_by_name(obj, name) {
      let ret = obj.find((obj) => obj.name == name);
      if (ret) {
        return ret.id;
      } else {
        return null;
      }
    },

    $_getGenderNameById(id) {
      const obj = Object.values(ENUM_GENDER).find((x) => x.id === id);
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '';
      }
    },

    // マスタからidの配列を元にnameの配列を取得し、separatorで繋いだ文字列を返す
    concated_name_by_ids(obj, ids, separator) {
      const names = ids.map((id) => {
        let ret = obj.find((obj) => obj.id == id);
        if (ret) {
          return ret.name;
        } else {
          return '';
        }
      });
      return names.join(separator);
    },

    // 確認用ダイアログ
    async confirm_dialog(msg) {
      let confirmed = window.confirm(msg);
      if (confirmed) {
        return true;
      } else {
        throw new Error();
      }
    },

    // 確認用ダイアログ（vuetify）
    confirm_custom_dialog(msg, done_callback = null, cancel_callback = null) {
      const vo = this.common_dialog_vo;
      vo.is_open = true;
      vo.message = msg;
      vo.done_callback = done_callback;
      vo.cancel_callback = cancel_callback;

      this.set_common_dialog_vo(vo);
    },

    getUuid() {
      return UuidV4();
    },

    // uuidか否か
    test_uuid(str) {
      const regex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

      if (regex.test(str)) {
        return true;
      } else {
        throw new Error('UUIDの形式が不正です');
      }
    },

    /**
     * フォーム情報で複数階層になっているデータを処理する
     *
     * @param obj
     * @param id
     * @param key
     * @returns {Array|*}
     */
    find_children(obj, id, key) {
      let arr = obj.find((elm) => {
        if (elm[key] === id) {
          return elm;
        }
      });
      if (arr) {
        return arr.children;
      } else {
        return [];
      }
    },

    /**
     * ファイルをbase64に変換するが、ここは非同期で行う必要がある
     * @param file
     * @returns {Promise<unknown>}
     */
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    /**
     * チェック or マイナス
     */
    get_check_or_minus_class(bool) {
      return bool ? 'fa-check t-green' : 'fa-minus t-gray';
    },

    /**
     * 指定したマスク方式で、渡したobjectの該当のpropertyをマスクする
     *
     * @param obj
     * @param keys
     * @param masking_type
     */
    mask_object(obj, keys, masking_type) {
      const masked_val = ENUM_MASKING_TYPE[masking_type];
      keys.forEach((key) => {
        obj[key] = masked_val;
      });
    },

    /**
     * 本番環境判別
     * @returns {Boolean}
     */
    // HACK: 環境変数がdevelop(staging)でもproductionになってるので応急処置
    isProduction() {
      // return process.env.NODE_ENV === 'production';

      // NOTE: 環境ごとのURLに対応（本来はenvを使用すべき）
      switch (process.env.VUE_APP_TENANT_NAME) {
        case 'timeless':
          return location.hostname.includes('timeless-nu.bst-gyro.work');
        default:
          return location.hostname.includes('gyro-nu.bst-gyro.work');
      }
    },

    /**
     * バイセル環境判別
     * @returns {Boolean}
     */
    isBuySell() {
      return process.env.VUE_APP_TENANT_NAME === 'default';
    },
    /**
     * タイムレス環境判別
     * @returns {Boolean}
     */
    isTimeless() {
      return process.env.VUE_APP_TENANT_NAME === 'timeless';
    },
  },
};
