<docs>

    # 汎用的なテキスト編集用Dialog

    ## 概要
    値を受け取って表示を行い、保存でemitする
    リアクティブに保存させないようにローカルに値を持ち、emitにて反映させる

    ## 参照ドキュメント
    -

    ## author
    - Picos LLC. Tomonori Kawata
    - Picos LLC. Rie Kawata

</docs>
<template lang="pug">
v-dialog#TextEditDialog(v-model='local_dialog', content-class='d-pc d-small')
  v-card
    .d-header
      h2 {{ title }}
      span.a-btn.t-small.t-primary-g(@click='local_dialog = false') 閉じる
    .d-main
      fieldset
        v-textarea(auto-grow, rows='6', v-model='local_value')
    .d-footer
      span.a-btn.t-large.t-primary.t-center(@click='update()') 保存
</template>

<script>
/**
 * Vue
 */
export default {
  /**
   * props
   */
  props: {
    // dialog制御用
    dialog: Boolean,

    // タイトル
    title: {
      type: String,
      default: 'タイトルが設定されていません',
    },

    // テキスト本体
    value: String,
  },

  /**
   * data
   */
  data: function () {
    return {
      // v-modelとはリアクティブにしないここだけの関数
      // リアクティブにすると保存という動作を挟めない
      local_value: null,
    };
  },

  /**
   * computed
   */
  computed: {
    // v-dialog制御用
    local_dialog: {
      // getter 関数
      get: function () {
        return this.dialog;
      },
      // setter 関数
      set: function (val) {
        this.$emit('update:dialog', val);
      },
    },
  },

  /**
   * watch
   */
  watch: {
    /**
     * 開いたときに値を保持
     */
    dialog: function (val) {
      if (val) {
        this.local_value = this.value;
      }
    },
  },

  /**
   * methods
   */
  methods: {
    // 保存
    update() {
      // v-model更新
      this.$emit('input', this.local_value);
      // syncの方も更新
      this.$emit('update:dialog', false);
    },
  },
};
</script>
