<template lang="pug">
.m-header-wrap
  .m-header
    .header-inner
      router-link(to='/')
        i.fas.fa-home
      h1 GYRO
      span.staging(v-if='!isProductionFlg') 検証環境

  slot
  //↑.m-header-subに置換
</template>

<script>
import { isProduction } from '@/helpers';

export default {
  data: function () {
    return {
      navClass: '',
      dialog: false,
      isProductionFlg: isProduction(),
    };
  },
};
</script>
<style lang="scss" scoped>
.m-header-wrap {
  z-index: 11;
  position: fixed;
  top: 0;
  width: 100%;

  .m-header {
    height: $h-sp-header-inner;
    position: relative;
    .header-inner {
      background: $base-color;
      height: $h-sp-header-inner;
      display: flex;
      align-items: center;
      a {
        background-color: $base-color-light;
        width: 40px;
        height: $h-sp-header-inner;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        i {
          color: #fff;
          font-size: 16px;
        }
      }
      h1 {
        content: url('../../assets/svg/logo.svg');
        width: 36px;
        height: 21px;
        display: inline-block;
      }
      .staging {
        color: #fff;
        background-color: #f9a825;
        margin-left: 15px;
        border-radius: 2px;
        font-size: 12px;
        padding: 2px 15px;
        letter-spacing: 0.6px;
      }
    }
  }
  .m-header-sub {
    background-color: #fff;
    height: $h-sp-l-title-area;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #dfe9f8;
    .m-center-heading {
      width: calc(100vw - 200px);
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      h1 {
        font-size: 1.2rem;
        font-weight: bold;
        color: $base-color;
        line-height: 1.2;
        margin-top: 4px;
      }
      span {
        color: #8e9f9f;
      }
    }
    .m-left-action {
      width: 80px;
      display: inline-block;
      color: #267fe6;
      i:before {
        padding-right: 5px;
      }
    }
    .m-right-action {
      text-align: right;
      width: 80px;
      display: inline-block;
      color: #267fe6;
      i:before {
        padding-left: 5px;
      }
    }
  }
}
</style>
